import React from "react";
import { connect } from "react-redux";
import { getTranslation } from "../../../view/shared/helpers";
import Transaction from "./Transaction";
import { fetchActiveMeetingTransactions } from "../../../store/actions/meetings";
import { fullArabicDateConverter } from "../../../view/shared/utilities/arabicDateConverter";
import StellarLoader from "../../layout/StellarLoader";
import { db, deleteCacheByKey } from "../../../view/shared/utilities/cache";
import { useLiveQuery } from "dexie-react-hooks";

const dateStyle = {
  color: "#E36F1E",
  fontFamily: "quicksand",
  fontWeight: "bolder",
  fontSize: "25px",
  padding: "1.5rem",
  textAlign: "center",
};

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
};
const View = (props) => {
  const [transactions, setTransactions] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (Array.isArray(cachedMeetingTransactions)) {
      const ids = new Set(
        props.meetingTransactions.map(({ requestId }) => requestId)
      );
      const memberIds = new Set(
        props.members.map(({ blockchain_wallet_id }) => blockchain_wallet_id)
      );
      const updatedTransactions = cachedMeetingTransactions
        .filter(({ data, cacheId }) => {
          if (ids.has(data.requestId)) {
            deleteCacheByKey("pendingTransactions", "cacheId", cacheId);
          }
          return (
            !ids.has(data.requestId) &&
            memberIds.has(data.memberId) &&
            activeMeeting.id === data.meetingId
          );
        })
        .map(({ data, error, cacheId }) => ({
          ...data,
          createdAt: new Date().toISOString(),
          transactionType: data.type,
          cacheId,
          description: error && error.description ? error.description: "",
          status: error ? "FAILED" : data.status || "PENDING",
        }));
      setTransactions([...updatedTransactions, ...props.meetingTransactions]);
    }
  }, [props.meetingTransactions]);

  const [activeMeeting, setMeeting] = React.useState(null);
  const [offlineMeeting, setOfflineMeeting] = React.useState(null);

  const cachedMeeting = useLiveQuery(async () => {
    try {
      const items = await db.offlineMeeting.toArray();
      return items.filter(({vslaId})=>vslaId===props.vsla.id);
    } catch (error) {
      console.log(`Error: ${error}`);
      return [];
    }
  }, []);

  React.useEffect(() => {
    if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
      return;
    }
    setOfflineMeeting({
      ...cachedMeeting[0].data,
      cacheId: cachedMeeting[0].cacheId,
    });
  }, [cachedMeeting]);

  const cachedMeetingTransactions = useLiveQuery(async () => {
    try {
      const items = await db.pendingTransactions.toArray();
      return items.filter(({vslaId})=>vslaId === props.vsla.id);
    } catch (error) {
      console.log(`Error: ${error}`);
      return [];
    }
  }, []);

  React.useEffect(() => {
    if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
      return;
    }
    setOfflineMeeting({
      ...cachedMeeting[0].data,
      cacheId: cachedMeeting[0].cacheId,
    });
  }, [cachedMeeting]);

  React.useEffect(() => {
    setMeeting(offlineMeeting || props.currentOngoingMeeting[0]);
  }, [props.currentOngoingMeeting, offlineMeeting]);

  React.useEffect(() => {
    const id = activeMeeting ? activeMeeting.id : null;
    if (!id) {
      return;
    }
    props.fetchActiveMeetingTransactions(id).finally(()=> {
      setIsLoading(false);
    });
  }, [cachedMeetingTransactions]);

  React.useEffect(() => {
    const id = activeMeeting ? activeMeeting.id : null;
    if (!id) {
      return;
    }
    setIsLoading(true);
    props
      .fetchActiveMeetingTransactions(id)
      .then(() => {
        setTransactions(props.meetingTransactions);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (!activeMeeting) {
      return;
    }
    setIsLoading(true);
    props
      .fetchActiveMeetingTransactions(activeMeeting.id)
      .then(() => {
        setTransactions(props.meetingTransactions);
      }).catch(()=>{

      }).finally(() => {
        setIsLoading(false);
      });
  }, [activeMeeting]);

  if (isLoading || !activeMeeting) {
    return <StellarLoader />;
  }

  return (
    <div style={{ padding: "12px 14px", width: "100%" }}>
      <div style={dateStyle}>
        <span>
          {!["ARABIC"].includes(localStorage.getItem("lang")) &&
            new Date(activeMeeting.date).toLocaleString(
              getTranslation("locale", "date"),
              { dateStyle: "full" }
            )}
          {["ARABIC"].includes(localStorage.getItem("lang")) &&
            fullArabicDateConverter(activeMeeting.date)}
        </span>
      </div>
      {!isLoading && !transactions.length ? (
        <div style={{ textAlign: "center" }}>
          <center>
            <br />
            <span style={loadingStyle}>
              {getTranslation("span", "no_records_found")}
            </span>
          </center>
        </div>
      ) : (
        transactions.map((ts) => (
          <>
            <Transaction
              vslaId={props.vsla.id}
              data={ts} />
            <hr />
          </>
        ))
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    meetingTransactions: state.meetingTransactions,
    currentOngoingMeeting: state.currentOngoingMeeting,
    members: state.loadedvslaMembers,
    vsla: state.vsla
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchActiveMeetingTransactions: (id) =>
      dispatch(fetchActiveMeetingTransactions(id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(View);
