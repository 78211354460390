import React from "react";
import { FormButton } from "../members/formComponents/FormButton";
import { FormInput } from "../members/formComponents/FormInput";
import { Container, Form, Input } from "reactstrap";
import counterpart from "counterpart";
import ENGLISH from "../../view/shared/lang/ENGLISH";
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS";
import SWAHILI from "../../view/shared/lang/SWAHILI";
import SPANISH from "../../view/shared/lang/SPANISH";
import validateInput from "../../view/shared/validations/validateOtherIncome";
import { connect } from "react-redux";
import Translate from "react-translate-component";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { WarningMessage } from "../../view/shared/notifications/Notifications";
import { initiateTransaction } from "../../store/reducers/transactions/actions";
import LEBANESE from "../../view/shared/lang/LEBANESE";
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE";
import { setCurrentPage } from "../../store/reducers/navigation";
import StellarLoader from "../layout/StellarLoader";
import { getTranslation } from "../../view/shared/helpers";
import { db } from "../../view/shared/utilities/cache";
import { useLiveQuery } from "dexie-react-hooks";

counterpart.registerTranslations("PORTUGUESE", PORTUGUESE);
counterpart.registerTranslations("LEBANESE", LEBANESE);
counterpart.registerTranslations("ENGLISH", ENGLISH);
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS);
counterpart.registerTranslations("SWAHILI", SWAHILI);
counterpart.registerTranslations("SPANISH", SPANISH);

const accounts = ["fine", "welfare", "fund"];
const notify = (msg) => toast(msg);

const radioTextStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
  marginLeft: "25px",
  width: 0,
};

const subheaderStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
  textAlign: "left",
  lineHeight: "1.5em",
};
const OtherIncomeAndExpenses = (props) => {
  const cachedMeeting = useLiveQuery(async () => {
    try {
      const items = await db.offlineMeeting.toArray();
      return items.filter(({ vslaId }) => vslaId === props.vsla.id);
    } catch (error) {
      console.log(`Error: ${error}`);
      return [];
    }
  }, []);

  const [offlineMeeting, setOfflineMeeting] = React.useState(null);

  React.useEffect(() => {
    if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
      setOfflineMeeting(null);
      return;
    }
    setOfflineMeeting(cachedMeeting[0]);
  }, []);

  React.useEffect(() => {
    if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
      setOfflineMeeting(null);
      return;
    }
    setOfflineMeeting(cachedMeeting[0]);
  }, [cachedMeeting]);

  React.useEffect(() => {
    counterpart.setLocale(localStorage.getItem("lang"));
  }, []);

  const redirectBack = () => {
    props.setPage("group-memberList");
  };

  const [state, setState] = React.useState({
    amount: props.amount,
    destination: props.destination,
    sourceFundType: props.sourceFundType,
    otherType: props.otherType,
    loading: false,
    redirect: false,
    errors: {},
    fundError: false,
  });

  const onChange = (e) => {
    setState({
      ...state,
      errors: { [e.target.name]: null, fundError: false },
    });
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const isValid = () => {
    const { errors, isValid } = validateInput(state);

    if (!isValid) {
      setState({ ...state, errors });
    }

    return isValid;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const account = state.sourceFundType;

    const fineAccount = props.vsla.accounts.filter(
      (item) => item.type === account
    )[0];

    if (account === "fine" && fineAccount.amount < state.amount) {
      setState({ ...state, fundError: true });
      return;
    }

    if (isValid()) {
      setState({ ...state, errors: {} });
      processTransaction(
        state.amount,
        state.sourceFundType,
        state.destination,
        state.otherType
      );
      setState({ ...state, amount: "" });
    }
  };

  const processTransaction = (amount, source, destination, otherType) => {
    const lang_code = localStorage.getItem("lang");

    const lang = {
      FRANÇAIS: {
        message: `Confirmer la soumission de ${state.amount} à ${source} pour le membre`,
      },
      ENGLISH: {
        message: `Confirm  submission of ${state.amount} to ${source} for member`,
      },
      SWAHILI: {
        message: `Thibitisha uwasilishaji wa kiasi cha hela ${state.amount} kwa mwanachama akaunti ya ${source}`,
      },
      SPANISH: {
        message: `Confirm  submission of ${state.amount} to ${source} for member`,
      },
      LEBANESE: {
        message: `Confirm  submission of ${state.amount} to ${source} for household`,
      },
      PORTUGUESE: {
        message: `Confirm  submission of ${state.amount} to ${source} for member`,
      },
      ARABIC: {
        message: `الاسرة ${source} في الحساب ${state.amount} أكد إيداع `,
      },
    };

    const member_id = props.currentMember.blockchain_wallet_id;
    const uuid = props.currentMember.pubkey;

    if (window.confirm(lang[lang_code].message)) {
      setState({ ...state, loading: true });
      props.commitTransaction(
        {
          uid: uuid,
          meetingId: props.ongoingMeeting.length
            ? props.ongoingMeeting[0].id
            : offlineMeeting.data.id,
          type: ["other income", "group income"].includes(
            otherType.toLowerCase()
          )
            ? "GROUP_INCOME"
            : "GROUP_EXPENSE",
          groupId: props.group[0].blockchain_key,
          memberId: member_id,
          fund: source,
          amount: parseFloat(amount),
        },
        offlineMeeting
      );

      notify(<WarningMessage msg={"notifications.processing"} />);
      setState({ ...state, loading: false });
      setTimeout(() => {
        redirectBack();
      }, 2000);
    } else {
      notify(<WarningMessage />);
    }
  };

  const groupAccounts = () => props.vsla.accounts.map((item) => item.type);

  if (state.redirect) {
    return (
      <Redirect
        to={{
          pathname: `/Groups/${props.vsla.id}/members/${props.currentMember.blockchain_wallet_id}/Actions`,
          state: { vsla_id: props.vsla.id },
        }}
      />
    );
  }

  if (state.loading) {
    return <StellarLoader />;
  }

  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <Container>
          <span style={{ color: "red" }}>{state.errors.amount}</span>
          {state.fundError && (
            <span
              style={{
                color: "red",
              }}
            >
              Insufficient funds
            </span>
          )}

          <FormInput
            type="number"
            name="amount"
            placeholder={getTranslation("placeholder_copy", "income_amount")}
            value={state.amount || ""}
            onChange={onChange}
            className="form-control"
          />
          <span style={subheaderStyle}>
            <Translate content="span.selectFund" />
          </span>
          <Container>
            <span style={{ color: "red" }}>{state.errors.sourceFundType}</span>
            <br />
            <Container style={{ display: "grid" }}>
              {accounts.map((acc) => (
                <>
                  {acc === "welfare" && groupAccounts().includes(acc) && (
                    <span>
                      <Input
                        name="sourceFundType"
                        type="radio"
                        id="welfare"
                        style={{ width: radioTextStyle }}
                        onChange={onChange}
                        value="welfare"
                      />
                      <Translate
                        content="span.welfare"
                        component="span"
                        style={radioTextStyle}
                      />
                    </span>
                  )}
                  {acc === "fund" && groupAccounts().includes(acc) && (
                    <span>
                      <Input
                        name="sourceFundType"
                        type="radio"
                        id="savings"
                        style={{ width: radioTextStyle }}
                        onChange={onChange}
                        value="fund"
                      />
                      <Translate
                        content="span.savings"
                        component="span"
                        style={radioTextStyle}
                      />
                    </span>
                  )}
                  {acc === "fine" && groupAccounts().includes(acc) && (
                    <span>
                      <Input
                        name="sourceFundType"
                        type="radio"
                        id="fine"
                        style={{ width: radioTextStyle }}
                        onChange={onChange}
                        value="fine"
                      />
                      <Translate
                        content="span.fine"
                        component="span"
                        style={radioTextStyle}
                      />
                    </span>
                  )}
                </>
              ))}
            </Container>
          </Container>
          <br />
          <FormButton
            type={"submit"}
            value="placeholder_copy.submit"
            name="submit"
            className="btn btn-lg form-control"
            disabled={!state.amount}
          />
        </Container>
      </Form>
    </Container>
  );
};

OtherIncomeAndExpenses.propTypes = {
  destination: PropTypes.string.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setPage: (page) => dispatch(setCurrentPage(page)),
  commitTransaction: (data, meeting) =>
    dispatch(initiateTransaction(data, meeting)),
});

const mapStateToProps = (state) => {
  return {
    ongoingMeeting: state.currentOngoingMeeting,
    currentMember: state.loadedMember,
    vsla: state.vsla,
    group: state.loadedvslas,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OtherIncomeAndExpenses);
