import { combineReducers } from "redux";
import {
  groupTransactionsAggregLoadingStarted,
  loadedGroupTransactionAggreg,
  groupTransactionAggregLoadingError,
} from "./getGroupTransactionsAggregate";
import { currentTransactionContext } from "./transactionContext";
import {
  gettingGroupMeetings,
  groupMeetings,
  groupmeetingsLoadingError,
} from "./getAllGroupMeetings";
import {
  memberBalanceLoaded,
  memberBalanceLoadStarted,
  memberBalanceLoadError,
} from "./getMemberBalance";
import { balance, balanceError, vslaBalanceLoading } from "./getVslaBalance";
import {
  memberTransactionsAggregLoadingStarted,
  loadedMemberTransactionAggreg,
  memberTransactionAggregLoadingError,
} from "./getMemberAggregates";
import { vslaIsLoading, vsla, vslaHasErrored } from "./vslaDataReducer";
import { creatingFund, vslaFund, createFundError } from "./createFundAccounts";
import {
  isCreatingVsla,
  group,
  vslaCreated,
  createVslaError,
  groupError,
  projects,
} from "./createVsla";
import {
  loadedvslas,
  vlsasLoadingError,
  vlsasLoadingStarted,
} from "./getVslas";
import {
  isCreatingVslaMember,
  vslaMember,
  groupMember,
  createMemberError,
} from "./createMember";
import {
  loadedvslaMembers,
  vlsaMembersLoadingStarted,
  vlsaMembersLoadingError,
} from "./getVslaMembers";
import {
  loadedMeetingTransaction,
  meetingTransactionsLoadingStarted,
  meetingTransactionLoadingError,
} from "./getTransactions";
import {
  loadedGroupItem,
  groupItemLoadingStarted,
  groupItemLoadingError,
} from "./getGroupItem";
import {
  loadedUserVsla,
  UserVlsaLoadingStarted,
  userVlsaLoadingError,
} from "./getUserVsla";
import {
  isUpdatingVsla,
  groupUpdated,
  vslaUpdated,
  groupUpdateError,
  updateVslaError,
} from "./updateVslaAndGroup";
import {
  isCreatingUser,
  createdUser,
  createUserDetails,
  createUserError,
  loadingToggleState,
} from "./signUp";
import {
  geoDataError,
  geoDataRequestStartedLat,
  geoDataRequestStartedLong,
  geoDataReturnedLat,
  geoDataReturnedLong,
} from "./geoData";
import {
  createMeetingError,
  isCreatingMeeting,
  createdMeeting,
} from "./createMeeting";
import {
  currentOngoingMeeting,
  meetingLoadingError,
  gettingOngoingMeeting,
} from "./getMeeting";
import {
  loadedMember,
  memberLoadingError,
  memberLoadingStarted,
} from "./getMember";
import {
  loadedMemberHOL,
  memberLoadingStartedHOL,
  memberLoadingHOLError,
} from "./getMemberInfo";
import {
  isUpdatingMember,
  memberUpdatedHOL,
  memberUpdatedBC,
  updateMemberErrorHOL,
  updateMemberErrorBC,
} from "./updateMemberInfo";

import account from "./transactions";

import { dismissInstallBanner } from "./dismissInstallBanner";

import { pendingTransactions } from "./members";
import currentPage, { previousPage } from "./navigation";

import {
  meetingTransactions,
  previousMeetingsTransactions,
  offlineMeetings,
} from "./meeting";
import user from "./user";
import { isConnected, uploadStatus } from "./networkStatus";

import {
  membersTocashout,
  membersWithLoans,
  loadingCashoutData,
  cashoutStarted,
} from "./cashout";

import { cycles } from "./cycles";
import { countries, locationTransport, locations } from "./location";

const reducers = combineReducers({
  cycles,
  uploadStatus,
  offlineMeetings,
  isConnected,
  user,
  previousMeetingsTransactions,
  meetingTransactions,
  currentPage,
  account,
  pendingTransactions,
  dismissInstallBanner,
  groupTransactionsAggregLoadingStarted,
  loadedGroupTransactionAggreg,
  groupTransactionAggregLoadingError,
  currentTransactionContext,
  memberBalanceLoaded,
  memberBalanceLoadStarted,
  memberBalanceLoadError,
  memberTransactionsAggregLoadingStarted,
  loadedMemberTransactionAggreg,
  memberTransactionAggregLoadingError,
  creatingFund,
  vslaFund,
  createFundError,
  balance,
  balanceError,
  vslaBalanceLoading,
  vslaIsLoading,
  vsla,
  vslaHasErrored,
  vslaCreated,
  isCreatingVsla,
  group,
  createVslaError,
  groupError,
  loadedvslas,
  vlsasLoadingError,
  vlsasLoadingStarted,
  isCreatingVslaMember,
  vslaMember,
  groupMember,
  createMemberError,
  loadedvslaMembers,
  vlsaMembersLoadingStarted,
  vlsaMembersLoadingError,
  loadedGroupItem,
  groupItemLoadingStarted,
  groupItemLoadingError,
  loadedMeetingTransaction,
  meetingTransactionsLoadingStarted,
  meetingTransactionLoadingError,
  loadedUserVsla,
  UserVlsaLoadingStarted,
  userVlsaLoadingError,
  isUpdatingVsla,
  groupUpdated,
  vslaUpdated,
  groupUpdateError,
  updateVslaError,
  isCreatingUser,
  createdUser,
  createUserDetails,
  createUserError,
  loadingToggleState,
  geoDataError,
  geoDataRequestStartedLat,
  geoDataRequestStartedLong,
  geoDataReturnedLat,
  geoDataReturnedLong,
  createMeetingError,
  isCreatingMeeting,
  createdMeeting,
  currentOngoingMeeting,
  meetingLoadingError,
  gettingOngoingMeeting,
  loadedMember,
  memberLoadingError,
  memberLoadingStarted,
  loadedMemberHOL,
  memberLoadingStartedHOL,
  memberLoadingHOLError,
  isUpdatingMember,
  memberUpdatedHOL,
  memberUpdatedBC,
  updateMemberErrorHOL,
  updateMemberErrorBC,
  gettingGroupMeetings,
  groupMeetings,
  groupmeetingsLoadingError,
  membersWithLoans,
  membersTocashout,
  loadingCashoutData,
  cashoutStarted,
  previousPage,
  projects,
  locationTransport,
  locations,
  countries,
});

export default (state, action) => {
  if (action.type === "RESET") {
    state = undefined;
  }
  return reducers(state, action);
};
