import React, { useState, useEffect } from "react";
import {
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { getTranslation } from "../../view/shared/helpers";

const formStyle = {
  flex: "10",
  padding: "5px",
  fontFamily: "Fira Sans",
  fontSize: "20px",
  height: "50px",
};

const CustomModal = (props) => {
  const {
    className,
    headerClassName,
    isOpen,
    isCentered,
    showInput,
    showSavingsCycle,
    isCashoutModal,
    inputPlaceholder,
    cycleDuration,
    getNewCycleData,
    setInputData,
    isDisabled,
    modalHeader,
    bodyContent,
    errors,
    footerContent,
  } = props;

  const [modal, setModal] = useState(isOpen);
  const [state, setState] = useState({
    inputNumber: inputPlaceholder,
    cycleDuration: cycleDuration,
  });

  // extend this function if you want the modal to have close button
  const noob = () => <></>;

  useEffect(() => {
    setModal(isOpen);
    setState({ ...state, inputNumber: inputPlaceholder });
  }, [isOpen, inputPlaceholder]);

  const onChange = (e) => {
    const { name, value } = e.target;
    if (isCashoutModal && value.length > value.trim().length) return;
    setState({ ...state, [name]: value });
  };

  useEffect(() => {
    if (!showInput || isCashoutModal) return;
    getNewCycleData(state);
  }, [state, showInput, isCashoutModal]);

  useEffect(() => {
    if (!isCashoutModal) return;
    setInputData(state.inputNumber);
  }, [state, isCashoutModal]);

  return (
    <div>
      <Modal
        centered={isCentered}
        isOpen={modal}
        className={className}
        external={noob}
      >
        <ModalHeader className={headerClassName}>{modalHeader}</ModalHeader>
        <ModalBody>
          {bodyContent}
          {showInput && (
            <>
              <br />
              <span style={{ color: "red" }}>{errors.amount}</span>
              <Input
                type={isCashoutModal ? "text" : "number"}
                disabled={isDisabled}
                name="inputNumber"
                value={state.inputNumber}
                onChange={onChange}
                placeholder={
                  isCashoutModal ? getTranslation("cashOut", "write_here") : ""
                }
              />
              <br />
              {showSavingsCycle && (
                <Input
                  disabled={isDisabled}
                  id="savings_cycle"
                  type="select"
                  name="cycleDuration"
                  style={formStyle}
                  onChange={onChange}
                  value={state.cycleDuration}
                >
                  <option value="">
                    {getTranslation("placeholder_copy", "savings_cycle")}
                  </option>
                  <option value="6">
                    {getTranslation("dropdowns", "six_months")}
                  </option>
                  <option value="9">
                    {getTranslation("dropdowns", "nine_months")}
                  </option>
                  <option value="12">
                    {getTranslation("dropdowns", "year")}
                  </option>
                </Input>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>{footerContent}</ModalFooter>
      </Modal>
    </div>
  );
};

export default CustomModal;
