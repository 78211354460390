import React from "react";
import { Container, Row, Col, Form, Input, FormGroup } from "reactstrap";
import stellarProcessing from "../../view/assets/stellar-animation.gif";
import counterpart from "counterpart";
import Translate from "react-translate-component";
import ZARMA from "../../view/shared/lang/ZARMA";
import ENGLISH from "../../view/shared/lang/ENGLISH";
import SWAHILI from "../../view/shared/lang/SWAHILI";
import HAOUSSA from "../../view/shared/lang/HAOUSSA";
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS";
import SPANISH from "../../view/shared/lang/SPANISH";

import { toast } from "react-toastify";
import { WarningMessage } from "../../view/shared/notifications/Notifications";
import { connect } from "react-redux";
import LastWriteOff from "../reverseTransactions/LastWriteOff";
import { currentTransactionContext } from "../../store/actions/transactionContext";
import { selectMemberTrasactions } from "../../store/reducers/getTransactions";
import { getCurrency } from "../../view/shared/utilities/currency";
import { initiateTransaction } from "../../store/reducers/transactions/actions";
import LEBANESE from "../../view/shared/lang/LEBANESE";
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE";
import { selectCreatedVslaInfo } from "../../store/reducers/getVslas";
import { setCurrentPage } from "../../store/reducers/navigation";
import { getTranslation } from "../../view/shared/helpers";
import { db } from "../../view/shared/utilities/cache";
import { useLiveQuery } from "dexie-react-hooks";
counterpart.registerTranslations("PORTUGUESE", PORTUGUESE);
counterpart.registerTranslations("LEBANESE", LEBANESE);
counterpart.registerTranslations("ZARMA", ZARMA);
counterpart.registerTranslations("SWAHILI", SWAHILI);
counterpart.registerTranslations("ENGLISH", ENGLISH);
counterpart.registerTranslations("HAOUSSA", HAOUSSA);
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS);
counterpart.registerTranslations("SPANISH", SPANISH);

const notify = (msg) => toast(msg);

const WriteOffLoan = (props) => {
  const cachedMeeting = useLiveQuery(async () => {
    try {
      const items = await db.offlineMeeting.toArray();
      return items.filter(({ vslaId }) => vslaId === props.vsla.id);
    } catch (error) {
      console.log(`Error: ${error}`);
      return [];
    }
  }, []);

  const [offlineMeeting, setOfflineMeeting] = React.useState(null);

  React.useEffect(() => {
    if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
      setOfflineMeeting(null);
      return;
    }
    setOfflineMeeting(cachedMeeting[0]);
  }, []);

  React.useEffect(() => {
    if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
      setOfflineMeeting(null);
      return;
    }
    setOfflineMeeting(cachedMeeting[0]);
  }, [cachedMeeting]);

  React.useEffect(() => {
    counterpart.setLocale(state.lang);
    props.pushTransactionContext("Loan write-off");
  }, []);

  const [state, setState] = React.useState({
    amount: "",
    asset: "SC",
    source: "fund",
    dest: "member",
    loading: false,
    errors: {},
    lang: localStorage.getItem("lang"),
  });

  const redirectBack = () => {
    props.setPage("group-memberList");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    processTransaction(isPayable(), state.asset, state.source, state.dest);
  };

  const isPayable = () => {
    let outstandingLoan = 0;

    if (
      props.memberDebt(props.currentMember.blockchain_wallet_id).balance > 0
    ) {
      outstandingLoan = props.memberDebt(
        props.currentMember.blockchain_wallet_id
      ).balance;
      return outstandingLoan;
    }
    return outstandingLoan;
  };

  const processTransaction = (amount) => {
    const lang_code = localStorage.getItem("lang");

    const lang = {
      FRANÇAIS: {
        message: `Confirmez la suppression du prêt de ${parseFloat(
          amount
        )} pour le membre`,
      },
      ENGLISH: {
        message: `Confirm loan write-off of ${parseFloat(amount)} for member`,
      },
      SWAHILI: {
        message: `Thibitisha kufuta mkopo wa shilingi ${parseFloat(
          amount
        )} kwa mwanachama`,
      },
      ZARMA: {
        message: `Zarma for Confirm loan write-off of ${parseFloat(
          amount
        )} for member`,
      },
      HAOUSSA: {
        message: `Haoussa for r Confirm loan write-off of ${parseFloat(
          amount
        )} for member`,
      },
      SPANISH: {
        message: `Confirmar la cancelación del préstamo de ${parseFloat(
          amount
        )} lempiras para el miembro`,
      },
      LEBANESE: {
        message: `Confirm loan write-off of ${parseFloat(
          amount
        )} for household`,
      },
      PORTUGUESE: {
        message: `Confirme a eliminação do empréstimo de ${parseFloat(
          amount
        )} para o membro`,
      },
      ARABIC: {
        message: ` (${parseFloat(amount)}) أكد شطب القرض `,
      },
    };

    if (window.confirm(lang[lang_code].message) && isPayable()) {
      setState({ ...state, loading: true });
      const uuid = props.currentMember.pubkey;

      props.commitTransaction(
        {
          uid: uuid,
          meetingId: props.ongoingMeeting.length
            ? props.ongoingMeeting[0].id
            : offlineMeeting.data.id,
          type: "WRITE_OFF_LOAN",
          groupId: props.group[0].blockchain_key,
          memberId: props.currentMember.blockchain_wallet_id,
          amount: parseFloat(amount),
        },
        offlineMeeting
      );
      notify(<WarningMessage msg={"notifications.processing"} />);
      props.setPage("group-memberList");
    } else {
      notify(<WarningMessage />);
    }
  };

  if (!state.loading) {
    return (
      <Container>
        <div>
          <h4
            style={{
              color: "#E36F1E",
              fontFamily: "quicksand",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {props.currentMember.full_name}
          </h4>
        </div>
        <div style={{ padding: ".5rem", textAlign: "center" }}>
          <Translate
            content="title_copy.loan_writeoff"
            component="h4"
            style={{
              color: "#E36F1E",
              fontFamily: "quicksand",
              fontWeight: "bold",
            }}
          />
        </div>
        <p>This action will remove the loan records for member:</p>
        <p
          style={{
            fontFamily: "Fira Sans",
            fontSize: "16px",
            color: "#696969",
          }}
        >
          <Translate content="title_copy.outstanding_loan" />{" "}
          {parseFloat(isPayable()).toFixed(2)}{" "}
          {getCurrency(props.group[0].country).symbol}
        </p>

        <p
          style={{
            fontFamily: "Fira Sans",
            fontSize: "16px",
            color: "#696969",
          }}
        >
          <Translate content="title_copy.outstanding_loan_interest" />{" "}
          {parseFloat(
            props.loanInterest(props.currentMember.blockchain_wallet_id).balance
          ).toFixed(2)}{" "}
          {getCurrency(props.group[0].country).symbol}
        </p>

        <Row>
          <br />
          <Col>
            <Form onSubmit={onSubmit}>
              <Container>
                <FormGroup>
                  <Input
                    disabled={!isPayable()}
                    type="submit"
                    value={getTranslation("placeholder_copy", "loan_writeoff")}
                    name="submit"
                    className="btn btn-primary"
                  />
                </FormGroup>
              </Container>
            </Form>
          </Col>
        </Row>
        {props.isConnected && (
          <LastWriteOff
            offlineMeeting={props.offlineMeeting}
            redirectBack={() => {
              redirectBack();
            }}
            reversal_details={{
              reversalTransactionType: "Write-off reversed",
            }}
            transactions={props.transactions.filter(
              ({ memberId, type }) =>
                memberId === props.currentMember.blockchain_wallet_id &&
                type === "WRITE_OFF_LOAN"
            )}
          />
        )}
      </Container>
    );
  }

  return (
    <div>
      <center>
        <img
          src={stellarProcessing}
          style={{ height: "90%", width: "90%" }}
          alt="Stellar"
        />
      </center>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isConnected: state.isConnected,
    user: state.user,
    ongoingMeeting: state.currentOngoingMeeting,
    memberDebt: (id) => state.account.members[id].loan,
    loanInterest: (id) => state.account.members[id].loan_interest,
    currentMember: state.loadedMember,
    transactions: selectMemberTrasactions(state),
    group: state.loadedvslas,
    vslaSummary: selectCreatedVslaInfo(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPage: (page) => dispatch(setCurrentPage(page)),
    commitTransaction: (data, meeting) =>
      dispatch(initiateTransaction(data, meeting)),
    pushTransactionContext: (type) => dispatch(currentTransactionContext(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WriteOffLoan);
