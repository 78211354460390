export function isUpdatingMember(state = false, action) {
  switch (action.type) {
    case "IS_UPDATING_MEMBER":
      return action.isUpdating;
    default:
      return state;
  }
}

export function memberUpdatedHOL(state = [], action) {
  switch (action.type) {
    case "UPDATE_MEMBER_HOL":
      return action.memberHOL;
    default:
      return state;
  }
}

export function memberUpdatedBC(state = [], action) {
  switch (action.type) {
    case "UPDATE_MEMBER_BC":
      return action.memberBC;
    default:
      return state;
  }
}

export function updateMemberErrorHOL(state = "", action) {
  switch (action.type) {
    case "UPDATE_MEMBER_BC_ERROR":
      return action.error;
    default:
      return state;
  }
}

export function updateMemberErrorBC(state = "", action) {
  switch (action.type) {
    case "UPDATE_MEMBER_HOL_ERROR":
      return action.error;
    default:
      return state;
  }
}
