import { backendAPI, transactionAPI } from "../../api";
import {
  addToCache,
  updateCacheItem,
} from "../../../view/shared/utilities/cache";

export const cashout = (memberId, isMidCycle) => async (dispatch, getState) => {
  const id = getState().loadedvslas[0].blockchain_key;
  try {
    const { data } = await transactionAPI.get(
      `/${id}/members/${memberId}/cashout?midCycle=${isMidCycle}`
    );
    return Promise.resolve(data);
  } catch (error) {
    console.log("Error: ", error);
    return Promise.reject(error);
  }
};

export const membersWithLoans = (state) => ({
  type: "SET_MEMBERS_WITH_LOANS",
  membersWithLoans: state,
});

export const membersTocashout = (state) => ({
  type: "CASHOUT_VSLA_MEMBERS",
  membersTocashout: state,
});

export const deleteCashoutMembers = (state) => ({
  type: "DELETE_CASHOUT_VSLA_MEMBERS",
  id: state,
});

export const deleteCashoutvslaMembers = (id) => async (dispatch, getState) => {
  dispatch(deleteCashoutMembers(id));
};

export const loadingCashoutData = (status) => ({
  type: "lOADING_CASHOUT_DATA",
  loadingCashoutData: status,
});

export const cashoutStarted = (state) => ({
  type: "CASHOUT_STARTED",
  state,
});

export const canCashout = () => async (dispatch, getState) => {
  const id = getState().loadedvslas[0].blockchain_key;
  try {
    const { data } = await transactionAPI.get(`/groups/${id}/can-cashout`);
    dispatch(loadingCashoutData(data));
    if (data.membersWithLoans) {
      const memberLookup = getState().loadedvslaMembers.reduce(
        (acc, member) => {
          acc[member.blockchain_wallet_id] = member;
          return acc;
        },
        {}
      );

      const transformedMembersWithLoans = data.membersWithLoans.map((item) => {
        const member = memberLookup[item.bcWalletId];
        if (!member) {
          return item; // If member details not found, return the original item
        }
        return {
          full_name: item.name,
          blockchain_wallet_id: item.bcWalletId,
          dueLoanBalance: item.dueLoanBalance,
          ...member, // Include other member details
        };
      });

      dispatch(membersWithLoans(transformedMembersWithLoans));
    }
    return Promise.resolve(data.membersWithLoans);
  } catch (error) {
    console.log("Error: ", error);
    return Promise.reject(error);
  }
};

export const groupCashout =
  (groupId, meetingId) => async (dispatch, getState) => {
    try {
      await backendAPI.delete(`/api/v1/meetings/archive/groups/${groupId}`);
      return Promise.resolve();
    } catch (error) {
      console.log("Error: ", error);
      return Promise.reject(error);
    }
  };

export const memberCashoutCheck = (memberId) => async (dispatch, getState) => {
  try {
    const { data } = await transactionAPI.get(`/member-cachout/${memberId}`);
    return Promise.resolve(data);
  } catch (error) {
    console.log("Error: ", error);
    return Promise.reject(error);
  }
};

const cashedOut = (params) => {
  return params.every((element) => element.amount === 0);
};

export const getCashoutDetails = (member) => async (dispatch, getState) => {
  const id = getState().loadedvslas[0].blockchain_key;
  try {
    const { data } = await transactionAPI.get(
      `/${id}/members/${
        member.blockchain_wallet_id
      }/cashout?midCycle=${"endOfCycle"}`
    );
    dispatch(memberCashoutCheck(member.blockchain_wallet_id));
    if (cashedOut(data))
      dispatch(deleteCashoutvslaMembers(member.blockchain_wallet_id));
    else {
      let obj = {
        name: member.name,
        member_id: member.blockchain_wallet_id,
        balances: data,
      };
      return Promise.resolve(obj);
    }
  } catch (error) {
    console.log("Error: ", error);
    return Promise.reject(error);
  }
};

export const confirmCashout =
  (memberId, meetingId, groupId, userId) => async (dispatch, getState) => {
    const { vsla } = getState();
    const cacheKey = await addToCache("pendingTransactions", {
      data: {
        memberId,
        cashout: true,
        status: "processing",
      },
      vslaId: vsla.id
    });
    const requestObject = {
      pushEvent: "requests",
      receiveEvent: "response",
      emit: true,
      metadata: {
        userId: vsla.id,
        callBackParams: [memberId, cacheKey],
        cacheKey,
        type: "pendingTransactions",
      },
      payload: {
        requestId: "CASHOUT_RESPONSE",
        url: `${window.REACT_APP_PWA_TRANSACTIONS}/group-cashout`,
        headers: { Authorization: "Bearer " + localStorage.getItem("tokenBC") },
        data: {
          groupId: groupId,
          userId: userId,
          requestId: "CASHOUT_RESPONSE",
          meetingId: meetingId,
        },
        userId: vsla.id,
        method: "POST",
      },
    };
    dispatch(requestObject);
    await updateCacheItem(
      "pendingTransactions",
      {
        requestObject: JSON.stringify(requestObject),
      },
      cacheKey
    );
  };
