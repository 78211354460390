import { addToCache } from "../../../view/shared/utilities/cache";
import {isCreatingFundAccount} from './createFundAccounts'

export const createFundAccounts = (types) => async (dispatch, getState) => {
    const { vsla, vslaCreated } = getState();
    const vsla_id = vsla.length < 1 ? vslaCreated.id : vsla.id;
    const url = `${window.REACT_APP_BLOCKCHAIN_API || "https://stellar.staging.vsla.online"}/vsla/${vsla_id}/account`;
    const headers = {
          Authorization: "Bearer " + localStorage.getItem("tokenBC"),
    };
    localStorage.setItem("initialAccounts", JSON.stringify(types));
    types.forEach(async (type) => {
        const cacheKey = await addToCache('accounts', {type, vslaId: vsla_id});
        const requestObject = {
            pushEvent: "requests",
            receiveEvent: "response",
            emit: true,
            metadata: {
                userId: vsla_id,
                cacheKey,
                callBackParams: [],
                type: "accounts",
            },
            payload: {
                requestId: `vsla.account.new.${type}`,
                url,
                headers,
                data: {type},
                userId: vsla_id,
                method: "POST",
            },
        };
        dispatch(requestObject);
    });
    dispatch(isCreatingFundAccount(true));
  };
