import { backendAPI } from "../../api";
import { setCurrentPage } from "../../reducers/navigation";

export const isCreatingFundAccount = (bool) => ({
  type: "IS_CREATING_FUND",
  isCreating: bool,
});

export const createFund = (fund) => ({
  type: "CREATE_FUND",
  fund,
});

export const createFundError = (error) => ({
  type: "CREATE_FUND_ERROR",
  error: error,
});

export function postCreateFund(url, item, headers, ownProps) {
  return async function (dispatch) {
    dispatch(isCreatingFundAccount(true));
    try {
      const params = {
        type: item,
      };

      const res = await backendAPI.post(url, params, headers);
      dispatch(createFund(res.data));
      dispatch(isCreatingFundAccount(false));
      dispatch(setCurrentPage("group-main"));
    } catch (error) {
      dispatch(createFundError(error));
      dispatch(isCreatingFundAccount(false));
    }
  };
}
