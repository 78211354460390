import React from "react";
import counterpart from "counterpart";
import validateInput from "../../view/shared/validations/validateAmount";
import { WarningMessage } from "../../view/shared/notifications/Notifications";
import ZARMA from "../../view/shared/lang/ZARMA";
import ENGLISH from "../../view/shared/lang/ENGLISH";
import SWAHILI from "../../view/shared/lang/SWAHILI";
import HAOUSSA from "../../view/shared/lang/HAOUSSA";
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS";
import SPANISH from "../../view/shared/lang/SPANISH";
import { FormInput } from "./formComponents/FormInput";
import { FormTitle } from "./formComponents/FormTitle";
import { FormButton } from "./formComponents/FormButton";
import { Form, Container, Alert } from "reactstrap";
import { connect } from "react-redux";
import stellarProcessing from "../../view/assets/stellar-animation.gif";
import { toast } from "react-toastify";
import { selectGroupWelfareBalance } from "../../store/reducers/getVslaBalance";
import { formatCurrency } from "../../view/shared/utilities/outputFormat";
import { DisplayOutput } from "./formComponents/DisplayOutput";
import LastTransaction from "../reverseTransactions/LastTrasaction";
import { currentTransactionContext } from "../../store/actions/transactionContext";
import Translate from "react-translate-component";
import { getCurrency } from "../../view/shared/utilities/currency";
import { initiateTransaction } from "../../store/reducers/transactions/actions";
import LEBANESE from "../../view/shared/lang/LEBANESE";
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE";
import { selectCreatedVslaInfo } from "../../store/reducers/getVslas";
import { setCurrentPage } from "../../store/reducers/navigation";
import { getTranslation } from "../../view/shared/helpers";
import { db } from "../../view/shared/utilities/cache";
import { useLiveQuery } from "dexie-react-hooks";

counterpart.registerTranslations("PORTUGUESE", PORTUGUESE);
counterpart.registerTranslations("LEBANESE", LEBANESE);
counterpart.registerTranslations("ZARMA", ZARMA);
counterpart.registerTranslations("SWAHILI", SWAHILI);
counterpart.registerTranslations("ENGLISH", ENGLISH);
counterpart.registerTranslations("HAOUSSA", HAOUSSA);
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS);
counterpart.registerTranslations("SPANISH", SPANISH);

const notify = (msg) => toast(msg);

const spinnerStyle = {
    height: "90%",
    width: "90%",
  };

  const headerStyle = {
    color: "#E36F1E",
    fontFamily: "quicksand",
    fontWeight: "bolder",
    fontSize: "1.8em",
    textAlign: "center",
    paddingBottom: "0.5em",
  };

  const infoStyle = {
    padding: ".3rem",
    textAlign: "center",
    fontFamily: "Fira Sans",
    fontSize: "20px",
    color: "#696969",
    align: "inline-block",
  };

  const spanStyle = {
    textAlign: "center",
    fontFamily: "Fira Sans",
    fontSize: "20px",
    color: "#696969",
    align: "inline-block",
  };

  const loadingStyle = {
    fontFamily: "Fira Sans",
    fontSize: "20px",
  };
  
const WelfareRequest = (props) =>{

  const cachedMeeting = useLiveQuery(async () => {
    try {
      const items = await db.offlineMeeting.toArray();
      return items.filter(({vslaId})=>vslaId===props.vsla.id);
    } catch (error) {
      console.log(`Error: ${error}`);
      return [];
    }
  }, []);
  const [offlineMeeting, setOfflineMeeting] = React.useState(null);

  React.useEffect(()=> {
  if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
    setOfflineMeeting(null);
    return
  }
  setOfflineMeeting(cachedMeeting[0])
  }, []);

  React.useEffect(()=> {
  if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
    setOfflineMeeting(null);
    return
  }
  setOfflineMeeting(cachedMeeting[0])
  }, [cachedMeeting]);

  React.useEffect(()=> {
    counterpart.setLocale(localStorage.getItem("lang"));
    props.pushTransactionContext("Welfare request");
  }, []);

  const [state, setState] = React.useState({
    amount: "",
    errors: {},
    loading: false,
  });

  const onChange = (e) => setState({...state, [e.target.name]: e.target.value });

  const isValid = () => {
    const { errors, isValid } = validateInput(state);

    if (!isValid) {
      setState({ ...state, errors });
    }
    return isValid;
  }

  const redirectBack = () => {
    props.setPage("group-memberList");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (isValid()) {
      processTransaction(state.amount);
      setState({...state, amount: "" });
    }
  };

  const processTransaction = (amount) => {
    const lang_code = localStorage.getItem("lang");

    const lang = {
      FRANÇAIS: {
        message: `Confirmer la cotisation de ${state.amount} pour le membre`,
      },
      ENGLISH: {
        message: `Confirm contribution of ${state.amount} for member`,
      },
      SWAHILI: {
        message: `Thibitisha mwanachama ametoa mchango wa shilingi ${state.amount}`,
      },
      ZARMA: {
        message: `Zarma for Confirm contribution of ${state.amount} for member`,
      },
      HAOUSSA: {
        message: `Tabbatar da taimakon dala ${state.amount} daga ỳar rukuni`,
      },
      SPANISH: {
        message: `Confirmar contribución de ${state.amount} lempiras para miembro`,
      },
      LEBANESE: {
        message: `Confirm contribution of ${state.amount} for household`,
      },
      PORTUGUESE: {
        message: `Confirme a contribuição de ${state.amount} para o membro`,
      },
      ARABIC: {
        message: `للأسرة ${state.amount} أكد مساهمة`,
      },
    };

    if (window.confirm(lang[lang_code].message)) {
      setState({...state, loading: true });
      const uuid = props.currentMember.pubkey;
      props.commitTransaction(
        {
          uid: uuid,
          meetingId: props.ongoingMeeting.length
            ? props.ongoingMeeting[0].id
            : props.offlineMeeting.data.id,
          type: "WELFARE_REQUEST",
          groupId: props.group[0].blockchain_key,
          memberId: props.currentMember.blockchain_wallet_id,
          amount: parseFloat(amount),
        },
        offlineMeeting
      );
      notify(<WarningMessage msg={"notifications.processing"} />);
      props.setPage("group-memberList");
    } else {
      notify(<WarningMessage />);
    }
  };

  if (!state.loading) {
    return (
      <div>
        <div>
          <h4 style={headerStyle}>{props.currentMember.full_name}</h4>
        </div>
        <FormTitle style={headerStyle} title="title_copy.welfare_request" />
        <DisplayOutput title="mem_overview.welfare_total" style={infoStyle} />
        <p style={spanStyle}>
          {props.welfareBalance.flat(1).length < 1
            ? "0.00"
            : formatCurrency(props.welfareBalance.flat(1)[0].amount)}{" "}
          {getCurrency(props.group[0].country).symbol}
        </p>

        {props.welfareBalance.flat(1).length < 1 && (
          <Alert color="warning">
            <span>
              <Translate
                content="notifications.can_not_request_welfare"
                style={loadingStyle}
              />
            </span>
          </Alert>
        )}
        {props.welfareBalance.flat(1).length > 0 && (
          <Form onSubmit={onSubmit}>
            <Container>
              <span style={{ color: "red" }}>{state.errors.amount}</span>
              <FormInput
                type="number"
                name="amount"
                placeholder={getTranslation("placeholder_copy", "welfare_amount")}
                value={state.amount}
                onChange={onChange}
                className="form-control"
              />
              {parseFloat(props.welfareBalance.flat(1)[0].amount) <
                parseFloat(state.amount) && (
                <span style={{ color: "red" }}>
                  <Translate
                    content="notifications.welfare_amount_warning"
                    style={loadingStyle}
                  />
                </span>
              )}
              <FormButton
                type={"submit"}
                value="placeholder_copy.welfare_submit"
                name="submit"
                className="btn btn-lg form-control"
                disabled={
                  parseFloat(props.welfareBalance.flat(1)[0].amount) <
                  parseFloat(state.amount)
                    ? true
                    : false
                }
              />
            </Container>
          </Form>
        )}
        {props.isConnected &&
        <LastTransaction
          offlineMeeting={offlineMeeting}
          redirectBack={()=>{redirectBack()}}
          reversal_details={{
            sourceFundType: "issuer",
            destFundType: "welfare",
            reversalTransactionType: "Welfare request reversed",
          }}
          transactions={props.transactions.filter(
            ({ memberId }) =>
              memberId === props.currentMember.blockchain_wallet_id
          )}
        />}
      </div>
    );
  }

  return (
    <div>
      <center>
        <img src={stellarProcessing} style={spinnerStyle} alt="Stellar" />
      </center>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isConnected: state.isConnected,
    ongoingMeeting: state.currentOngoingMeeting,
    currentMember: state.loadedMember,
    welfareBalance: selectGroupWelfareBalance(state),
    transactions: state.loadedMeetingTransaction.filter(
      ({ type }) => type === "WELFARE_REQUEST"
    ),
    vsla: state.vsla,
    group: state.loadedvslas,
    vslaSummary: selectCreatedVslaInfo(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPage: (page) => dispatch(setCurrentPage(page)),
    commitTransaction: (data, meeting) =>
      dispatch(initiateTransaction(data, meeting)),
    pushTransactionContext: (type) => dispatch(currentTransactionContext(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WelfareRequest);
