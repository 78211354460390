import React, { useState } from "react";
import { Input, Container, Form, FormGroup, Alert } from "reactstrap";
import validateInput from "../../view/shared/validations/codeVerify";
import Translate from "react-translate-component";
import counterpart from "counterpart";
import ZARMA from "../../view/shared/lang/ZARMA";
import ENGLISH from "../../view/shared/lang/ENGLISH";
import SWAHILI from "../../view/shared/lang/SWAHILI";
import HAOUSSA from "../../view/shared/lang/HAOUSSA";
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS";
import SPANISH from "../../view/shared/lang/SPANISH";
import "react-phone-number-input/style.css";
import "react-responsive-ui/style.css";
import "react-phone-input-2/lib/style.css";
import ar from "react-phone-input-2/lang/ar.json";
import PhoneInput from "react-phone-input-2";
import { NavLink } from "react-router-dom";
import LEBANESE from "../../view/shared/lang/LEBANESE";
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE";
import ARABIC from "../../view/shared/lang/ARABIC";

counterpart.registerTranslations("ARABIC", ARABIC);
counterpart.registerTranslations("PORTUGUESE", PORTUGUESE);
counterpart.registerTranslations("LEBANESE", LEBANESE);
counterpart.registerTranslations("ZARMA", ZARMA);
counterpart.registerTranslations("SWAHILI", SWAHILI);
counterpart.registerTranslations("ENGLISH", ENGLISH);
counterpart.registerTranslations("HAOUSSA", HAOUSSA);
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS);
counterpart.registerTranslations("SPANISH", SPANISH);

const VerifyCode = (props) => {
  const [phone_number, setPhoneNumber] = useState("");
  const [token, setToken] = useState("");
  const [errors, setErrors] = useState({});
  const [_country] = React.useState(localStorage.getItem("_country") || "GH");

  const onChange = (e) => {
    if (e.target.name === "phone_number") {
      setPhoneNumber(e.target.value);
    } else if (e.target.name === "token") {
      setToken(e.target.value);
    }
  };

  const isValid = () => {
    const { errors, isValid } = validateInput({ phone_number, token });
    if (!isValid) {
      setErrors(errors);
    }
    return isValid;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (isValid()) {
      props.history.push({
        pathname: `/pinReset`,
        pincode: token,
        phone: phoneValidate("+" + phone_number),
      });
    }
  };

  const phoneValidate = (phone) => {
    if (phone === "+") {
      return "";
    } else {
      return phone;
    }
  };

  const smscode = counterpart.translate("placeholder_copy.sms_code");
  const submit = counterpart.translate("placeholder_copy.submit");
  const login_email = counterpart.translate("placeholder_copy.login_email");

  const inputStyle = {
    flex: "10",
    fontFamily: "Fira Sans",
    height: "50px",
    width: "100%",
    fontSize: "20px",
  };

  const formStyle = {
    flex: "10",
    padding: "5px",
    fontFamily: "Fira Sans",
    fontSize: "20px",
    height: "50px",
    width: "100%",
  };

  return (
    <div>
      <Container>
        <Alert color="secondary" style={loadingStyle}>
          <Translate content="placeholder_copy.token_sent" />{" "}
          <i>
            <NavLink to="/PhoneVerify">
              <Translate content="placeholder_copy.resend_token" />
            </NavLink>
          </i>
        </Alert>
        <Form onSubmit={onSubmit}>
          <FormGroup
            style={{
              direction: "ltr",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span style={{ color: "red" }}>{errors.phone_number}</span>
            <PhoneInput
              country={_country.toLocaleLowerCase()}
              enableSearch
              disableSearchIcon
              placeholder={login_email}
              inputStyle={inputStyle}
              style={loadingStyle}
              value={phone_number}
              onChange={(value) => setPhoneNumber(value)}
              limitMaxLength={true}
              localization={
                localStorage.getItem("lang") === "ARABIC" ? ar : false
              }
            />
          </FormGroup>
          <FormGroup>
            <span style={{ color: "red" }}>{errors.pin}</span>
            <input
              type="number"
              name="token"
              placeholder={smscode}
              style={formStyle}
              value={token}
              onChange={onChange}
              error={errors.pin}
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="submit"
              value={submit}
              name="submit"
              className="btn btn-primary"
              size="lg"
            />
          </FormGroup>
        </Form>
      </Container>
    </div>
  );
};

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "18px",
};
export default VerifyCode;
