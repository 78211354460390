import isEmpty from "lodash/isEmpty";

export default function validateInput(data) {
  const lang_code = localStorage.getItem("lang");

  const lang_required = {
    FRANÇAIS: {
      message_required: `Ce champ est requis`,
    },
    ENGLISH: {
      message_required: `This field is required`,
    },
    SWAHILI: {
      message_required: `Ni lazima kujaza nafasi hii`,
    },
    ZARMA: {
      message_required: `Tilas batamo hima ga bara`,
    },
    HAOUSSA: {
      message_required: `Doole hilin ga shina nan`,
    },
    SPANISH: {
      message_required: `Este campo es requerido`,
    },
    PORTUGUESE: {
      message_required: `Este campo é obrigatório`,
    },
  };

  const lang_digits_only = {
    FRANÇAIS: {
      message_required: `Entrez uniquement des chiffres`,
    },
    ENGLISH: {
      message_required: `Enter digits only`,
    },
    SWAHILI: {
      message_required: `Ingiza tarakimu pekee`,
    },
    ZARMA: {
      message_required: `Hantum  lambayze hinne`,
    },
    HAOUSSA: {
      message_required: `Rubuta ỳan ƙilgo kaɗay`,
    },
    SPANISH: {
      message_required: `Inserta solo dígitos`,
    },
    PORTUGUESE: {
      message_required: `Insira apenas dígitos`,
    },
  };

  const lang_pin_length = {
    FRANÇAIS: {
      message_required: `Le code PIN doit comporter 4 chiffres`,
    },
    ENGLISH: {
      message_required: `Pin should be 4 digits`,
    },
    SWAHILI: {
      message_required: `PIN mwisho tarakimu 4`,
    },
    ZARMA: {
      message_required: `Zarma for PIN mwisho tarakimu 4`,
    },
    HAOUSSA: {
      message_required: `ỳan ƙilgo hudu kaday ke cikin lamban kanki`,
    },
    SPANISH: {
      message_required: `El pin debe tener 4 dígitos`,
    },
    PORTUGUESE: {
      message_required: `O PIN deve ter 4 dígitos`,
    },
  };

  let errors = {};

  if (data.token === "") {
    errors.pin = lang_required[lang_code].message_required;
  }

  if (data.token.length !== 4) {
    errors.pin = lang_pin_length[lang_code].message_required;
  }

  if (isNaN(data.token)) {
    errors.pin = lang_digits_only[lang_code].message_required;
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}
