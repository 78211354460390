import { getTranslation } from "../../../view/shared/helpers";

//Default configuarion for location form fields
const defaultFormConfigs = {
  default: {
    //Level One: Country
    showLevelOne: true,
    requiredLevelOne: true,
    disabledLevelOne: false,
    levelOneName: getTranslation("locations", "country"),
    levelOneKey: "country",

    //Level Two
    showLevelTwo: false,
    requiredLevelTwo: true,
    disabledLevelTwo: false,
    levelTwoName: getTranslation("locations", "province"),
    levelTwoKey: "province",

    //Level Three
    showLevelThree: false,
    requiredLevelThree: true,
    disabledLevelThree: false,
    levelThreeName: getTranslation("locations", "district"),
    levelThreeKey: "district",

    //Level Four
    showLevelFour: false,
    requiredLevelFour: true,
    disabledLevelFour: false,
    levelFourName: getTranslation("locations", "administrative_post"),
    levelFourKey: "administrative_post",

    //Level Five
    showLevelFiveSelector: false,
    showLevelFiveTextInput: false,
    requiredLevelFive: true,
    disabledLevelFive: false,
    levelFiveName: getTranslation("locations", "locality"),
    levelFiveKey: "locality",

    //Level Six
    showLevelSix: false,
    requiredLevelSix: false,
    disabledLevelSix: false,
    levelSixName: getTranslation("locations", "neigbourhood_name"),
    levelSixKey: "neighbourhood_name",
  },
  kenya: {
    levelTwoName: getTranslation("locations", "county"),
    levelTwoKey: "county",
    levelThreeName: getTranslation("locations", "sub_county"),
    levelThreeKey: "sub_county",
    showLevelTwo: true,
    showLevelThree: true,
    showLevelFiveSelector: false,
    showLevelFiveTextInput: true,
  },
  ghana: {
    levelTwoName: getTranslation("locations", "region"),
    levelTwoKey: "region",
    levelThreeName: getTranslation("locations", "gh_district"),
    showLevelTwo: true,
    showLevelThree: true,
    showLevelFiveSelector: false,
    showLevelFiveTextInput: true,
    levelFiveName: getTranslation("placeholder_copy", "create_group_location"),
  },
  moçambique: {
    showLevelTwo: true,
    showLevelThree: true,
    showLevelFour: true,
    showLevelFiveSelector: true,
    showLevelFiveTextInput: false,
    showLevelSix: true,
  },
  // Add more country-specific configurations as needed eg:
};

export { defaultFormConfigs };
