import React from "react";
import PropTypes from "prop-types";
import Translate from "react-translate-component";

export const FormTitle = ({ title, style }) => {
  return <Translate content={title} component="div" style={style} />;
};

FormTitle.propTypes = {
  title: PropTypes.string.isRequired,
  style: PropTypes.object
};
