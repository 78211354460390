import React from "react";
import { Form, FormGroup } from "reactstrap";
import Select from "react-select";

const MultiSelector = ({
  onChange,
  selectorOptions,
  placeholderText,
  defaultOptions,
}) => {
  /**
   * MultiSelector Component
   * This component renders a customizable multi-select dropdown. It allows users to select multiple options from a list.
   *
   * @param {function} onChange - Callback function that is triggered when the selection changes.
   *      It receives the new selected value as an argument.
   * @param {Array} selectorOptions - Array of objects representing the selectable options.
   *      Each object must include 'id' and 'name' properties to be compatible with this component.
   * @param {string} placeholderText - Text to display when no option is selected, serving as a prompt for the user.
   *
   */
  const [selectedIds, setSelectedIds] = React.useState([]);
  const [options, setOptions] = React.useState([]);

  /* This effect is necessary to transform the selector data options into the format required by the react-select library.
     It runs whenever the 'selctor options' prop changes to ensure the options are updated correctly.*/
  React.useEffect(() => {
    const result = selectorOptions.map((option) => ({
      value: option.id,
      label: option.name,
    }));
    setOptions(result);
  }, [selectorOptions]);

  React.useEffect(() => {
    if (defaultOptions && defaultOptions.length) {
      const ids = defaultOptions.map((option) => option);
      setSelectedIds(ids);
    }
  }, []);

  React.useEffect(() => {
    onChange(selectedIds);
  }, [selectedIds]);

  const handleChange = (selectedOptions) => {
    const ids = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setSelectedIds(ids);
  };

  return (
    <Form>
      <FormGroup name="multiSelector">
        <Select
          isMulti
          placeholder={placeholderText}
          options={options}
          className="group-multi-select"
          classNamePrefix="select"
          onChange={handleChange}
          value={options.filter((option) => selectedIds.includes(option.value))} // Ensures selected items are highlighted
        />
      </FormGroup>
    </Form>
  );
};

export default MultiSelector;