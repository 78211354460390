import React, { Component } from "react";
import { connect } from "react-redux";
import { FormTitle } from "../members/formComponents/FormTitle";
import { FormButton } from "../members/formComponents/FormButton";
import { Redirect } from "react-router-dom";
import {
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Alert,
  Input,
  Card,
} from "reactstrap";
import Translate from "react-translate-component";
import counterpart from "counterpart";
import ENGLISH from "../../view/shared/lang/ENGLISH";
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS";
import SWAHILI from "../../view/shared/lang/SWAHILI";
import SPANISH from "../../view/shared/lang/SPANISH";
import uuid from "uuid";
import updateTransanction from "../reverseTransactions/updateTransaction";
import memberToIssuer from "./memberToIssuer";
import { toast } from "react-toastify";
import {
  SuccessMessage,
  ErrorMessage,
  WarningMessage,
} from "../../view/shared/notifications/Notifications";
import { getCurrency } from "../../view/shared/utilities/currency";
import LEBANESE from "../../view/shared/lang/LEBANESE";
import { reverseTransaction } from "../../store/reducers/transactions/actions";
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE";
import { setCurrentPage } from "../../store/reducers/navigation";
import StellarLoader from "../layout/StellarLoader";
import { blockchainAPI } from "../../store/api";

counterpart.registerTranslations("PORTUGUESE", PORTUGUESE);
counterpart.registerTranslations("LEBANESE", LEBANESE);
counterpart.registerTranslations("ENGLISH", ENGLISH);
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS);
counterpart.registerTranslations("SWAHILI", SWAHILI);
counterpart.registerTranslations("SPANISH", SPANISH);

export class LastLoanRepayment extends Component {
  notify = (msg) => toast(msg);

  state = {
    reverse: false,
    loading: false,
    redirect: false,
  };

  onChange = (e) => {
    const lang_code = localStorage.getItem("lang");

    const lang = {
      FRANÇAIS: {
        message: `Confirmez que vous souhaitez activer l'annulation des transactions`,
      },
      ENGLISH: {
        message: `Confirm you want to activate reversal of transactions`,
      },
      SWAHILI: {
        message: `Thibitisha unataka kuwezesha kurejeshea mwanachama mchango`,
      },
      SPANISH: {
        message: `Confirme que desea activar la reversión de transacciones`,
      },
      LEBANESE: {
        message: `Confirm you want to activate reversal of transactions`,
      },
      PORTUGUESE: {
        message: `Confirme que deseja ativar o estorno de transações`,
      },
      ARABIC: {
        message: "أكد أنك تريد تفعيل إلغاء المعاملات",
      },
    };

    if (window.confirm(lang[lang_code].message)) {
      this.setState({ [e.target.name]: this.toggleReverse() });
    }
  };

  toggleReverse() {
    return !this.state.reverse;
  }
  reverseTransaction(data) {
    const uid = this.props.currentMember.pubkey;
    const lang_code = localStorage.getItem("lang");
    const amount = data.amount.toString();
    const lang = {
      FRANÇAIS: {
        message: `Confirmer la cotisation de ${amount} pour le membre`,
      },
      ENGLISH: {
        message: `Confirm reversal of ${amount} for member`,
      },
      SWAHILI: {
        message: `Thibitisha kurejeshea mwanachama mchango wa shilingi ${amount}`,
      },
      SPANISH: {
        message: `Confirmar la reversión de ${amount} lempiras por miembro`,
      },
      LEBANESE: {
        message: `Confirm reversal of ${amount} for household`,
      },
      PORTUGUESE: {
        message: `Confirme o reverso de ${amount} para o membro`,
      },
      ARABIC: {
        message: `${amount} أكد عكس معاملة المبلغ`,
      },
    };

    if (window.confirm(lang[lang_code].message)) {
      this.props.reverse({ ...data, uid }, this.props.offlineMeeting);
      this.notify(<WarningMessage msg={"notifications.reversal"} />);
      this.props.setPage("group-memberList");
    }
  }

  handleReversal(
    id,
    full_name,
    transaction_type,
    amount,
    pubkey,
    destFundType,
    reversalTransactionType
  ) {
    const lang_code = localStorage.getItem("lang");
    const headers = {
      headers: {
        Authorization:
          "Bearer " + this.props.user.tokenBC ||
          localStorage.getItem("tokenBC"),
      },
    };
    const amountStr = amount.toString();
    const lang = {
      FRANÇAIS: {
        message: `Confirmer la cotisation de ${amount} pour le membre`,
      },
      ENGLISH: {
        message: `Confirm reversal of ${amount} for member`,
      },
      SWAHILI: {
        message: `Thibitisha kurejeshea mwanachama mchango wa shilingi ${amount}`,
      },
      SPANISH: {
        message: `Confirmar la reversión de ${amount} lempiras por miembro`,
      },
      LEBANESE: {
        message: `Confirm reversal of ${amount} for household`,
      },
      PORTUGUESE: {
        message: `Confirme o reverso de ${amount} para o membro`,
      },
      ARABIC: {
        message: `${amount} أكد عكس معاملة المبلغ`,
      },
    };

    const txn = {
      id: id,
      pubkey: pubkey,
      full_name: full_name,
      transaction_type: transaction_type,
      amount: amount,
      reversal: false,
    };

    if (window.confirm(lang[lang_code].message)) {
      const { vsla, member, ongoingMeeting } = this.props;

      this.setState({ loading: true });

      blockchainAPI
        .post(
          `/vsla/${vsla.id}/member/${member.id}/loan`,
          { amount: amountStr },
          headers
        )
        .then((res) => {
          return null;
        })
        .then((res) => {
          return memberToIssuer(
            id,
            txn,
            amountStr,
            vsla.id,
            member.id,
            ongoingMeeting[0].id,
            member.metadata.member_uuid,
            reversalTransactionType,
            destFundType
          );
        })
        .then((res) => {
          updateTransanction(txn);
        })
        .catch((error) => {
          console.log("error", error);
          this.notify(<ErrorMessage />);
        });

      const timeout = 13000;
      setTimeout(() => {
        this.setState({ loading: false });
        this.setState({ reverse: false });
        this.setState({ redirect: true });
        this.notify(<SuccessMessage />);
      }, timeout);
    }
  }

  render() {
    counterpart.setLocale(localStorage.getItem("lang"));
    const { group } = this.props;

    const { ongoingMeeting, transactions, vsla, member } = this.props;
    const { loading, redirect } = this.state;

    const headerStyle = {
      color: "#E36F1E",
      fontFamily: "quicksand",
      fontWeight: "bolder",
      fontSize: "1.2rem",
      textAlign: "center",
      paddingBottom: "0.5em",
    };

    const checkboxStyle = {
      position: "relative",
      bottom: "0",
      width: "100%",
    };

    const listStyle = {
      color: "#343a40",
      fontFamily: "Fira Sans",
      fontSize: "20px",
      textAlign: "left",
    };

    if (redirect) {
      return (
        <Redirect
          to={{
            pathname: `/Groups/${vsla.id}/members/${member.id}/Actions`,
            state: { vsla_id: vsla.id },
          }}
        />
      );
    }

    if (loading) {
      return <StellarLoader />;
    }

    if (ongoingMeeting && transactions.length < 1) {
      return (
        <div>
          {" "}
          <Alert color="warning">
            <span>
              <Translate
                content="notifications.no_reversible_transactions"
                style={{
                  fontFamily: "Fira Sans",
                  fontSize: "16px",
                }}
              />
            </span>
          </Alert>
        </div>
      );
    }

    if (ongoingMeeting) {
      return (
        <Container>
          <FormTitle style={headerStyle} title="title_copy.lastTransaction" />
          <ListGroup flush>
            {transactions.map((txn) => {
              return (
                <ListGroupItem style={listStyle} key={uuid.v4()}>
                  <Row>
                    <Col>
                      <span>
                        {txn.amount} {getCurrency(group[0].country).symbol}
                      </span>
                    </Col>
                    <Col>
                      <FormButton
                        handleClick={() => this.reverseTransaction(txn)}
                        value="placeholder_copy.membership_reverse"
                        className="btn btn-lg form-control float"
                        disabled={
                          !this.state.reverse ||
                          (txn.type === "LOAN_INTEREST_REPAYMENT" &&
                            this.props.disableLoanInterestReversal)
                        }
                      />
                    </Col>
                  </Row>
                </ListGroupItem>
              );
            })}
          </ListGroup>
          <Container>
            <Card style={checkboxStyle}>
              <Input
                name="reverse"
                type="checkbox"
                value={this.state.reverse}
                onChange={this.onChange}
                checked={this.state.reverse ? true : false}
              />
            </Card>
            <Alert color="warning">
              <span>
                <Translate
                  content="notifications.activate_reversible_transactions"
                  style={{
                    fontFamily: "Fira Sans",
                    fontSize: "16px",
                  }}
                />
              </span>
            </Alert>
          </Container>
        </Container>
      );
    }

    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    ongoingMeeting: state.currentOngoingMeeting,
    vsla: state.vsla,
    currentMember: state.loadedMember,
    group: state.loadedvslas,
  };
};

const mapDispatchToProps = (dispatch) => ({
  reverse: (data, meeting) => dispatch(reverseTransaction(data, meeting)),
  setPage: (page) => dispatch(setCurrentPage(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LastLoanRepayment);
