import isEmpty from "lodash/isEmpty";

export default function validateInput(data) {
  let errors = {};
  const lang_code = localStorage.getItem("lang");

  const lang_required = {
    FRANÇAIS: {
      message_required: `Ce champ est requis`,
    },
    ENGLISH: {
      message_required: `This field is required`,
    },
    SWAHILI: {
      message_required: `Ni lazima kujaza nafasi hii`,
    },
    ZARMA: {
      message_required: `Tilas batamo hima ga bara`,
    },
    HAOUSSA: {
      message_required: `Doole hilin ga shina nan`,
    },
    SPANISH: {
      message_required: `Este campo es requerido`,
    },
    ARABIC: {
      message_required: `هذا الحقل اجباري`,
    },
    PORTUGUESE: {
      message_required: `Este campo é obrigatório`,
    },
  };

  if (data.paysInterest === "") {
    errors.paysInterest = lang_required[lang_code].message_required;
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}
