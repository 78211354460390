export function vslaIsLoading(state = false, action) {
  switch (action.type) {
    case "LOAD_VSLA_START":
      return action.isloading;
    default:
      return state;
  }
}

export function vsla(state = [], action) {
  switch (action.type) {
    case "LOAD_VSLA_SUCCESS":
      return action.vsla;
    default:
      return state;
  }
}

export function vslaHasErrored(state = false, action) {
  switch (action.type) {
    case "LOAD_VSLA_ERROR":
      return action.error;
    default:
      return state;
  }
}
