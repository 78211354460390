import isEmpty from "lodash/isEmpty";
import { arabicToInt } from "../utilities/arabicNumParser";

export default function validateInput(data) {
  let errors = {};

  const lang_code = localStorage.getItem("lang");

  const lang_required = {
    FRANÇAIS: {
      message_required: `Ce champ est requis`,
    },
    ENGLISH: {
      message_required: `This field is required`,
    },
    SWAHILI: {
      message_required: `Ni lazima kujaza nafasi hii`,
    },
    ZARMA: {
      message_required: `Tilas batamo hima ga bara`,
    },
    HAOUSSA: {
      message_required: `Doole hilin ga shina nan`,
    },
    SPANISH: {
      message_required: `Este campo es requerido`,
    },
    ARABIC: {
      message_required: `هذا الحقل اجباري`,
    },
    PORTUGUESE: {
      message_required: `Este campo é obrigatório`,
    },
  };

  const lang_digits_only = {
    FRANÇAIS: {
      message_required: `Entrez uniquement des chiffres`,
    },
    ENGLISH: {
      message_required: `Enter digits only`,
    },
    SWAHILI: {
      message_required: `Ingiza tarakimu pekee`,
    },
    ZARMA: {
      message_required: `Hantum  lambayze hinne`,
    },
    HAOUSSA: {
      message_required: `Rubuta ỳan ƙilgo kaɗay`,
    },
    SPANISH: {
      message_required: `Inserta solo dígitos`,
    },
    ARABIC: {
      message_required: `ادخال أرقام فقط`,
    },
    PORTUGUESE: {
      message_required: `Insira apenas dígitos`,
    },
  };

  const lang_range = {
    FRANÇAIS: {
      message_required: `Montant compris entre 0 et 999999999`,
    },
    ENGLISH: {
      message_required: `Amount between 0 and 999999999`,
    },
    SWAHILI: {
      message_required: `Kiasi kati ya 0 na 999999999`,
    },
    ZARMA: {
      message_required: `Zarma for Amount between 0 and 999999999`,
    },
    HAOUSSA: {
      message_required: `Haoussa for Amount between 0 and 999999999`,
    },
    SPANISH: {
      message_required: `Importe entre 0 y 999999999`,
    },
    ARABIC: {
      message_required: `Amount between 0 and 999999999`,
    },
    PORTUGUESE: {
      message_required: `Valor entre 0 e 999999999`,
    },
  };

  if (data.number_of_shares === "") {
    errors.number_of_shares = lang_required[lang_code].message_required;
  }

  if (isNaN(arabicToInt(data.number_of_shares))) {
    errors.number_of_shares = lang_digits_only[lang_code].message_required;
  }

  if (data.number_of_shares < 0 || data.number_of_shares > 999999999) {
    errors.number_of_shares = lang_range[lang_code].message_required;
  }

  return {
    errors,
    isValid: isEmpty(errors),
  };
}
