export function arabicToInt(number) {
  number = number
    .replace(/[٠١٢٣٤٥٦٧٨٩]/g, function (d) {
      return d.charCodeAt(0) - 1632;
    })
    .replace(/[۰۱۲۳۴۵۶۷۸۹]/g, function (d) {
      return d.charCodeAt(0) - 1776;
    });
  return number;
}

export function intToArabic(num) {
  const val = num + "";
  const arabicNumbers =
    "\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669";
  return val.replace(/[0123456789]/g, (d) => {
    return arabicNumbers[d];
  });
}
