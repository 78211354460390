import React from 'react'
import {  Input } from "reactstrap";
import { getTranslation } from "../../../view/shared/helpers";


const SearchBar = ({ setter, data }) => {
	const onChange = ({target}) => {
		const name = target.value
	    if (name !== '') {
	      const results = data.filter(member=> {
	        return member.full_name.toLocaleLowerCase().startsWith(name.toLocaleLowerCase())
	      })
	      setter(results)
	    }
	    else {
	      setter(data)
	    }
	}
	return (<div>
		<Input
			onChange={onChange}
			name="member-search-bar"
			type="search"
			placeholder={getTranslation("placeholder_copy", "search_placeholder")}/>
	</div>)
}
export default SearchBar;