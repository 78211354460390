import { createSelector } from "reselect";

export function memberBalanceLoadStarted(state = false, action) {
  switch (action.type) {
    case "MEM_BALANCE_LOAD_START":
      return action.isloading;
    default:
      return state;
  }
}

export function memberBalanceLoaded(state = [], action) {
  switch (action.type) {
    case "LOADED_MEMBER_BALANCE":
      return action.balance;
    default:
      return state;
  }
}

export function memberBalanceLoadError(state = [], action) {
  switch (action.type) {
    case "MEM_BALANCE_LOAD_ERROR":
      return action.error;
    default:
      return state;
  }
}

export const selectMemberBalance = (state) => state.memberBalanceLoaded;

export const selectMemberSharesFund = createSelector(
  selectMemberBalance,
  (balances) => {
    return balances
      .filter((balance) => balance.asset === "SHAR")
      .map((balance) => {
        return { balance: balance.balance };
      });
  }
);
