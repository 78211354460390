import React from "react";
import { Container } from "reactstrap";
import LoginForm from "./LoginForm";
import counterpart from "counterpart";
import ZARMA from "../../view/shared/lang/ZARMA";
import ENGLISH from "../../view/shared/lang/ENGLISH";
import SWAHILI from "../../view/shared/lang/SWAHILI";
import HAOUSSA from "../../view/shared/lang/HAOUSSA";
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS";
import SPANISH from "../../view/shared/lang/SPANISH";

import { NavLink } from "react-router-dom";
import Translate from "react-translate-component";
import LEBANESE from "../../view/shared/lang/LEBANESE";
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE";
import ARABIC from "../../view/shared/lang/ARABIC";
counterpart.registerTranslations("ARABIC", ARABIC);

counterpart.registerTranslations("PORTUGUESE", PORTUGUESE);
counterpart.registerTranslations("LEBANESE", LEBANESE);
counterpart.registerTranslations("ZARMA", ZARMA);
counterpart.registerTranslations("SWAHILI", SWAHILI);
counterpart.registerTranslations("ENGLISH", ENGLISH);
counterpart.registerTranslations("HAOUSSA", HAOUSSA);
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS);
counterpart.registerTranslations("SPANISH", SPANISH);

export default function LoginPage() {
  counterpart.setLocale(localStorage.getItem("lang"));

  return (
    <Container>
      <LoginForm />
      <Container style={{ textAlign: "center", color: "#6c757d" }}>
        <Translate
          content="span.login_span"
          component="span"
          style={loadingStyle}
        />
        <NavLink to={{ pathname: `/SignUpPage` }}>
          {" "}
          <b>
            <Translate
              content="span.signup"
              style={loadingStyle}
              component="u"
            />
          </b>
        </NavLink>
        <br />
        <br />
        {window.REACT_APP_PWA_APP_ENV === "production" && (
          <NavLink to={{ pathname: "/phoneVerify" }}>
            <span
              style={{
                textAlign: "right",
                fontFamily: "Fira Sans",
                fontSize: "16px",
                color: "#6c757d",
                marginRight: "0.2em",
              }}
            >
              <u>
                <Translate content="placeholder_copy.forgot_pin" />
              </u>
            </span>
          </NavLink>
        )}
      </Container>
    </Container>
  );
}

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "18px",
};
