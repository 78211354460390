import React, { useState } from "react";
import { ListGroup, ListGroupItem, Container } from "reactstrap";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import counterpart from "counterpart";
import Translate from "react-translate-component";
import ZARMA from "../../view/shared/lang/ZARMA";
import ENGLISH from "../../view/shared/lang/ENGLISH";
import SWAHILI from "../../view/shared/lang/SWAHILI";
import HAOUSSA from "../../view/shared/lang/HAOUSSA";
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS";
import SPANISH from "../../view/shared/lang/SPANISH";

import { toast } from "react-toastify";
import { connect } from "react-redux";
import { fetchMeeting, meetingLoaded } from "../../store/actions/meetings/getMeeting";
import { patchMeeting } from "../../store/actions/meetings/createMeeting";
import LEBANESE from "../../view/shared/lang/LEBANESE";
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE";
import ARABIC from "../../view/shared/lang/ARABIC";
import { selectCreatedVslaInfo } from "../../store/reducers/getVslas";
import { setCurrentPage } from "../../store/reducers/navigation";
import { getTranslation } from "../../view/shared/helpers";
import CustomModal from "../layout/Modal";
import { updateGroupSettings } from "../../store/actions/createVsla";
import { patchGroupItem } from "../../store/actions/updateVslaAndGroup";
import validateInput from "../../view/shared/validations/validateAmount";
import { useLiveQuery } from "dexie-react-hooks";
import { db, updateCacheItem } from "../../view/shared/utilities/cache";
counterpart.registerTranslations("ARABIC", ARABIC);

counterpart.registerTranslations("PORTUGUESE", PORTUGUESE);
counterpart.registerTranslations("LEBANESE", LEBANESE);
counterpart.registerTranslations("ZARMA", ZARMA);
counterpart.registerTranslations("SWAHILI", SWAHILI);
counterpart.registerTranslations("ENGLISH", ENGLISH);
counterpart.registerTranslations("HAOUSSA", HAOUSSA);
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS);
counterpart.registerTranslations("SPANISH", SPANISH);

const linkReverseStyles = {
  display: "flex",
  justifyContent: "space-between",
};

const iconStyle = {
  fontSize: "20px",
  float: "right",
  color: "#536A81",
  transform:
    localStorage.getItem("lang") === "ARABIC" ? "rotateY(180deg)" : "none",
};

const successStyle = {
  fontFamily: "quicksand",
  fontSize: "20px",
  color: "green",
  fontWeight: "bolder",
};

const BackbuttonStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "6px 12px",
  width: "62px",
  height: "36px",
  color: "#fff",
  fontFamily: "Quicksand",
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 600,
  background: "#6C757D",
  borderRadius: "4px",
  flex: "none",
  border: "0",
  alignSelf: "stretch",
  flexGrow: "0",
  textTransform: "capitalize",
  marginRight: "0.5em",
};

const ContbuttonStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "6px 12px",
  width: "93px",
  height: "36px",
  color: "#fff",
  fontFamily: "Quicksand",
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 600,
  background: "#E36F1E",
  borderRadius: "4px",
  flex: "none",
  border: "0",
  alignSelf: "stretch",
  flexGrow: "0",
  textTransform: "capitalize",
  marginRight: "0.5em",
};

export const SuccessMessage = () => {
  return (
    <Translate
      content="notifications.meeting_end_success"
      component="div"
      style={loadingStyle}
    />
  );
};

export const ErrorMessage = () => {
  return (
    <Translate
      content="notifications.meeting_end_error"
      component="div"
      style={loadingStyle}
    />
  );
};

export const UpdatedSuccessMessage = () => {
  return (
    <Translate
      content="notifications.share_value_updated_msg"
      component="div"
      style={successStyle}
    />
  );
};

const MeetingsMenu = (props) => {
  const notify = (msg) => toast(msg);
  const [isOpen, setIsOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [modalFirstBtnName, setModalFirstBtnName] = useState("");
  const [modalSecBtnName, setModalSecBtnName] = useState("");
  const [modalBtnDisable, setModalBtnDisable] = useState(false);
  const [cycleData, setNewCycleData] = useState({
    duration: null,
    value: null,
  });
  const [errors, setErrors] = useState({});

  const cachedMeeting = useLiveQuery(async () => {
   try {
      const items = await db.offlineMeeting.toArray();
      return items.filter(({vslaId})=>vslaId===props.vsla.id);
    } catch (error) {
      console.log(`Error: ${error}`);
      return [];
    }
  }, []);

  const cachedTransactions = useLiveQuery(() => {
    try {
      return db.pendingTransactions
        .filter(({vslaId})=>vslaId === props.vsla.id).toArray();
    } catch (error) {
      console.log(`Error: ${error}`);
      return [];
    }
  }, []);

  const [offlineMeeting, setOfflineMeeting] = React.useState(null);
  const [transactions, setTransactions] = React.useState([]);

  React.useEffect(() => {
    if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
      setOfflineMeeting(null);
      return;
    }
    setOfflineMeeting({
      ...cachedMeeting[0].data,
      cacheId: cachedMeeting[0].cacheId,
    });
  }, [cachedMeeting]);

  React.useEffect(() => {
    if (!Array.isArray(cachedTransactions) || !cachedTransactions.length) {
      return;
    }
    setTransactions(transactions
      .filter(({vslaId})=> vslaId === props.vsla.id)
        .map(({ data }) => data));
  }, [cachedTransactions]);

  React.useEffect(() => {
    const ts = props.meetingTransactions.filter(({ status }) =>
      ["pending"].includes((status || "").toLocaleLowerCase())
    );
    if (!ts.length) {
      return;
    }
    setTransactions(ts);
  }, [props.meetingTransactions]);

  const endMeeting = async () => {
    const lang_code = localStorage.getItem("lang");
    const lang = {
      FRANÇAIS: {
        message: `Voulez-vous vraiment terminer la réunion?`,
      },
      ENGLISH: {
        message: `Are you sure you want to end the meeting?`,
      },
      SWAHILI: {
        message: `Una uhakika unataka kufunga mkutano?`,
      },
      ZARMA: {
        message: `Akiika kaŋ ni ga ba ma marga ban ne?`,
      },
      HAOUSSA: {
        message: `Tabbat ki na so ki ƙare taron ỳanzun nan?`,
      },
      SPANISH: {
        message: `¿Está seguro de que desea finalizar la reunión?`,
      },
      LEBANESE: {
        message: `Are you sure you want to end the session?`,
      },
      ARABIC: {
        message: "هل انت متاكد انك تريد انهاء الجلسة",
      },
      PORTUGUESE: {
        message: `Tem certeza de que deseja encerrar a reunião?`,
      },
    };

    if (transactions.length) {
      alert(getTranslation("alert", "cannot_end_meeting"));
      return;
    }

    if (window.confirm(lang[lang_code].message)) {
      const currentMeeting = offlineMeeting || props.ongoingMeeting[0];
      const temp = currentMeeting;
      temp.status = "completed";
      const headers = {
        headers: {
          Authorization:
            "Bearer " + props.user.token || localStorage.getItem("token"),
        },
      };

      const group_name = props.loadedVsla[0].name;

      const notif = {
        ENGLISH: {
          message: `The secretary has ended a meeting for ${group_name} group on VSLA Online`,
        },
        FRANÇAIS: {
          message: `FRA - The secretary has ended a meeting for ${group_name} group on VSLA Online`,
        },
        SWAHILI: {
          message: `SWA - The secretary has ended a meeting for ${group_name} group on VSLA Online`,
        },
        SPANISH: {
          message: `La secretaria ha terminado una reunión para el grupo ${group_name} en VSLA Online`,
        },
        LEBANESE: {
          message: `The officer has ended a session for ${group_name} community on VSLA.online`,
        },
        ARABIC: {
          message: group_name,
        },
        PORTUGUESE: {
          message: `O/A secretário/a encerrou uma reunião para o grupo ${group_name} no VSLA Online`,
        },
      };

      temp.msg = Object.values(notif[lang_code])[0];
      const url = `/api/v1/meetings/${temp.id}`;
      if (offlineMeeting) {
        await updateCacheItem(
          "offlineMeeting",
          {
            data: {
              ...offlineMeeting,
              status: "completed",
            },
          },
          offlineMeeting.cacheId
        );
        notify(<SuccessMessage />);
        props.clearLoadedMeeting();
        return;
      }
      const data = {
        date: temp.date,
        status: "completed",
        request_id: temp.requestId,
        group_id: temp.group_id,
        msg: temp.msg,
      };
      props
        .patchOngoingMeeting(url, data, headers)
        .then((res) => {
          notify(<SuccessMessage />);
          const group_id = props.loadedVsla[0].id;
          const url = `/api/v1/groups/${group_id}/meetings`;
          props.fetchOngoingMeeting(url, headers);
        })
        .catch((error) => {
          notify(<ErrorMessage />);
        });
    }
  };

  const [hasActiveMeeting, setHasActiveMeeting] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  React.useEffect(() => {
    setHasActiveMeeting(
      [
        props.ongoingMeeting !== undefined && props.ongoingMeeting.length,
        offlineMeeting && offlineMeeting.status === "ongoing",
      ].some((value) => value)
    );
    if (!offlineMeeting) {
      return;
    }

    const meeting = offlineMeeting;
    setHasError(
      (offlineMeeting.transactions || []).filter(
        ({ status }) => status === "failed"
      ).length > 0 || !!meeting.error
    );
  }, [props.isConnected, props.ongoingMeeting, offlineMeeting]);

  const newMeeting = () => {
    if (offlineMeeting || hasError || props.isCreatingMeeting) {
      return;
    }
    if (
      props.loadedVsla[0].can_change_share_value &&
      !["ARABIC", "LEBANESE"].includes(localStorage.getItem("lang"))
    ) {
      setModalFirstBtnName(getTranslation("dropdowns", "no"));
      setModalSecBtnName(getTranslation("dropdowns", "yes"));
      setIsDisabled(true);
      setIsOpen(true);
    } else props.setPage("group-createMeeting");
  };

  const onModalBtnClick = (params) => {
    if (params === "btn1" && modalFirstBtnName === "No") {
      props.setPage("group-createMeeting");
      updateGroup();
    } else if (params === "btn1" && modalFirstBtnName === "Cancel") {
      setIsOpen(false);
      setIsDisabled(true);
    }
    if (params === "btn2" && modalSecBtnName === "Yes") {
      setIsDisabled(false);
      setModalFirstBtnName(getTranslation("span", "cancel"));
      setModalSecBtnName(getTranslation("span", "save"));
    } else if (params === "btn2" && modalSecBtnName === "Save") {
      if (isValid()) {
        setErrors({});
        setModalSecBtnName(getTranslation("span", "saving"));
        setModalBtnDisable(true);
        updateShareValue();
      }
    }
  };

  const isValid = () => {
    const { errors, isValid } = validateInput({
      amount: cycleData.inputNumber,
    });

    if (!isValid) {
      setErrors(errors);
    }

    return isValid;
  };

  const updateShareValue = () => {
    const vsla = props.groupVsla;
    let newVsla = Object.assign({}, vsla);
    newVsla.metadata.share_value = !cycleData.inputNumber
      ? vsla.metadata.share_value
      : cycleData.inputNumber;
    newVsla.cycle_duration = cycleData.duration;
    newVsla.metadata.fineCashOutType = vsla.metadata.fineCashOutType;
    newVsla.metadata.welfareCashOutType = vsla.metadata.welfareCashOutType;
    newVsla.metadata.membershipCashOutType =
      vsla.metadata.membershipCashOutType;
    newVsla.metadata.loanInterestCashOutType =
      vsla.metadata.loanInterestCashOutType;
    newVsla.metadata.savingsCashOutType = vsla.metadata.savingsCashOutType;
    props
      .updateSettings(newVsla)
      .then(() => {
        //update group to change value of can_change_share_value
        updateGroup();
        notify(<UpdatedSuccessMessage />);
        props.setPage("group-createMeeting");
      })
      .catch((error) => {
        notify(<ErrorMessage />);
        throw new Error("Updating shares", error);
      });
  };

  const updateGroup = () => {
    // patch group in HOL
    const headers = {
      headers: {
        Authorization:
          "Bearer " + props.user.token || localStorage.getItem("token"),
      },
    };
    const vslaData = props.vslaSummary[0].group;
    let temp = vslaData;
    temp.can_change_share_value = false;
    temp.cycle_duration = cycleData.cycleDuration;
    const urlHOL = `/api/v1/groups/group_update/${temp.id}`;
    props.patchGroup(urlHOL, temp, headers);
  };

  if (!hasActiveMeeting) {
    return (
      <div>
        <div
          style={{
            padding: ".5rem",
            textAlign: "center",
            margin: "34px 0 0 0",
          }}
        >
          <Translate
            content="title_copy.meeting"
            component="h4"
            style={{
              color: "#E36F1E",
              fontFamily: "quicksand",
              fontWeight: "bolder",
            }}
          />
        </div>
        <Container>
          <ListGroup flush>
            {props.loadedVsla[0].status === "Active" && (
              <ListGroupItem>
                <span
                  onClick={() => newMeeting()}
                  style={
                    localStorage.getItem("lang") === "ARABIC"
                      ? linkReverseStyles
                      : {}
                  }
                >
                  <Translate
                    content="menu.new_meeting"
                    className="textStyle"
                    style={{
                      ...loadingStyle,
                      color: offlineMeeting || hasError ? "grey" : "initial",
                    }}
                  />
                  <FontAwesomeIcon style={iconStyle} icon="angle-right" />
                </span>
              </ListGroupItem>
            )}
            <ListGroupItem>
              <span
                onClick={() => {
                  props.setPage("group-previousMeetings");
                }}
                style={
                  localStorage.getItem("lang") === "ARABIC"
                    ? linkReverseStyles
                    : {}
                }
              >
                <Translate
                  content="menu.prev_meeting"
                  className="textStyle"
                  style={loadingStyle}
                />
                <FontAwesomeIcon style={iconStyle} icon="angle-right" />
              </span>
            </ListGroupItem>
            {offlineMeeting && (
              <ListGroupItem>
                <span
                  onClick={() => {
                    props.setPage("group-offlineMeeting");
                  }}
                  style={
                    localStorage.getItem("lang") === "ARABIC"
                      ? linkReverseStyles
                      : {}
                  }
                >
                  <Translate
                    content="menu.offline_meeting"
                    className="textStyle"
                    style={loadingStyle}
                  ></Translate>
                  {(offlineMeeting.transactions || []).filter(
                    ({ status }) => status === "failed"
                  ).length ? (
                    <FontAwesomeIcon
                      style={{
                        position: "absolute",
                        color: "red",
                      }}
                      icon="exclamation-circle"
                    />
                  ) : (
                    false
                  )}
                  <FontAwesomeIcon style={iconStyle} icon="angle-right" />
                </span>
              </ListGroupItem>
            )}
          </ListGroup>
          <CustomModal
            cycleDuration={props.vslaSummary[0].group.cycle_duration}
            modalHeader={getTranslation("title_copy", "reminder")}
            headerClassName="d-flex justify-content-center"
            bodyContent={getTranslation(
              "notifications",
              "share_value_information"
            )}
            isCentered={true}
            showInput={true}
            isDisabled={isDisabled}
            errors={errors}
            getNewCycleData={setNewCycleData}
            showSavingsCycle={true}
            inputPlaceholder={parseFloat(props.groupVsla.metadata.share_value)}
            isOpen={isOpen}
            footerContent={
              <div className="btn-toolbar">
                <button
                  className="btn"
                  disabled={modalBtnDisable}
                  onClick={() => onModalBtnClick("btn1")}
                  style={BackbuttonStyle}
                >
                  {modalFirstBtnName}
                </button>
                <button
                  className="btn btn-info"
                  disabled={modalBtnDisable}
                  onClick={() => onModalBtnClick("btn2")}
                  style={ContbuttonStyle}
                >
                  {modalSecBtnName}
                </button>
              </div>
            }
          />
        </Container>
      </div>
    );
  }

  return (
    <div>
      <br />

      <Container>
        <ListGroup flush>
          {!hasError && (
            <>
              <ListGroupItem>
                <span
                  onClick={() => {
                    props.setPage("group-memberList");
                  }}
                  style={
                    localStorage.getItem("lang") === "ARABIC"
                      ? linkReverseStyles
                      : {}
                  }
                >
                  <Translate
                    content="menu.current_meeting"
                    className="textStyle"
                    style={loadingStyle}
                  />
                  <FontAwesomeIcon style={iconStyle} icon="angle-right" />
                </span>
              </ListGroupItem>

              <ListGroupItem>
                <NavLink
                  onClick={endMeeting}
                  to={{
                    pathname: `/Groups`,
                  }}
                  style={
                    localStorage.getItem("lang") === "ARABIC"
                      ? linkReverseStyles
                      : {}
                  }
                >
                  <Translate
                    content="menu.end_meeting"
                    className="textStyle"
                    style={loadingStyle}
                  />
                  <FontAwesomeIcon style={iconStyle} icon="angle-right" />
                </NavLink>
              </ListGroupItem>
            </>
          )}
          <ListGroupItem>
            <span
              onClick={() => props.setPage("group-previousMeetings")}
              style={
                localStorage.getItem("lang") === "ARABIC"
                  ? linkReverseStyles
                  : {}
              }
            >
              <Translate
                content="menu.prev_meeting"
                className="textStyle"
                style={loadingStyle}
              />
              <FontAwesomeIcon style={iconStyle} icon="angle-right" />
            </span>
          </ListGroupItem>
          {offlineMeeting && (
            <ListGroupItem>
              <span
                onClick={() => {
                  props.setPage("group-offlineMeeting");
                }}
                style={
                  localStorage.getItem("lang") === "ARABIC"
                    ? linkReverseStyles
                    : {}
                }
              >
                <Translate
                  content="menu.offline_meeting"
                  className="textStyle"
                  style={loadingStyle}
                ></Translate>
                {hasError ? (
                  <FontAwesomeIcon
                    style={{
                      position: "absolute",
                      color: "red",
                    }}
                    icon="exclamation-circle"
                  />
                ) : (
                  false
                )}
                <FontAwesomeIcon style={iconStyle} icon="angle-right" />
              </span>
            </ListGroupItem>
          )}
        </ListGroup>
      </Container>
    </div>
  );
};

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
  color: "initial",
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    isCreatingMeeting: state.isCreatingMeeting,
    isConnected: state.isConnected,
    loadedVsla: state.loadedvslas,
    vsla: state.vsla,
    group: state.group,
    ongoingMeeting: state.currentOngoingMeeting,
    vslaSummary: selectCreatedVslaInfo(state),
    groupVsla: state.vsla,
    meetingTransactions: state.meetingTransactions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearLoadedMeeting: ()=>dispatch(meetingLoaded([])),
    setPage: (page) => dispatch(setCurrentPage(page)),
    patchOngoingMeeting: (url, data, headers) =>
      dispatch(patchMeeting(url, data, headers)),
    fetchOngoingMeeting: (url, headers) => dispatch(fetchMeeting(url, headers)),
    updateSettings: (data) => dispatch(updateGroupSettings(data)),
    patchGroup: (url, data, headers) =>
      dispatch(patchGroupItem(url, data, headers)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingsMenu);
