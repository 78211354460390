import axios from "axios";
import { logoutAction } from "../actions/auth";
import store from "../index";

const backendAPI = axios.create({
  baseURL:
    window.REACT_APP_PWA_APP_DOMAIN || "https://backend.staging.vsla.online",
});

const AuthAPI = axios.create({
  baseURL: window.REACT_APP_PWA_AUTH,
});

const transactionAPI = axios.create({
  baseURL:
    window.REACT_APP_PWA_TRANSACTIONS ||
    "https://transaction-service.staging.hivenetwork.online/transactions/vsla",
});

const blockchainAPI = axios.create({
  baseURL:
    window.REACT_APP_BLOCKCHAIN_API || "https://stellar.staging.vsla.online",
});

const locationAPI = axios.create({
  baseURL:
    window.REACT_APP_LOCATION_SERVICE ||
    "https://location-service.staging.hivenetwork.online",
  headers: {
    accessKey: window.REACT_APP_PWA_LOCATION_ACCESS_KEY,
  },
});

[backendAPI, transactionAPI, blockchainAPI, AuthAPI].forEach((api) => {
  api.interceptors.request.use(
    (request) => {
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const status = (error && error.response && error.response.status) || 500;
      if ([403, 401].includes(status)) {
        const lang = localStorage.getItem("lang") || "ENGLISH";
        store.dispatch(logoutAction());
        deleteCookie("token");
        deleteCookie("tokenBC");
        localStorage.setItem("lang", lang);
        if (window.location.pathname === "/") {
          return;
        }
        window.location.href = "/";
      }
      return Promise.reject(error);
    }
  );
});

const setAuthorizationToken = (token, tokenBC) => {
  if (token && tokenBC) {
    backendAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    locationAPI.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    blockchainAPI.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${tokenBC}`;
  } else {
    delete backendAPI.defaults.headers.common["Authorization"];
    delete blockchainAPI.defaults.headers.common["Authorization"];
    delete locationAPI.defaults.headers.common["Authorization"];
  }
};

//expire cookie
const deleteCookie = (name) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};
export {
  backendAPI,
  transactionAPI,
  blockchainAPI,
  setAuthorizationToken,
  AuthAPI,
  locationAPI,
};
