import React, { useEffect, useState } from "react";
import { ListGroupItem, ListGroupItemText, ListGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { retryAction } from "../../store/actions/members/createMember";
import { memberLoaded } from "../../store/actions/members/getMember";
import DropMenu from "../layout/RetryComponent";
import { setCurrentPage } from "../../store/reducers/navigation";
import { getTranslation } from "../../view/shared/helpers";

function MemberItem(props) {
  const [pointerEvent, setPointerEvent] = useState(false);
  const [opacity, setOpacity] = useState("");
  const cashedOutMembers = () => {
    const found = props.membersToCashout.some(
      (el) => el.name === props.member_details.name
    );
    if (!found && props.membersToCashout.length) {
      setOpacity("0.4");
      setPointerEvent(true);
    }
  };

  useEffect(() => {
    if (props.cashoutStarted) cashedOutMembers();
  }, []);

  const iconStyle = {
    fontSize: "20px",
    float: "left",
    color: "#E36F1E",
  };

  const listStyle = {
    color: "#343a40",
    fontFamily: "Fira Sans",
    fontSize: "20px",
    textAlign: localStorage.getItem("lang") === "ARABIC" ? "right" : "left",
    marginLeft: "1.5em",
    marginTop: "0.4em",
    width: "100%",
    marginBottom: "0.4em",
    lineHeight: 1,
    opacity: opacity,
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  };

  return (
    <ListGroup flush key={props.cacheKey}>
      <ListGroupItem key={props.member_details.pubkey}>
        <span
          onClick={(e) => {
            if (props.isPending || props.isFailed) {
              e.preventDefault();
              return;
            }
            props.setMember(props.member_details);
            props.setPage("group-memberActions");
          }}
          style={{
            display: "flex",
            textDecoration: "none",
            alignItems: "baseline",
            pointerEvents: pointerEvent ? "none" : "",
          }}
        >
          <FontAwesomeIcon style={iconStyle} icon="user" />
          <ListGroupItemText style={listStyle}>
            {props.member_details.full_name}
          </ListGroupItemText>
          {props.isPending ? (
            <span
              className="dots-loader"
              style={{
                color: "#E36F1E",
                fontSize: 12,
                margin: "auto",
                whiteSpace: "nowrap", // Ensure text stays on one line
              }}
            >
              {getTranslation("span", "processing")}
              <span style={{ position: "absolute" }}></span>
            </span>
          ) : props.isFailed ? (
            <span
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>
                {getTranslation("title_copy", "status")}: <span style={{ color: "red", padding: '0 2px' }}>{getTranslation("alert", "failed")}</span>
              </span>
              <DropMenu
                key={props.cacheKey}
                cacheKey={props.cacheKey}
                retryFunc={retryAction}
                member={props.member_details}
                vslaId={props.vsla_id}
                namespace={"members"}
                showDelete={props.members.length > 0}
              />
            </span>
          ) : (
            ""
          )}
        </span>
      </ListGroupItem>
    </ListGroup>
  );
}

const mapStateToProps = (state) => ({
  pendingMembers: state.pendingTransactions,
  memberBalance: state.account.members,
  cashoutStarted: state.cashoutStarted,
  membersToCashout: state.membersTocashout,
});

const mapDispatchToProps = (dispatch) => ({
  setMember: (m) => dispatch(memberLoaded(m)),
  setPage: (page) => dispatch(setCurrentPage(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberItem);
