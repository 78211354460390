import React from "react";
import { Row, Col, FormGroup, Input, Container } from "reactstrap";
import { Route } from "react-router-dom";

const buttonStyle = {
  backgroundColor: "#C0C9D1",
  flex: "1",
  color: "white",
};

const rowStyle = {
  fontFamily: "Fira Sans",
  fontSize: "22px",
  textAlign: "center",
  fontWeight: "bold",
};

const headerStyle = {
  color: "#E36F1E",
  fontFamily: "quicksand",
  fontWeight: "bolder",
  fontSize: "1.8em",
  textAlign: "center",
  paddingBottom: "0.5em",
};

const MainMenu = (props) => {

  const handleInput = (e) => {
    localStorage.setItem("lang", e.target.value);
    window.location.reload();
  }

  return (
    <div>
      <div>
        <h6 style={headerStyle}>LANGUAGE/LANGAGE</h6>
      </div>
      <br />
      <Container>
        <Route
          exact
          path="/"
          render={(props) => (
            <React.Fragment>
              <Row style={rowStyle}>
                <Col>
                  <FormGroup>
                    <Input
                      type="submit"
                      value="العربية"
                      name="submit"
                      className="btn"
                      onClick={(e) => {
                        handleInput(
                          { target: { value: "ARABIC" } },
                          "ar"
                        );
                      }}
                      style={{
                        buttonStyle,
                        backgroundColor: "#F4B223",
                        color: "#FFFFFF",
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row style={rowStyle}>
                <Col>
                  <FormGroup>
                    <Input
                      type="submit"
                      value="ENGLISH"
                      name="submit"
                      className="btn"
                      onClick={(e) => handleInput(e, "en")}
                      style={{
                        buttonStyle,
                        backgroundColor: "#F4B223",
                        color: "#FFFFFF",
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row style={rowStyle}>
                <Col>
                  <FormGroup>
                    <Input
                      type="submit"
                      value="FRANÇAIS"
                      name="submit"
                      className="btn"
                      onClick={(e) => handleInput(e, "fr")}
                      style={{
                        buttonStyle,
                        backgroundColor: "#F4B223",
                        color: "#FFFFFF",
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row style={rowStyle}>
                <Col>
                  <FormGroup>
                    <Input
                      type="submit"
                      value="SPANISH"
                      name="submit"
                      className="btn"
                      onClick={(e) => handleInput(e, "sp")}
                      style={{
                        buttonStyle,
                        backgroundColor: "#F4B223",
                        color: "#FFFFFF",
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row style={rowStyle}>
                <Col>
                  <FormGroup>
                    <Input
                      type="submit"
                      value="KISWAHILI"
                      name="submit"
                      className="btn"
                      onClick={(e) => handleInput(e, "sw")}
                      style={{
                        buttonStyle,
                        backgroundColor: "#F4B223",
                        color: "#FFFFFF",
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row style={rowStyle}>
                <Col>
                  <FormGroup>
                    <Input
                      type="submit"
                      value="PORTUGUESE"
                      name="submit"
                      className="btn"
                      onClick={(e) => {
                        e.target.value = "PORTUGUESE";
                        handleInput(e, "pt");
                      }}
                      style={{
                        buttonStyle,
                        backgroundColor: "#F4B223",
                        color: "#FFFFFF",
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row style={rowStyle}>
                <Col>
                  <FormGroup>
                    <Input
                      type="submit"
                      value="LEBANESE ENGLISH"
                      name="submit"
                      className="btn"
                      onClick={(e) => {
                        e.target.value = "LEBANESE";
                        handleInput(e, "en");
                      }}
                      style={{
                        buttonStyle,
                        backgroundColor: "#F4B223",
                        color: "#FFFFFF",
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </React.Fragment>
          )}
        />
      </Container>
    </div>
  );
}
export default MainMenu;