export function creatingFund(state = false, action) {
  switch (action.type) {
    case "IS_CREATING_FUND":
      return action.isCreating;
    default:
      return state;
  }
}

export function vslaFund(state = [], action) {
  switch (action.type) {
    case "CREATE_FUND":
      return action.fund;
    default:
      return state;
  }
}

export function createFundError(state = false, action) {
  switch (action.type) {
    case "CREATE_FUND_ERROR":
      return action.error;
    default:
      return state;
  }
}
