import { triggerAction } from "../constants";
import { AuthAPI, backendAPI, setAuthorizationToken } from "../api";
import { getGeolocation } from "../../view/shared/helpers";
import { v4 as uuid } from "uuid";

export const getUser = () => async (dispatch, getState) => {
  try {
    const { user } = getState();
    const headers = {
      headers: {
        Authorization: "Bearer " + user.token || localStorage.getItem("token"),
      },
    };
    const { data } = await backendAPI.get(`/api/v1/users/me`, headers);
    const userDetails = await AuthAPI.get(`/api/v1/users/${data.id}`, headers);
    const obj = {
      ...user,
      ...data,
      hashed_resetcode: null,
      ...userDetails.data,
    };
    dispatch(triggerAction("SET_USER_DATA", obj));
    return Promise.resolve(obj);
  } catch (error) {
    console.log("Error: ", error);
    return Promise.reject();
  }
};

export const logoutAction = () => async (dispatch, getState) => {
  const { user } = getState();
  const lang = localStorage.getItem("lang") || "ENGLISH";
  const session_id = sessionStorage.getItem("ta_id");
  const _country = localStorage.getItem("_country");
  if (!user) {
    return;
  }
  dispatch(
    trackActivity({
      session_id,
      logged_in: false,
      person_name: user.full_name,
      user_id: user.id,
      logout_time: new Date().toISOString(),
    })
  );
  // disconnect user from web socket server
  dispatch({
    pushEvent: "disconnect",
    receiveEvent: "response",
    emit: true,
    payload: {},
  });
  localStorage.removeItem("uConnected");
  sessionStorage.removeItem("ta_id");
  localStorage.clear();
  localStorage.setItem("lang", lang);
  localStorage.setItem("_country", _country);
  dispatch(triggerAction("RESET"));
  dispatch(triggerAction("SET_USER_DATA", null));
  setAuthorizationToken(false);
};

export const handleSocketConnection =
  (pushEvent, userId) => async (dispatch) => {
    dispatch({
      pushEvent,
      receiveEvent: "response",
      emit: true,
      metadata: {},
      payload: {
        userId,
        applicationName: `vsla_${process.env.REACT_APP_PWA_APP_ENV}`,
      },
    });
  };
export const trackActivity = (data) => async (dispatch) => {
  const position = await getGeolocation().catch(() => Promise.resolve());
  let geoLocation = null;
  if (position) {
    let latitude = position.coords.latitude;
    let longitude = position.coords.longitude;
    geoLocation = {
      longitude,
      latitude,
    };
  }
  dispatch(
    handleSocketConnection(
      `${data.logged_in ? "" : "dis"}connected`,
      data.user_id
    )
  );

  dispatch({
    pushEvent: "action-log",
    receiveEvent: "response",
    emit: true,
    callback: () => {},
    cacheKey: "",
    metadata: {
      userId: null,
    },
    payload: {
      data: {
        login_time: null,
        logout_time: null,
        ...data,
        geoLocation,
        uuid: uuid(),
        app: "vsla",
        env: window.REACT_APP_PWA_APP_ENV,
      },
    },
  });
};
