export function isCreatingUser(state = false, action) {
  switch (action.type) {
    case "IS_CREATING_USER":
      return action.isCreatingUser;
    default:
      return state;
  }
}

export function createdUser(state = [], action) {
  switch (action.type) {
    case "CREATE_USER":
      return action.user;
    default:
      return state;
  }
}

export function createUserDetails(state = [], action) {
  switch (action.type) {
    case "CREATE_USER_DETAILS":
      return action.userDetails;
    default:
      return state;
  }
}

export function createUserError(state = false, action) {
  switch (action.type) {
    case "CREATE_USER_ERROR":
      return action.error;
    default:
      return state;
  }
}

export function loadingToggleState(state = false, action) {
  switch (action.type) {
    case "LOADING_TOGGLE_ACTION":
      return {
        ...state,
        loadingToggleState: action.loadingToggleState
      };
    default:
      return state;
  }
}