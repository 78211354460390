import React from "react";
import { FormGroup } from "reactstrap";
import Translate from "react-translate-component";

export const FormButton = (props) => (
  <FormGroup>
    <Translate
      component="button"
      style={props.style}
      type={props.type}
      className={props.className}
      onClick={props.handleClick}
      content={props.value}
      disabled={props.disabled}
    />
  </FormGroup>
);
