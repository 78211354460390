import React from "react";
import {
  Container,
  Card,
  CardHeader,
  Alert,
  ListGroupItem,
  ListGroup,
} from "reactstrap";
import { Input, FormGroup } from "reactstrap";
import counterpart from "counterpart";
import Translate from "react-translate-component";
import ZARMA from "../../view/shared/lang/ZARMA";
import ENGLISH from "../../view/shared/lang/ENGLISH";
import SWAHILI from "../../view/shared/lang/SWAHILI";
import HAOUSSA from "../../view/shared/lang/HAOUSSA";
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS";
import SPANISH from "../../view/shared/lang/SPANISH";
import { connect } from "react-redux";
import { selectCreatedVslaInfo } from "../../store/reducers/getVslas";
import { v4 as uuid } from "uuid";
import { postCreateGroup } from "../../store/actions/createVsla";

import LEBANESE from "../../view/shared/lang/LEBANESE";
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE";
import ARABIC from "../../view/shared/lang/ARABIC";
import Settings from "./Settings";
import FundSummary from "./FundSummary";
import MemberMenu from "../members/MemberMenu";
import GroupSummary from "./GroupSummary";
import VslaMenu from "./VslaMenu";
import { setCurrentPage } from "../../store/reducers/navigation";
import PayFine from "../members/PayFine";
import MemberOverview from "../members/MemberOverview";
import RepayLoan from "../members/RepayLoan";
import WriteOffLoan from "../members/WriteOffLoan";
import CreateLoan from "../members/CreateLoan";
import SellShares from "../members/SellShares";
import BuyShares from "../members/BuyShares";
import WelfareGiven from "../members/WelfareGiven";
import MembershipFee from "../members/MembershipFee";
import WelfareRequest from "../members/WelfareRequest";
import MemberAction from "../members/MemberAction";
import MeetingsMenu from "../meetings/MeetingsMenu";
import PreviousMeetings from "../meetings/PreviousMeetings";
import CreateMeeting from "../meetings/CreateMeeting";
import PreviousMeetingItem from "../meetings/MeetingItem";
import { fetchPreviousMeetingsData } from "../../store/actions/meetings";
import ActiveMeetingTransactions from "../meetings/ActiveMeetingTransactions";
import AddMember from "../members/AddMember";
import AddGroup from "./AddGroup";
import AddGroupFund from "./AddGroupFund";
import { getUser, logoutAction } from "../../store/actions/auth";
import OfflineMeetingItem from "../meetings/OfflineMeetingItem";
import otherIncome from "../otherIncomeAndExpenses/otherIncome";
import otherExpenses from "../otherIncomeAndExpenses/otherExpenses";
import CashoutError from "../cashout/CashoutError";
import { canCashout } from "../../store/reducers/cashout/actions";
import EndCycleCashout from "../cashout/EndCycleCashout";
import { intToArabic } from "../../view/shared/utilities/arabicNumParser";
import { getTranslation } from "../../view/shared/helpers";
import { useCookies } from "react-cookie";
import { arabicDateConverter } from "../../view/shared/utilities/arabicDateConverter";
import MidCycleCashout from "../cashout/MidCycleCashout";
import StellarLoader from "../layout/StellarLoader";

counterpart.registerTranslations("ARABIC", ARABIC);

counterpart.registerTranslations("PORTUGUESE", PORTUGUESE);
counterpart.registerTranslations("LEBANESE", LEBANESE);
counterpart.registerTranslations("ZARMA", ZARMA);
counterpart.registerTranslations("SWAHILI", SWAHILI);
counterpart.registerTranslations("ENGLISH", ENGLISH);
counterpart.registerTranslations("HAOUSSA", HAOUSSA);
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS);
counterpart.registerTranslations("SPANISH", SPANISH);

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
};

const headerStyle = {
  color: "#E36F1E",
  fontFamily: "quicksand",
  fontWeight: "bolder",
  fontSize: "1.8em",
  textAlign: "center",
  paddingBottom: "0.5em",
};

const cardheaderStyle = {
  backgroundColor: "white",
  padding: ".5rem",
  textAlign: "center",
  fontWeight: "bold",
};

const componentsMap = {
  "group-settings": Settings,
  "group-funds": FundSummary,
  "group-memberList": MemberMenu,
  "group-details": GroupSummary,
  "group-vslaMenu": VslaMenu,
  "group-payFine": PayFine,
  "group-overview": MemberOverview,
  "group-repayLoan": RepayLoan,
  "group-writeOffLoan": WriteOffLoan,
  "group-createLoan": CreateLoan,
  "group-sellShares": SellShares,
  "group-buyShares": BuyShares,
  "group-addWelfare": WelfareGiven,
  "group-membershipFee": MembershipFee,
  "group-requestWelfare": WelfareRequest,
  "group-memberActions": MemberAction,
  "group-meetingsMenu": MeetingsMenu,
  "group-previousMeetings": PreviousMeetings,
  "group-createMeeting": CreateMeeting,
  "group-previousMeetingsItem": PreviousMeetingItem,
  "group-meetingTransactions": ActiveMeetingTransactions,
  "group-addMember": AddMember,
  "group-addGroup": AddGroup,
  "group-addFunds": AddGroupFund,
  "group-offlineMeeting": OfflineMeetingItem,
  "group-addGroupIncome": otherIncome,
  "group-addGroupExpense": otherExpenses,
  "group-cashOut": MidCycleCashout,
  "group-cashOutError": CashoutError,
  "group-endOfCycleCashout": EndCycleCashout,
  "group-createGroup": AddGroup,
};

const Groups = (props) => {
  const [DynamicComponent, setComponent] = React.useState(null);
  // eslint-disable-next-line no-unused-vars
  const [_, removeCookie] = useCookies(["token"]);
  const [groupPayload, setPayload] = React.useState(null);

  const setPayloadData = (items) => setPayload({ ...groupPayload, ...items });
  React.useEffect(() => {
    if (!groupPayload || !Array.isArray(groupPayload.accounts)) {
      return;
    }
    submitCreateGroup();
  }, [groupPayload]);

  const submitCreateGroup = () => {
    if (groupPayload) {
      const data = groupPayload.group;
      const headers = {
        headers: {
          Authorization:
            "Bearer " + props.user.token || localStorage.getItem("token"),
        },
      };

      if (!data.establishment_date.length) {
        delete data.establishment_date;
      }
      props.sendGroupData(
        `/api/v1/groups/?requestId=${uuid()}`,
        {
          ...groupPayload,
          stellarToken: localStorage.getItem("tokenBC"),
        },
        headers
      );
    }
  };

  const removeTokens = () => {
    removeCookie("token");
    removeCookie("tokenBC");
  };
  React.useEffect(() => {
    if (!props.error) {
      return;
    }
    removeTokens();
  }, [props.error]);

  React.useEffect(() => {
    if (!props.currentPage) {
      props.setPage("group-main");
    }
  }, []);

  React.useEffect(() => {
    if (!props.currentPage) {
      return;
    }
    if (props.currentPage === "group-previousMeetings") {
      props.fetchPreviousMeetings();
    }
    const component = componentsMap[props.currentPage];
    if (component) {
      setComponent(component);
    }
    if (
      props.currentPage === "group-details" ||
      props.currentPage === "group-meetingsMenu" ||
      props.currentPage === "group-memberList"
    ) {
      props.canCashout();
    }
  }, [props.currentPage]);

  const numberOfMembers = () => {
    const { group, members } = props.vslaSummary[0];
    if (group.members && group.members.length > members.length) {
      return group.members.length;
    } else {
      const activeMembers = members.filter(
        (member) => member.blockchain_wallet_id !== null
      );
      return (activeMembers || []).length;
    }
  };

  if (props.vslasArrayLoading) {
    return <StellarLoader />;
  }

  if (
    !props.vslasArrayLoading &&
    props.vslas.length < 1 &&
    !["group-addFunds", "group-createGroup"].includes(props.currentPage)
  ) {
    const create_group = counterpart.translate(
      "placeholder_copy.create_group_submit"
    );

    return (
      <div>
        <Container>
          <Alert color="warning">
            <span>
              <Translate
                content="title_copy.no_vsla"
                style={{ fontSize: "16px", fontFamily: "Fira Sans" }}
              />
            </span>
          </Alert>
          <FormGroup
            onClick={() => {
              props.setPage("group-createGroup");
            }}
          >
            <Input
              type="submit"
              value={create_group}
              name="button"
              className="btn btn-primary"
            />
          </FormGroup>
        </Container>
      </div>
    );
  }

  return (
    <>
      {[null, "group-main"].includes(props.currentPage) ? (
        <div>
          <div
            style={{
              backgroundColor: "white",
              padding: ".5rem",
              textAlign: "center",
            }}
          >
            <Translate
              content="title_copy.group_header"
              component="h4"
              style={headerStyle}
            />
          </div>
          <Container>
            {props.vslaSummary.map(({ group, id, metadata }) => (
              <Card key={group.id} className="shadow p-3 mb-5 bg-white rounded">
                <span
                  key={id}
                  onClick={() => {
                    sessionStorage.setItem("current_group_id", group.id);
                    props.setPage("group-vslaMenu");
                  }}
                  className="textStyle"
                  style={{
                    ...loadingStyle,
                    textAlign:
                      localStorage.getItem("lang") === "ARABIC"
                        ? "right"
                        : "left",
                  }}
                >
                  <CardHeader style={cardheaderStyle}>{group.name}</CardHeader>
                  <br />
                  <p>
                    <Translate content="cards.total_member" />:{" "}
                    {localStorage.getItem("lang") === "ARABIC"
                      ? intToArabic(numberOfMembers())
                      : numberOfMembers()}
                  </p>
                  {metadata !== undefined && (
                    <p>
                      <Translate content="cards.share_value" />:{" "}
                      {localStorage.getItem("lang") === "ARABIC"
                        ? intToArabic(metadata.share_value)
                        : metadata.share_value}
                    </p>
                  )}
                  {!["ARABIC", "LEBANESE"].includes(
                    localStorage.getItem("lang")
                  ) && (
                    <p>
                      <Translate content="cards.cycle_duration" />:{" "}
                      {group.cycle_duration}{" "}
                      <Translate content="cards.months" />
                    </p>
                  )}
                  <hr />
                  <p>
                    <Translate content="cards.country" />:{" "}
                    {getTranslation("countries", group.country)}
                  </p>
                  <p>
                    <Translate content="cards.locality" />: {group.locality}
                  </p>
                  <ListGroup>
                    {!["ARABIC", "LEBANESE"].includes(
                      localStorage.getItem("lang")
                    ) &&
                      group.country !== "Lebanon" && (
                        <ListGroupItem>
                          <Translate
                            content="cards.established_on"
                            component="b"
                          />
                          :{" "}
                          {props.groupWithEstablishmentDate
                            .establishment_date || group.establishment_date}
                        </ListGroupItem>
                      )}
                    <ListGroupItem>
                      <Translate content="cards.created_at" component="b" />:{" "}
                      {!["ARABIC"].includes(localStorage.getItem("lang")) &&
                        new Date(group.created_at).toDateString()}
                      {["ARABIC"].includes(localStorage.getItem("lang")) &&
                        arabicDateConverter(group.created_at)}
                    </ListGroupItem>
                  </ListGroup>
                </span>
              </Card>
            ))}
          </Container>
        </div>
      ) : (
        <>
          {!!DynamicComponent && (
            <DynamicComponent
              setPayloadData={setPayloadData}
              submitCreateGroup={submitCreateGroup}
            />
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    currentPage: state.currentPage,
    vslasArrayLoading: state.vlsasLoadingStarted,
    groupWithEstablishmentDate: state.group,
    vslas: state.loadedvslas,
    vsla: state.vsla,
    error: state.vlsasLoadingError,
    vslaSummary: selectCreatedVslaInfo(state),
    members: state.loadedvslaMembers.filter(
      (member) =>
        member.status === "Active" && member.blockchain_wallet_id !== null
    ),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchPreviousMeetings: () => dispatch(fetchPreviousMeetingsData()),
    setPage: (page) => dispatch(setCurrentPage(page)),
    getUser: () => dispatch(getUser()),
    canCashout: () => dispatch(canCashout()),
    logout: () => dispatch(logoutAction()),
    sendGroupData: (url, vsla, headers) =>
      dispatch(postCreateGroup(url, vsla, headers, ownProps)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
