import counterpart from "counterpart";
import ZARMA from "../lang/ZARMA";
import ENGLISH from "../lang/ENGLISH";
import SWAHILI from "../lang/SWAHILI";
import HAOUSSA from "../lang/HAOUSSA";
import FRANÇAIS from "../lang/FRANÇAIS";
import SPANISH from "../lang/SPANISH";
import LEBANESE from "../lang/LEBANESE";
import PORTUGUESE from "../lang/PORTUGUESE";
import ARABIC from "../lang/ARABIC";

const defaultGroupSettings = {
  savingsCashOutType: "Share based",
  fineCashOutType: "Equally",
  welfareCashOutType: "Equally",
  membershipCashOutType: "Refund",
  loanInterestCashOutType: "Share based",
};

const manifestURLs = {
  production: "/manifest.json",
  staging: "/manifest.staging.json",
  uat: "/manifest.uat.json",
  development: "/manifest.staging.json",
};
const initLanguageTranslations = () => {
  counterpart.registerTranslations("ARABIC", ARABIC);
  counterpart.registerTranslations("PORTUGUESE", PORTUGUESE);
  counterpart.registerTranslations("LEBANESE", LEBANESE);
  counterpart.registerTranslations("ZARMA", ZARMA);
  counterpart.registerTranslations("SWAHILI", SWAHILI);
  counterpart.registerTranslations("ENGLISH", ENGLISH);
  counterpart.registerTranslations("HAOUSSA", HAOUSSA);
  counterpart.registerTranslations("FRANÇAIS", FRANÇAIS);
  counterpart.registerTranslations("SPANISH", SPANISH);
};

const getTranslation = (keyLvl1 = "", keyLvl2 = "") => {
  /*
        language Object
        {
            keyLvl1: {
                keylvl2: value
            }
        }
    */
  if (!!ENGLISH[keyLvl1] && !!ENGLISH[keyLvl1][keyLvl2]) {
    return counterpart.translate(`${keyLvl1}.${keyLvl2}`);
  }
};

const checkOnlineStatus = async () => {
  try {
    await fetch("https://www.google.com/", {
      mode: "no-cors",
    });
    return true;
  } catch (err) {
    return false;
  }
};

const detectNetworkStatus = (
  fn = () => console.log("you didn't pass a function to be called")
) => {
  const handleNetworkChange = async () => {
    const result = await checkOnlineStatus();
    fn(result);
  };
  setInterval(() => {
    handleNetworkChange();
  }, 1000);
};

const getUserIPData = async () => {
  try {
    const response = await fetch(
      `https://ipinfo.io/json?token=${window.REACT_APP_PWA_IPINFO_ACCESS_KEY}`
    );
    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`);
    }
    const data = await response.json();
    localStorage.setItem("_country", data.country);
    localStorage.setItem("_ip", data.ip);
  } catch (err) {
    console.log("error: ", err);
  }
};

// to be replaced by the below function
const getUserGeoData = (
  success,
  errors,
  options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  }
) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(success, errors, options);
  }
};

const getGeolocation = () =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });

const getNestedObject = (nestedObj, pathArr, defaultReturn = {}) => {
  const data = pathArr.reduce(
    (obj, key) => (obj && obj[key] !== "undefined" ? obj[key] : undefined),
    nestedObj
  );
  return data || defaultReturn;
};

const countryMap = {
  NI: "Niger",
  TZ: "Tanzania",
  RW: "Rwanda",
  ZM: "Zambia",
  HN: "Honduras",
  NG: "Nigeria",
  LB: "Lebanon",
  MZ: "Moçambique",
  GH: "Ghana",
  // Add other country codes and names as needed
};

// Function to get the country name based on the code
const getCountryName = (countryCode) => {
  return countryMap[countryCode] || "N/A";
};

const navigationMap = {
  group: {
    main: { backPage: null },
    meetings: { backPage: "main" },
    funds: { backPage: "details" },
    settings: { backPage: "details" },
    details: { backPage: "vslaMenu" },
    overview: { backPage: "memberActions" },
    memberList: { backPage: "details" },
    memberActions: { backPage: "memberList" },
    buyShares: { backPage: "memberActions" },
    sellShares: { backPage: "memberActions" },
    payFine: { backPage: "memberActions" },
    requestWelfare: { backPage: "memberActions" },
    addWelfare: { backPage: "memberActions" },
    createLoan: { backPage: "memberActions" },
    repayLoan: { backPage: "memberActions" },
    membershipFee: { backPage: "memberActions" },
    addGroupIncome: { backPage: "memberActions" },
    addGroupExpense: { backPage: "memberActions" },
    writeOffLoan: { backPage: "memberActions" },
    cashOut: { backPage: "memberActions" },
    vslaMenu: { backPage: "main" },
    meetingsMenu: { backPage: "details" },
    previousMeetings: { backPage: "meetingsMenu" },
    createMeeting: { backPage: "meetingsMenu" },
    previousMeetingsItem: { backPage: "previousMeetings" },
    meetingTransactions: { backPage: "memberList" },
    addMember: { backPage: "memberList" },
    offlineMeeting: { backPage: "meetingsMenu" },
    cashOutError: { backPage: "memberList" },
    endOfCycleCashout: { backPage: "memberList" },
    createGroup: { backPage: "main" },
    addFunds: { backPage: "main" },
  },
};

export {
  manifestURLs,
  navigationMap,
  getTranslation,
  getUserIPData,
  getGeolocation,
  getUserGeoData,
  detectNetworkStatus,
  defaultGroupSettings,
  initLanguageTranslations,
  getNestedObject,
  getCountryName,
};
