import { blockchainAPI } from "../../api";

export const memberLoadStarted = (bool) => ({
  type: "MEM_LOAD_START",
  isloading: bool,
});

export const memberLoaded = (member) => ({
  type: "LOADED_MEMBER",
  member,
});

export const memberLoadError = (bool) => ({
  type: "MEM_LOAD_ERROR",
  error: bool,
});

export function fetchMember(url, headers) {
  return async (dispatch) => {
    dispatch(memberLoadStarted(true));
    try {
      const res = await blockchainAPI.get(url, headers);
      dispatch(memberLoaded(res.data === null ? [] : res.data));
      dispatch(memberLoadStarted(false));
      Promise.resolve();
    } catch (e) {
      return dispatch(memberLoadError(true));
    }
  };
}
