import React from "react";
import counterpart from "counterpart";
import Translate from "react-translate-component";

import ZARMA from "../lang/ZARMA";
import ENGLISH from "../lang/ENGLISH";
import SWAHILI from "../lang/SWAHILI";
import HAOUSSA from "../lang/HAOUSSA";
import FRANÇAIS from "../lang/FRANÇAIS";
import SPANISH from "../lang/SPANISH";
import LEBANESE from "../lang/LEBANESE";
import PORTUGUESE from "../lang/PORTUGUESE";
import ARABIC from "../lang/ARABIC";

counterpart.registerTranslations("ARABIC", ARABIC);
counterpart.registerTranslations("PORTUGUESE", PORTUGUESE);
counterpart.registerTranslations("LEBANESE", LEBANESE);
counterpart.registerTranslations("ZARMA", ZARMA);
counterpart.registerTranslations("SWAHILI", SWAHILI);
counterpart.registerTranslations("ENGLISH", ENGLISH);
counterpart.registerTranslations("HAOUSSA", HAOUSSA);
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS);
counterpart.registerTranslations("SPANISH", SPANISH);

counterpart.setLocale(localStorage.getItem("lang"));

export const SuccessMessage = () => {
  return (
    <Translate
      content="notifications.success"
      component="div"
      style={successStyle}
    />
  );
};

export const ErrorMessage = () => {
  return (
    <Translate
      content="notifications.error"
      component="div"
      style={failStyle}
    />
  );
};

export const WarningMessage = ({ msg }) => {
  return (
    <Translate
      content={msg || "notifications.cancel_txn"}
      component="div"
      style={warningStyle}
    />
  );
};

const successStyle = {
  fontFamily: "quicksand",
  fontSize: "20px",
  color: "green",
  fontWeight: "bolder",
};

const failStyle = {
  fontFamily: "quicksand",
  fontSize: "20px",
  color: "red",
  fontWeight: "bolder",
};

const warningStyle = {
  fontFamily: "quicksand",
  fontSize: "20px",
  color: "blue",
  fontWeight: "bolder",
};
