export function geoDataRequestStartedLat(state = false, action) {
  switch (action.type) {
    case "IS_REQUESTING_GEODATA_LAT":
      return action.isRequestingLat;
    default:
      return state;
  }
}

export function geoDataRequestStartedLong(state = false, action) {
  switch (action.type) {
    case "IS_REQUESTING_GEODATA_LONG":
      return action.isRequestingLong;
    default:
      return state;
  }
}

export function geoDataReturnedLat(state = "", action) {
  switch (action.type) {
    case "GET_GE0DATA_LAT":
      return action.lat;
    default:
      return state;
  }
}

export function geoDataReturnedLong(state = "", action) {
  switch (action.type) {
    case "GET_GE0DATA_LONG":
      return action.long;
    default:
      return state;
  }
}

export function geoDataError(state = false, action) {
  switch (action.type) {
    case "GEO_DATA_ERROR":
      return action.error;
    default:
      return state;
  }
}
