import "kaios-gaia-l10n";
import React from "react";
import ReactDOM from "react-dom";
import App from "./view/app";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "typeface-quicksand";
import "typeface-fira-sans";
import { Provider } from "react-redux";
import store from "./store/index";
import { setAuthorizationToken } from "./store/api";

setAuthorizationToken(
  localStorage.getItem("token"),
  localStorage.getItem("tokenBC")
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    <App />
  </Provider>
  </React.StrictMode>,
  document.getElementById("app")
);

if (window.Cypress) {
  window.store = store;
}
