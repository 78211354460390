import React from "react";
import { connect } from "react-redux";
import { triggerAction } from "../../../store/constants";
import { defaultFormConfigs } from "./utils";
import Selector from "../Selector";
import { fetchLocations } from "../../../store/actions/location";
import { getTranslation } from "../../../view/shared/helpers";
import { Input } from "reactstrap";
import { v4 as uuid } from "uuid";

const Form = (props) => {
  const [errors, setErrors] = React.useState({});
  const [state, setState] = React.useState({
    [defaultFormConfigs.default.levelOneKey]: props.defaultCountry,
    ...props.selectedLocations,
  });
  const [formConfig] = React.useState({ ...defaultFormConfigs });
  const prevRef = React.useRef(null);
  const [loadingState, setLoadingState] = React.useState(false);

  React.useEffect(() => {
    setState({ ...state, ...props.preset });
  }, [props.preset]);

  React.useEffect(() => {
    if (state.country && state.country.name) {
      const country = state.country.name.toLocaleLowerCase();
      //fetch country specific information
      getData(country);
      const isValidState = isValid();
      props.setLocationData({ ...state, valid: isValidState });
      setErrors({});
    }
    return () => {
      props.setLocationData({});
    };
  }, [state]);

  const getData = async (country) => {
    //Prevent fecthcing data if country has not changed
    if (country !== getPreviousCountry()) {
      setLoadingState(true);
      props
        .getLocationByCountry(country)
        .catch((error) => {
          console.log("Error Occured: ", error);
        })
        .finally(() => {
          setLoadingState(false);
        });
    }
  };

  const getPreviousCountry = () => {
    return (
      prevRef.current &&
      prevRef.current[levelOneName.toLocaleLowerCase()] &&
      prevRef.current[levelOneName.toLocaleLowerCase()].name &&
      prevRef.current[levelOneName.toLocaleLowerCase()].name.toLocaleLowerCase()
    );
  };

  const getFormParameters = () => {
    if (state.country && state.country.name) {
      const country = state.country.name.toLocaleLowerCase();
      const countryConfig = formConfig[country];
      return {
        ...formConfig.default, // Use default configuration as the base
        ...(countryConfig || {}), // Merge with country-specific configuration if available
      };
    }
    return {
      ...formConfig.default, // Use default configuration as the base
    };
  };

  const handleChange = (name, selectedId) => {
    prevRef.current = { ...state };
    onChange(name, selectedId);
  };

  const onChange = (name, val) => {
    //reset state if country changes
    const resetState = resetDependentLevels(name, getFormParameters());
    if (name === levelOneKey) {
      setState(() => ({
        [name]: val,
      }));
    } else
      setState((prevState) => ({
        ...prevState,
        ...resetState,
        [name]: val,
      }));
  };

  const onTextChange = (e) => {
    e.preventDefault();
    prevRef.current = { ...state };
    const { name, value } = e.target;
    setState({ ...state, [name]: { id: uuid(), name: value } });
  };

  const resetDependentLevels = (name, formConfig) => {
    const resetState = {};
    // Define levels with their show status and names from the formConfig
    const levels = [
      { show: formConfig.showLevelOne, key: formConfig.levelOneKey },
      { show: formConfig.showLevelTwo, key: formConfig.levelTwoKey },
      { show: formConfig.showLevelThree, key: formConfig.levelThreeKey },
      { show: formConfig.showLevelFour, key: formConfig.levelFourKey },
      {
        show: formConfig.showLevelFiveSelector,
        key: formConfig.levelFiveKey,
      },
    ];
    // Filter and map the levels to get only those that should be shown, and convert their names to lowercase
    const shownLevels = levels
      .filter((level) => level.show)
      .map((level) => level.key);
    // Find the index of the current level in the shown levels array
    const resetIndex = shownLevels.indexOf(name);
    // If the current level is found, reset all subsequent levels
    if (resetIndex !== -1) {
      shownLevels.slice(resetIndex + 1).forEach((levelName) => {
        resetState[levelName] = { id: null, name: null }; // Set id and name to null for each subsequent level
      });
    }
    // Return the reset state object containing the levels to be reset
    return resetState;
  };

  const {
    showLevelOne,
    requiredLevelOne,
    levelOneName,
    levelOneKey,
    showLevelTwo,
    requiredLevelTwo,
    levelTwoName,
    levelTwoKey,
    showLevelThree,
    requiredLevelThree,
    levelThreeName,
    levelThreeKey,
    showLevelFour,
    requiredLevelFour,
    levelFourName,
    levelFourKey,
    showLevelFiveSelector,
    showLevelFiveTextInput,
    requiredLevelFive,
    levelFiveName,
    levelFiveKey,
    showLevelSix,
    requiredLevelSix,
    levelSixName,
    levelSixKey,
  } = getFormParameters();

  const isValid = () => {
    // Helper function to get the value from state by level name
    const getValue = (key) => state[key];
    // Check the validity for all levels
    const levels = [
      { show: showLevelOne, required: requiredLevelOne, key: levelOneKey },
      { show: showLevelTwo, required: requiredLevelTwo, key: levelTwoKey },
      {
        show: showLevelThree,
        required: requiredLevelThree,
        key: levelThreeKey,
      },
      { show: showLevelFour, required: requiredLevelFour, key: levelFourKey },
      {
        show: showLevelFiveSelector,
        required: requiredLevelFive,
        key: levelFiveKey,
      },
      {
        show: showLevelFiveTextInput,
        required: requiredLevelFive,
        key: levelFiveKey,
      },
      {
        show: showLevelSix,
        required: requiredLevelSix,
        key: levelSixKey,
      },
    ];
    // Determine if the form is valid
    return levels.every(({ show, required, key }) => {
      if (show && required) {
        const value = getValue(key);
        if (value && value.name) {
          return value.name && value.name.trim() !== "";
        } else return false;
      }
      return true;
    });
  };

  // Get children of the given ID from the childrenMap
  const getChildren = React.useCallback(
    (id) => {
      if (props.childrenMap.size) {
        return props.childrenMap.get(id) || [];
      }
      return [];
    },
    [props.childrenMap, state]
  );

  const selectorOptions = React.useCallback(
    (param) => {
      if (!param) return [];
      const children = getChildren(param.id || param);
      if (children.length) {
        return children.map(({ name, id }) => ({
          id,
          name,
        }));
        //check that data for children exists, but no children for selected case
      } else if (props.childrenMap.size) {
        return [param].map(({ name, id }) => ({
          id,
          name,
        }));
      } else {
        return [];
      }
    },
    [state, props.childrenMap]
  );

  // Trigger validation for a specific key
  const triggerValidation = (key, hasError) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [key]: hasError ? getTranslation("locations", `select_${key}`) : "",
    }));
  };

  // Trigger form validation for all levels
  const triggerFormValidation = () => {
    const formParameters = getFormParameters();
    const levels = [
      { show: formParameters.showLevelOne, key: formParameters.levelOneKey },
      { show: formParameters.showLevelTwo, key: formParameters.levelTwoKey },
      {
        show: formParameters.showLevelThree,
        key: formParameters.levelThreeKey,
      },
      {
        show: formParameters.showLevelFour,
        key: formParameters.levelFourKey,
      },
      {
        show: formParameters.showLevelFiveSelector,
        key: formParameters.levelFiveKey,
      },
      {
        show: formParameters.showLevelFiveTextInput,
        key: formParameters.levelFiveKey,
      },
      {
        show: formParameters.showLevelSix,
        key: formParameters.levelSixKey,
      },
    ];

    for (let { show, key } of levels) {
      if (show) {
        const levelValue = state[key];
        if (!levelValue || !levelValue.name) {
          triggerValidation(key, true);
          break; // Stop at the first error found
        } else {
          triggerValidation(key, false);
        }
      }
    }
  };

  React.useEffect(() => {
    if (!props.validateData) {
      return;
    }
    triggerFormValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.validateData]);

  const getStateValue = (obj, key, defaultValue = "") => {
    return obj && obj[key] ? obj[key].name || defaultValue : defaultValue;
  };

  return (
    <span>
      {showLevelOne && (
        <Selector
          name="levelOneSelector"
          selectorOptions={props.countries}
          placeholderText={levelOneName}
          onChange={(item) => handleChange(levelOneKey, item)}
          required={requiredLevelOne}
          defaultOptions={state[levelOneKey] || {}}
          error={errors[levelOneKey]}
          isLoading={loadingState}
          isDisabled={loadingState}
        />
      )}
      {showLevelTwo && (
        <Selector
          name="levelTwoSelector"
          selectorOptions={selectorOptions(state[levelOneKey])}
          placeholderText={levelTwoName}
          onChange={(item) => handleChange(levelTwoKey, item)}
          required={requiredLevelTwo}
          defaultOptions={state[levelTwoKey] || {}}
          error={errors[levelTwoKey]}
          isLoading={loadingState}
          isDisabled={loadingState}
        />
      )}
      {showLevelThree && (
        <Selector
          name="levelThreeSelector"
          selectorOptions={selectorOptions(state[levelTwoKey])}
          placeholderText={levelThreeName}
          onChange={(item) => handleChange(levelThreeKey, item)}
          required={requiredLevelThree}
          defaultOptions={state[levelThreeKey] || {}}
          error={errors[levelThreeKey]}
          isLoading={loadingState}
          isDisabled={loadingState}
        />
      )}
      {showLevelFour && (
        <Selector
          name="levelFourSelector"
          selectorOptions={selectorOptions(state[levelThreeKey])}
          placeholderText={levelFourName}
          onChange={(item) => handleChange(levelFourKey, item)}
          required={requiredLevelFour}
          defaultOptions={state[levelFourKey] || {}}
          error={errors[levelFourKey]}
          isLoading={loadingState}
          isDisabled={loadingState}
        />
      )}
      {showLevelFiveSelector && (
        <Selector
          name="levelFiveSelector"
          selectorOptions={selectorOptions(state[levelFourKey])}
          placeholderText={levelFiveName}
          onChange={(item) => handleChange(levelFiveKey, item)}
          required={requiredLevelFive}
          defaultOptions={state[levelFiveKey] || {}}
          error={errors[levelFiveKey]}
          isLoading={loadingState}
          isDisabled={loadingState}
        />
      )}
      {/* There are fields for level five that are text input rather than select components */}
      {showLevelFiveTextInput && (
        <>
          <Input
            type="text"
            name="locality"
            style={{
              flex: "10",
              padding: "5px",
              fontFamily: "Fira Sans",
              fontSize: "20px",
              height: "50px",
              marginBottom: errors[levelFiveKey] ? "0px" : "15px",
              color: "black",
            }}
            placeholder={levelFiveName}
            onChange={onTextChange}
            value={getStateValue(state, levelFiveKey)}
          />
          {errors[levelFiveKey] && (
            <span
              name={`${errors[levelFiveKey]}Error`}
              style={{ color: "red" }}
            >
              {errors[levelFiveKey]}
            </span>
          )}
        </>
      )}
      {showLevelSix && (
        <>
          <Input
            type="text"
            name="neighbourhood_name"
            style={{
              flex: "10",
              padding: "5px",
              fontFamily: "Fira Sans",
              fontSize: "20px",
              height: "50px",
              marginBottom: "15px",
              color: "black",
            }}
            placeholder={levelSixName}
            onChange={onTextChange}
            value={getStateValue(state, levelSixKey)}
            required={requiredLevelSix}
          />
          {errors[levelSixKey] && (
            <span name={`${errors[levelSixKey]}Error`} style={{ color: "red" }}>
              {errors[levelSixKey]}
            </span>
          )}
        </>
      )}
    </span>
  );
};

const mapStateToProps = (state) => {
  return {
    childrenMap: state.locations.childrenMap,
    countries: state.countries.allCountries,
    locationTransportData: state.locationTransport,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setLocationData: (data) =>
    dispatch(triggerAction("SET_USER_SELECTED_LOCATION_DATA", data)),
  getLocationByCountry: (country) => dispatch(fetchLocations(country)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Form);
