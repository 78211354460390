import React from "react";
import {
  ListGroup,
  ListGroupItem,
  Container,
  Alert,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import counterpart from "counterpart";
import Translate from "react-translate-component";
import ZARMA from "../../view/shared/lang/ZARMA";
import ENGLISH from "../../view/shared/lang/ENGLISH";
import SWAHILI from "../../view/shared/lang/SWAHILI";
import HAOUSSA from "../../view/shared/lang/HAOUSSA";
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS";
import SPANISH from "../../view/shared/lang/SPANISH";
import LEBANESE from "../../view/shared/lang/LEBANESE";
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE";
import ARABIC from "../../view/shared/lang/ARABIC";
import { selectCreatedVslaInfo } from "../../store/reducers/getVslas";
import { connect } from "react-redux";
import { setCurrentPage } from "../../store/reducers/navigation";
import {
  selectCycle,
  selectPreviousMeeting,
} from "../../store/actions/meetings";
import { fullArabicDateConverter } from "../../view/shared/utilities/arabicDateConverter";
import classnames from "classnames";
import { getTranslation } from "../../view/shared/helpers";

counterpart.registerTranslations("ARABIC", ARABIC);

counterpart.registerTranslations("PORTUGUESE", PORTUGUESE);
counterpart.registerTranslations("LEBANESE", LEBANESE);
counterpart.registerTranslations("ZARMA", ZARMA);
counterpart.registerTranslations("SWAHILI", SWAHILI);
counterpart.registerTranslations("ENGLISH", ENGLISH);
counterpart.registerTranslations("HAOUSSA", HAOUSSA);
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS);
counterpart.registerTranslations("SPANISH", SPANISH);

const iconStyle = {
  fontSize: "20px",
  float: "right",
};

const headerStyle = {
  color: "#E36F1E",
  fontFamily: "quicksand",
  fontWeight: "bolder",
  fontSize: "1.8em",
  textAlign: "center",
  paddingBottom: "0.5em",
  margin: "34px 0 0 0",
};

const Meetings = (props) => {
  const [data, setData] = React.useState([]);
  const extractData = () => {
    let key = props.cycle ? props.cycle.id : "active";
    if (props.activeTab === 1) {
      key = "active";
    }
    setData(props.cycleMeetings[key] || []);
  };
  React.useEffect(() => {
    extractData();
  }, []);

  React.useEffect(() => {
    extractData();
  }, [props.cycleMeetings]);

  return (
    <Container>
      {data.length === 0 ? (
        <Alert color="warning">
          <span>
            <Translate
              content="notifications.no_previous_meeting"
              style={{ fontSize: "16px", fontFamily: "Fira Sans" }}
            />
          </span>
        </Alert>
      ) : (
        ""
      )}
      {data.map((prev_meeting) => (
        <ListGroup flush>
          <span
            onClick={() => {
              props.setPage("group-previousMeetingsItem");
              props.setPreviousMeeting(prev_meeting);
            }}
          >
            <ListGroupItem
              key={prev_meeting.id}
              className="textStyle"
              style={{
                textAlign: "center",
                fontFamily: "Fira Sans",
                fontSize: "20px",
              }}
            >
              {!["ARABIC"].includes(localStorage.getItem("lang")) &&
                new Date(prev_meeting.date).toLocaleString(
                  counterpart.translate("locale.date"),
                  {
                    dateStyle: "full",
                  }
                )}
              {["ARABIC"].includes(localStorage.getItem("lang")) &&
                fullArabicDateConverter(prev_meeting.date)}
              {prev_meeting &&
                prev_meeting.suffix &&
                `(${prev_meeting.suffix})`}
              <FontAwesomeIcon style={iconStyle} icon="angle-right" />
            </ListGroupItem>
          </span>
        </ListGroup>
      ))}
    </Container>
  );
};

const Cycles = (props) => {
  return (
    <Container>
      {props.cycles
        .filter((cycle) => !cycle.isActive)
        .map((cycle) => (
          <ListGroup flush>
            <span
              onClick={() => {
                props.setCycle(cycle);
              }}
            >
              <ListGroupItem
                key={cycle.id}
                className="textStyle"
                style={{
                  textAlign: "center",
                  fontFamily: "Fira Sans",
                  fontSize: "20px",
                }}
              >
                {cycle.name}
                <FontAwesomeIcon style={iconStyle} icon="angle-right" />
              </ListGroupItem>
            </span>
          </ListGroup>
        ))}
    </Container>
  );
};

const PreviousMeetings = (props) => {
  const [state, setState] = React.useState({
    isLoaded: false,
    previous_meetings: [],
    lang: localStorage.getItem("lang"),
  });

  const [cycleMeetings, setMeetings] = React.useState({});
  React.useEffect(() => {
    counterpart.setLocale(state.lang);
    let activeCycle;
    for (const item of props.cycles) {
      if (item.isActive) {
        activeCycle = item;
        break;
      }
    }
    const { group, vsla } = props.vslaSummary[0];
    setState({ ...state, group_id: group.id, vsla_id: vsla.id });
    const mapper = {
      active: [],
    };
    const suffixMap = new Map();
    props.previousMeetingsTransactions.forEach((item) => {
      if (!item.cycle_id) {
        return;
      } else if (activeCycle && item.cycle_id === activeCycle.id) {
        const meetings = [...(mapper["active"] || []), item];
        mapper["active"] = meetings;
      } else {
        const meetings = [...(mapper[item.cycle_id] || []), item];
        mapper[item.cycle_id] = meetings;
      }
    });
    // Sort meetings in the group by date
    const sortedMeetings = [...mapper.active].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      // Compare the dates to sort in ascending order
      return dateA - dateB;
    });
    sortedMeetings.forEach((item) => {
      const date = new Date(item.date).toISOString().split("T")[0];
      // Create or increment a suffix for the date
      suffixMap.set(date, (suffixMap.get(date) || 0) + 1);
      item.suffix = suffixMap.get(date);
    });
    setMeetings(mapper);
    if (props.selectedCycle) {
      toggle(2);
    }
  }, []);

  const [activeTab, setTab] = React.useState(1);
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setTab(tab);
      if (tab === 1) {
        props.setCycle(null);
      }
    }
  };

  const map = {
    1: connect(mapStateToProps, mapDispatchToProps)(Meetings),
    2: connect(mapStateToProps, mapDispatchToProps)(Cycles),
  };
  const titles = { 1: "prev_meeting", 2: "cycles_title" };
  const [DynamicComponent, setComponent] = React.useState(map[activeTab]);
  React.useEffect(() => {
    if (!props.selectedCycle) {
      setComponent(map[activeTab]);
      return;
    }
    setComponent(map[1]);
  }, [activeTab]);

  React.useEffect(() => {
    if (!props.selectedCycle && activeTab === 2) {
      setComponent(map[2]);
      return;
    }
    setComponent(map[1]);
  }, [props.selectedCycle]);

  return (
    <div className="tab-div-wrapper">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === 1,
              "active-tab": activeTab === 1,
              "previous-meeting-tabs": true,
            })}
            onClick={() => {
              toggle(1);
            }}
          >
            {getTranslation("span", "current_cycle")}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === 2,
              "active-tab": activeTab === 2,
              "previous-meeting-tabs": true,
            })}
            onClick={() => {
              toggle(2);
            }}
          >
            {getTranslation("span", "previous_cycles")}
          </NavLink>
        </NavItem>
      </Nav>
      <div>
        <h4 style={headerStyle}>
          {!props.selectedCycle
            ? getTranslation("title_copy", titles[activeTab])
            : props.selectedCycle.name}
        </h4>
      </div>
      {
        <DynamicComponent
          cycle={props.selectedCycle}
          activeTab={activeTab}
          cycleMeetings={cycleMeetings}
          previousMeetingsTransactions={props.previousMeetingsTransactions}
        />
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  cycles: state.cycles.cycles,
  selectedCycle: state.cycles.selectedCycle,
  vslaSummary: selectCreatedVslaInfo(state),
  previousMeetingsTransactions: state.previousMeetingsTransactions.meetings,
});

const mapDispatchToProps = (dispatch) => ({
  setCycle: (cycle) => dispatch(selectCycle(cycle)),
  setPreviousMeeting: (item) => dispatch(selectPreviousMeeting(item)),
  setPage: (page) => dispatch(setCurrentPage(page)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PreviousMeetings);
