import React from "react";
import {
  Container,
  Button,
  ListGroup,
  ListGroupItem,
  Badge,
  Input,
  Alert,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import counterpart from "counterpart";
import Translate from "react-translate-component";
import ZARMA from "../../view/shared/lang/ZARMA";
import ENGLISH from "../../view/shared/lang/ENGLISH";
import SWAHILI from "../../view/shared/lang/SWAHILI";
import HAOUSSA from "../../view/shared/lang/HAOUSSA";
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS";
import SPANISH from "../../view/shared/lang/SPANISH";
import { connect } from "react-redux";
import {
  patchVslaItem,
  patchGroupItem,
} from "../../store/actions/updateVslaAndGroup";
import { selectBalance } from "../../store/reducers/getVslaBalance";
import { selectVslaDebtBalance } from "../../store/reducers/getVslaBalance";
import { selectCreatedVslaInfo } from "../../store/reducers/getVslas";
import { toast } from "react-toastify";
import { getMemberBalanceByMemberId } from "../../store/reducers/transactions/actions";
import LEBANESE from "../../view/shared/lang/LEBANESE";
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE";
import ARABIC from "../../view/shared/lang/ARABIC";
import { getTranslation } from "../../view/shared/helpers";
import { setCurrentPage } from "../../store/reducers/navigation";
import StellarLoader from "../layout/StellarLoader";
import { backendAPI, blockchainAPI } from "../../store/api";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../view/shared/utilities/cache";
import ProjectSelector from "../layout/MultiSelector";
import { getVslas } from "../../store/actions/getVslas";
import LocationForm from "../layout/Location/LocationForm";

counterpart.registerTranslations("ARABIC", ARABIC);
counterpart.registerTranslations("PORTUGUESE", PORTUGUESE);
counterpart.registerTranslations("LEBANESE", LEBANESE);
counterpart.registerTranslations("ZARMA", ZARMA);
counterpart.registerTranslations("SWAHILI", SWAHILI);
counterpart.registerTranslations("ENGLISH", ENGLISH);
counterpart.registerTranslations("HAOUSSA", HAOUSSA);
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS);
counterpart.registerTranslations("SPANISH", SPANISH);

const SuccessMessage = () => {
  return (
    <Translate
      content="notifications.edit_success"
      component="div"
      className="toast__success-body"
    />
  );
};

const ErrorMessage = () => {
  return (
    <Translate
      content="notifications.edit_error"
      component="div"
      style={loadingStyle}
    />
  );
};

const iconStyle = {
  fontSize: "20px",
  float: "right",
  transform:
    localStorage.getItem("lang") === "ARABIC" ? "rotateY(180deg)" : "none",
};

const inputStyle = {
  flex: "10",
  padding: "5px",
  fontFamily: "Fira Sans",
  fontSize: "20px",
  marginBottom: "15px",
};

const headerStyle = {
  color: "#E36F1E",
  fontFamily: "quicksand",
  fontWeight: "bolder",
  fontSize: "1.8em",
  textAlign: "center",
  paddingBottom: "0.5em",
  margin: "34px 0 0 0",
};

const optionStyle = {
  padding: ".3rem",
  textAlign: "center",
  fontFamily: "Fira Sans",
  color: "#696969",
  fontStyle: "italic",
};
const linkReverseStyles = {
  display: "flex",
  justifyContent: "space-between",
};

const GroupSummary = (props) => {
  const notify = (msg) => toast.info(msg);

  const [state, setState] = React.useState({
    isEditing: false,
    name: props.vslaSummary[0].group.description,
    location: props.vslaSummary[0].group.locality,
    savings_cycle: props.vslaSummary[0].group.cycle_duration,
    establishment_date: props.vslaSummary[0].group.establishment_date,
    project_ids: props.vslaSummary[0].group.projects,
    country: props.vslaSummary[0].group.country,
    address: props.vslaSummary[0].group.address,
    loading: false,
    showLocationError: false,
  });

  React.useEffect(() => {
    setState({
      ...state,
      name: props.vslaSummary[0].group.description,
      location: props.vslaSummary[0].group.locality,
      savings_cycle: props.vslaSummary[0].group.cycle_duration,
      establishment_date: props.vslaSummary[0].group.establishment_date,
      project_ids: props.vslaSummary[0].group.projects,
      country: props.vslaSummary[0].group.country,
      address: props.vslaSummary[0].group.address,
    });
  }, [props.vslaSummary]);

  const cachedMeeting = useLiveQuery(async () => {
    try {
      const items = await db.offlineMeeting.toArray();
      return items.filter(({ vslaId }) => vslaId === props.vsla.id);
    } catch (error) {
      console.log(`Error: ${error}`);
      return [];
    }
  }, []);

  const [offlineMeeting, setOfflineMeeting] = React.useState(null);

  React.useEffect(() => {
    if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
      return;
    }
    setOfflineMeeting(cachedMeeting[0].data);
  }, [cachedMeeting]);

  const [canEdit, setCanEdit] = React.useState(false);
  React.useEffect(() => {
    setCanEdit(
      props.isConnected &&
        (props.ongoingMeeting === undefined ||
          props.ongoingMeeting.length === 0) &&
        (!offlineMeeting || offlineMeeting.status === "completed")
    );
  }, [props.isConnected, props.ongoingMeeting, offlineMeeting]);

  const onChange = (e) =>
    setState({ ...state, [e.target.name]: e.target.value });

  const handleProjectChange = (selectedOptionId) => {
    onChange({
      target: {
        value: selectedOptionId || [],
        name: "project_ids",
      },
    });
  };

  const isDeletable = () => {
    const { ongoingMeeting, vslaBalance, vslaDebt, vsla } = props;

    return (
      ongoingMeeting.length > 0 ||
      vslaBalance.length > 0 ||
      vslaDebt.amount > 0 ||
      vsla.status === "Inactive"
    );
  };

  const deleteVsla = (id) => {
    const lang_code = localStorage.getItem("lang");

    const lang = {
      FRANÇAIS: {
        message: `Supprimer vsla?`,
      },
      ENGLISH: {
        message: `Delete vsla?`,
      },
      SWAHILI: {
        message: `Futa kikundi?`,
      },
      SPANISH: {
        message: `¿Eliminar vsla?`,
      },
      LEBANESE: {
        message: `Delete vsla ?`,
      },
      PORTUGUESE: {
        message: `Excluir VSLA?`,
      },
    };

    const headers = {
      headers: { Authorization: "Bearer " + localStorage.getItem("tokenBC") },
    };
    const headersHOL = {
      headers: {
        Authorization:
          "Bearer " + props.user.token || localStorage.getItem("token"),
      },
    };

    const { vsla, group } = props;
    const currentVslaCopy = vsla;
    currentVslaCopy.status = "Inactive";
    const currentGroupCopy = group;
    currentGroupCopy.status = "Inactive";

    if (window.confirm(lang[lang_code].message)) {
      setState({ ...state, loading: true });
      blockchainAPI
        .patch(`/vsla/${vsla.id}`, currentVslaCopy, headers)
        .then((res) => {
          return backendAPI.patch(
            `/api/v1/groups/group_update/${group.id}`,
            currentGroupCopy,
            headersHOL
          );
        })
        .then((res) => {
          notify(<SuccessMessage />);
        })
        .catch((error) => {
          notify(<ErrorMessage />);
        })
        .finally(() => {
          setState({ ...state, loading: false });
        });
    }
  };

  const toggleEditVsla = () => {
    setState({ ...state, isEditing: !state.isEditing });
  };

  const updateGroup = () => {
    if (!props.transportedLocationData.valid) {
      setState({ ...state, showLocationError: true });
      setTimeout(() => {
        setState({ ...state, showLocationError: false });
      }, 4000);
      return;
    }
    setState({ ...state, loading: true });
    // patch group in HOL
    const headers = {
      headers: { Authorization: "Bearer " + props.user.token },
    };
    const vslaData = props.vslaSummary[0].group;
    let temp = { ...vslaData };
    delete temp.project;
    delete temp.projects;
    // Remove valid attribute from location data
    delete props.transportedLocationData.valid;
    temp.name = state.name;
    temp.locality = props.transportedLocationData.locality
      ? props.transportedLocationData.locality.name
      : "";
    temp.description = state.name;
    temp.cycle_duration = state.savings_cycle;
    temp.establishment_date = state.establishment_date;
    temp.project_ids = state.project_ids;
    temp.address = props.transportedLocationData;
    temp.country = props.transportedLocationData.country
      ? props.transportedLocationData.country.name
      : "";
    const urlHOL = `/api/v1/groups/group_update/${temp.id}`;
    props
      .patchGroup(urlHOL, temp, headers)
      .then(() => {
        updateVslaBC();
      })
      .catch((error) => {
        setState({ ...state, loading: false });
        notify(<ErrorMessage />);
        console.log("Error: ", error);
      });
  };

  const updateVslaBC = async () => {
    // get vsla data
    const vsla_id = props.vsla.id;
    const vsla = await blockchainAPI.get(`/vsla/${vsla_id}`);
    let temp = { ...vsla.data };
    temp.description = state.name;
    const urlBC = `/vsla/${vsla_id}`;

    //patch vsla with new name
    props
      .patchVsla(urlBC, temp)
      .then((res) => {
        notify(<SuccessMessage />);
      })
      .catch((error) => {
        notify(<ErrorMessage />);
      })
      .finally(() => {
        setState({ ...state, loading: false, isEditing: false });
        props.fetchData();
      });
  };
  if (state.loading) {
    return <StellarLoader />;
  }

  if (props.vslaSummary[0].group.length !== 0) {
    return (
      <div>
        <div>
          <h4 style={headerStyle}>{props.vslaSummary[0].group.description}</h4>
        </div>
        <Container>
          <div style={optionStyle}>
            <Translate content="title_copy.more_details" component="h5" />
          </div>

          <ListGroup flush>
            <span onClick={() => props.setPage("group-funds")}>
              <ListGroupItem
                className="justify-content-between textStyle"
                style={
                  localStorage.getItem("lang") === "ARABIC"
                    ? linkReverseStyles
                    : loadingStyle
                }
              >
                <Translate content="menu.funds" />
                <FontAwesomeIcon style={iconStyle} icon="angle-right" />
                <Badge pill></Badge>
              </ListGroupItem>
            </span>
            <span onClick={() => props.setPage("group-memberList")}>
              <ListGroupItem
                className="justify-content-between textStyle"
                style={
                  localStorage.getItem("lang") === "ARABIC"
                    ? linkReverseStyles
                    : loadingStyle
                }
              >
                <Translate content="menu.members" />
                <Badge pill></Badge>
                <FontAwesomeIcon style={iconStyle} icon="angle-right" />
              </ListGroupItem>
            </span>
            <span
              onClick={() => {
                if (!props.isConnected) {
                  return;
                }
                props.setPage("group-settings");
              }}
            >
              <ListGroupItem
                className="justify-content-between textStyle"
                style={
                  localStorage.getItem("lang") === "ARABIC"
                    ? {
                        ...linkReverseStyles,
                        color: props.isConnected ? "initial" : "grey",
                      }
                    : {
                        ...loadingStyle,
                        color: props.isConnected ? "initial" : "grey",
                      }
                }
              >
                <Translate content="menu.settings" />
                <FontAwesomeIcon style={iconStyle} icon="angle-right" />
              </ListGroupItem>
            </span>
          </ListGroup>
          <br />
          <br />
          <div style={optionStyle}>
            <Translate content="title_copy.vsla_actions" component="h5" />
          </div>

          {isDeletable() && (
            <div>
              <Alert color="warning">
                <span>
                  <Translate
                    content="notifications.cant_delete_group_has_funds"
                    style={{
                      fontFamily: "Fira Sans",
                      fontSize: "16px",
                    }}
                  />
                </span>
              </Alert>
            </div>
          )}

          {state.isEditing && (
            <>
              <Input
                type="text"
                name="name"
                style={inputStyle}
                placeholder={getTranslation(
                  "placeholder_copy",
                  "create_group_name"
                )}
                onChange={onChange}
                value={state.name}
              />
              <LocationForm
                preset={state.address}
                validateData={state.showLocationError}
              />
              <ProjectSelector
                onChange={handleProjectChange}
                selectorOptions={
                  props.transportedLocationData.country
                    ? props.projects.filter(
                        ({ country }) =>
                          country === props.transportedLocationData.country.name
                      )
                    : []
                }
                placeholderText={getTranslation(
                  "placeholder_copy",
                  "project_name"
                )}
                defaultOptions={state.project_ids}
              />
              {!["ARABIC", "LEBANESE"].includes(
                localStorage.getItem("lang")
              ) && (
                <Input
                  id="savings_cycle"
                  type="select"
                  name="savings_cycle"
                  style={{
                    flex: "10",
                    padding: "5px",
                    fontFamily: "Fira Sans",
                    fontSize: "20px",
                    marginTop:
                      state.project_ids.length === 2
                        ? "45px"
                        : state.project_ids.length > 2
                        ? "85px"
                        : "0px",
                    marginBottom: "15px",
                  }}
                  onChange={onChange}
                  value={state.savings_cycle}
                >
                  <option value="">
                    {getTranslation("placeholder_copy", "savings_cycle")}
                  </option>
                  <option value="6">
                    {getTranslation("dropdowns", "six_months")}
                  </option>
                  <option value="9">
                    {getTranslation("dropdowns", "nine_months")}
                  </option>
                  <option value="12">
                    {getTranslation("dropdowns", "year")}
                  </option>
                </Input>
              )}
              {!["ARABIC", "LEBANESE"].includes(
                localStorage.getItem("lang")
              ) && (
                <Input
                  type="date"
                  name="establishment_date"
                  placeholder={getTranslation(
                    "dropdowns",
                    "establishment_date"
                  )}
                  value={state.establishment_date}
                  style={inputStyle}
                  onChange={onChange}
                  numberofmonths={1}
                  max={new Date().toISOString().slice(0, 10)}
                />
              )}
              <br />
              <Button
                className="btn btn-primary"
                onClick={updateGroup}
                color="primary"
                size="sm"
                block
                disabled={state.editButtonDisable}
                style={{ marginBottom: "10px" }}
              >
                <Translate content="save" />
              </Button>
            </>
          )}
          {!state.isEditing && (
            <Button
              className="btn btn-primary"
              onClick={toggleEditVsla}
              color="primary"
              size="sm"
              block
              disabled={!canEdit}
              style={{ marginBottom: "10px" }}
            >
              <Translate content="edit" />
            </Button>
          )}
          {props.group.status === "Active" && (
            <Button
              className="btn btn-danger"
              onClick={deleteVsla}
              size="sm"
              block
              disabled={isDeletable()}
            >
              <Translate content="delete" />
            </Button>
          )}
        </Container>
      </div>
    );
  }
};

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
  color: "#000",
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    isConnected: state.isConnected,
    currentPage: state.currentPage,
    error: state.error,
    group: state.group,
    vsla: state.vsla,
    ongoingMeeting: state.currentOngoingMeeting,
    vslaBalance: selectBalance(state),
    vslaDebt: selectVslaDebtBalance(state),
    vslaSummary: selectCreatedVslaInfo(state),
    projects: state.projects,
    members: state.loadedvslaMembers.filter(
      (member) =>
        member.status === "Active" && member.blockchain_wallet_id !== null
    ),
    transportedLocationData: state.locationTransport,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPage: (page) => dispatch(setCurrentPage(page)),
    getMemberBalance: (id) => dispatch(getMemberBalanceByMemberId(id)),
    patchVsla: (url, data) => dispatch(patchVslaItem(url, data)),
    patchGroup: (url, data, headers) =>
      dispatch(patchGroupItem(url, data, headers)),
    fetchData: () => dispatch(getVslas()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupSummary);
