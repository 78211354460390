export default {
  error_msg: {
    transactions_error:
      "TRANSACTION_NAME kiasi cha AMOUNT CURRENCY kwa MEMBER_NAME imeshindikana. Je, unataka kufanya nini na huo muamala?",
  },
  countries: {
    Niger: "Niger",
    Tanzania: "Tanzania",
    Rwanda: "Rwanda",
    Zambia: "Zambia",
    Honduras: "Honduras",
    Nigeria: "Nigeria",
    Lebanon: "Lebanon",
    Mozambique: "Moçambique",
    Moçambique: "Moçambique",
    Ghana: "Ghana",
    Syria: "Syria",
    Palestine: "Palestine",
  },
  title_copy: {
    status: "status",
    version_msg: "Unatumia toleo la majaribio la vsla.online",
    cycles_title: "Saving cycles",
    header_login: "INGIA",
    header_signup: "JIUNGE",
    create_group: "ANZISHA KIKUNDI",
    group_finance: "Mfuko wa Jamii:",
    group_header: "KIKUNDI",
    loading: "Pakia...",
    updating: "Subiri....",
    check_status: "Hali ya Kikundi",
    no_vsla: "Hamna Kikundi",
    create_meeting: "ANZA MKUTANO",
    meeting_members: "WANACHAMA",
    meeting: "MKUTANO",
    prev_meeting: "MIKUTANO ILIYOPITA",
    offline_meeting: "Offline meeting",
    add_member: "TAARIFA ZA MWANACHAMA",
    buy_shares: "NUNUA HISA",
    sell_shares: "USA HISA",
    fine: "LIPA FAINI",
    welfare: "MCHANGO WA JAMII",
    welfare_request: "MCHANGO WA JAMII",
    membership_fee: "ADA YA UANACHAMA",
    loan_writeoff: " FUTA MKOPO",
    loan: "MKOPO",
    funds: "MIFUKO",
    more_details: "Taarifa zaidi",
    vsla_actions: "Rekebisha Kikundi",
    mem_actions: "Rekebisha Mwanachama",
    memberlist_overview: "ORODHA",
    mem_menu: "ONGEZA MWANACHAMA",
    loan_repayment: "LIPA MKOPO",
    outstanding_loan: "Deni lililobaki:",
    attendees: "Waliohudhuria Mkutano",
    share_value: "Thamani ya Hisa",
    settings: "MIPANGILIO",
    current_savings: "Akiba",
    cycle_duration: "Muda wa mzunguko",
    addFundAccount: "ONGEZA MIFUKO",
    supported_accounts: "Mifuko iliyopo",
    lastTransaction: "Redoki ya iliyopita",
    otherIncome: "Mapato ya kikundi",
    otherExpenses: "Gharama za Kikundi",
    cashOut: "Toa Uwekezaji",
    warning: "Warning",
    end_of_cycle_cash_out: "End-cycle cash out",
    reminder: "Reminder",
  },

  menu: {
    vlsa: "KIKUNDI",
    group: "Kikundi",
    meeting: "Mkutano",
    create_vsla: "Anzisha Kikundi",
    vsla_overview: "Taarifa za Kikundi",
    new_meeting: "Mkutano mpya",
    prev_meeting: "Mikutano iliyopita",
    offline_meeting: "Offline meeting",
    vsla_saving: "Mfuko wa Akiba:",
    vlsa_loan: "Jumla ya Mikopo:",
    vlsa_balance: "Salio halisi:",
    funds: "Mfuko",
    members: "Wanachama",
    members_overview: "Taarifa za Mwanachama",
    add_member: "Ongeza Mwanachama",
    end_meeting: "Funga Mkutano",
    current_meeting: "Mkutano unaoendelea",
    settings: "Mipangilio",
    options: "Chaguzi zilizopo",
    language: "Lugha",
    close: "Funga",
    savingsFund: "Mfuko wa akiba",
    welfareFund: "Mfuko wa ustawi",
    loanInterestFund: "Mfuko wa riba",
    fineFund: "Mfuko wa faini",
    membershipFund: "Malipo ya kujiandikisha",
    end_of_cycle_cash_out: "End of cycle cash out",
  },

  menu_mem: {
    overview: "Taarifa za Mwanachama",
    welfare: "Mchango wa Jamii",
    buy_shares: "Nunua Hisa",
    create_loan: "Toa Mkopo",
    repay_loan: "Lipa Mkopo",
    writeoff_loan: "Futa Mkopo",
    welfare_request: "Omba mkopo wa Jamii",
    pay_fine: "Lipa faini",
    membership_fee: "Ada ya uanachama",
    sell_shares: "Uza Hisa",
    transaction_amount: "Kiasi cha mchango",
    other_income: "Mapato mengine",
    other_expenses: "Gharama zingine",
    cashout: "Toa uwekezaji",
    group_income: "Group Income",
    group_expense: "Group Expenses",
    fine_payment: "Fine Payment",
    welfare_contribution: "Welfare Contribution",
    loan_repayment: "LOAN REPAYMENT",
    loan_interest_repayment: "Loan Interest Repayment",
    fine_payment_reversal: "Fine Payment Reversed",
    buy_shares_reversal: "Buy Shares Reversed",
    welfare_contribution_reversal: "Welfare Contribution Reversed",
    sell_shares_reversal: "Sell Shares Reversed",
    loan_interest_repayment_reversal: "Loan Interest Repayment Reversed",
    welfare_request_reversal: "Welfare Request Reversed",
    membership_fee_reversal: "Membership Fee Reversed",
    savings_cash_out: "Savings cashout",
    midCycle_Cashout: "Mid-cycle cash out",
    group_income_fund: "Mapato ya kikundi - Akiba",
    group_income_fine: "Mapato ya kikundi - Faini",
    group_income_welfare: "Mapato ya kikundi - Ustawi",
    group_expense_fund: "Gharama ya kikundi - Akiba",
    group_expense_fine: "Gharama ya kikundi - Faini",
    group_expense_welfare: "Gharama za kikundi - Ustawi",
  },

  mem_overview: {
    welfare: "Mchango wa Jamii:",
    welfare_request: "Agiza mchango wa Jamii",
    shares_in_francs: "Hisa kwa Shilingi:",
    loan: "Kiasi cha Mkopo:",
    shares_in_numbers: "Namba ya Hisa:",
    welfare_total: "Mchango wa Jamii:",
    shares_total: "Hisa za mwanachama:",
  },

  header: {
    home: "Nyumbani",
    vsla: "Kikundi",
    logout: "Funga programu",
    whats_new: "Nini kipya?",
    tutorials: "Mafunzo",
    legal: "Taarifa za kisheria",
  },

  placeholder_copy: {
    search_no_result_message: "Not found",
    search_placeholder: "Search name",
    loader_msg1: "Unda wasifu wa kikundi",
    loader_msg2: "Tengeneza maingizo ya akaunti",
    loader_msg3: "Kutenga akaunti kwa kikundi",
    loader_msg4: "Weka katibu kwenye kikundi",
    add_account_loader_msg: "Kuunda akaunti, hatua ya mwisho",
    group_loader_msg: "Inaunda kikundi, kwa muda mfupi tu",
    secretary_pay_interest: "Je, Katibu analipa riba kwa mikopo",
    nationality: "Nationality",
    gender: "Gender",
    female: "Female",
    male: "Male",
    sms_code: "Thibitisha nambari",
    submit: "Kamilisha",
    income_amount: "kiasi cha hela...",
    login_email: "Namba ya simu...",
    login_password: "Nywila...",
    login_submit: "Ingia",
    login_submitting: "Tunashughulika...",
    signUp_submit: "Jiunge",
    signUp_submitting: "Tunakuunganisha..",
    create_group_name: "Jina la Kikundi...",
    create_group_location: "Eneo kikundi kilipo...",
    create_group_submit: "Anzisha Kikundi",
    create_fund_submit: "Anzisha mfuko",
    meeting_date: "Chagua tarehe",
    meeting_submit: "Anza mkutano",
    meeting_submitting: "Creating Meeting",
    member_submit: "Andikisha Mwanachama",
    member_name: "Jina Kamili...",
    member_phone: "Namba ya Simu....",
    number_of_shares: "Namba ya Hisa...",
    shares_submit: "Nunua Hisa",
    share: "Hisa",
    loan_amount: "Kiasi cha Mkopo...",
    loan_submit: "Toa Mkopo",
    welfare_amount: "Kiasi cha Mchango wa Jamii...",
    welfare_submit: "Changia Mchango wa Jamii",
    payment_amount: "Kiasi cha Mchango...",
    repay_submit: "Lipa Mkopo",
    loan_writeoff: "Futa Mkopo",
    share_value_amount: "Kiasi cha Thamani ya Hisa..",
    share_value_submit: "Sahihisha Thamani ya Hisa",
    create_group_share_value: "Thamani ya Hisa katika Mzunguko",
    role_in_group: "Chagua jukumu katika kikundi",
    login_pin: "PIN",
    login_pin_confirm: "Rudia PIN",
    create_group_federation: "Shirikisho...",
    savings_cycle: "Urefu wa mzunguko wa Kikundi..",
    country: "Chagua Nchi",
    date_of_birth: "Chagua Tarehe ya Kuzaliwa",
    add_secretary_header: "Mweka Kumbukumbu",
    add_secretary_msg:
      "Aliyeandikisha kikundi ndio anahesabika kama mweka kumbukumbu. Endelea sasa kumuweka kwenye orodha",
    add_secretary_status: "Unaunganishwa...",
    add_secretary: "Muorodheshe",
    total_amount: "Jumla ya Kiasi",
    addFundAccount: "Add fund Account",
    fine_amount: "kiasi cha faini",
    fine_submit: "Lipa faini",
    membership_amount: "Kiasi...",
    membership_submit: "Lipa Usajili",
    sell_shares: "Uza Hisa",
    membership_reverse: "Rejesha",
    forgot_pin: "Umesahau nambari ya siri?",
    account_associated: "Ingiza namba ya simu uliyotumia kufungua akaunti yako",
    token_sent: "Ingiza tarakimu 4 ulizopokea kwenye ujumbe.",
    resend_token: "Rudisha msimbo",
    submit_smscode: "Ingiza nambari ya siri mpya yenye tarakimu 4",
    income_submit: "Toa uwekezo",
    select_nationality: "Select Nationalities",
    next: "Next",
    cashingOut: "Cashing out...",
    finish: "Finish",
    currency: "Select currency",
    select: "Select",
    savings_amount: "Savings amount",
    omt_recipt_number: "OMT Receipt number",
    enter_recipt_number: "Enter receipt number",
    savings_withdrawal: "Savings withdrawal",
    project_name: "Jina la mradi",
    retry: "Jaribu tena",
    remove: "Ondoa",
    secretary_creation_msg: "Katibu anawekwa kwenye kikundi...",
    secretary_creation_failed_msg:
      "Uundaji wa katibu umeshindwa. Tafadhali jaribu tena",
  },

  delete: "Futa Kikundi",
  delete_member: "Futa Mwanachama",
  edit: "Sahihisha Kikundi",
  save: "Hifadhi",
  edit_member: "Rebekisha Mwanachama",

  alert: {
    upload_error:
      "offline transaction upload encountered an error. Go to offline meeting record to see the error",
    upload_success:
      "Offline transactions have been uploaded and resolved. Check previous meetings records to see it",
    no_members: "Hamna mwanachama kwenye kikundi",
    no_savings_cash_out_personal:
      "Lipa mkopo kwanza kuwezesha kutoa mchango wako",
    no_savings_cash_out_group:
      "Kikundi kipo na mkopo, lipa ili kutoa mchango wako",
    no_membership_cash_out_personal:
      "Lipa mkopo kwanza kuwezesha kutoa mchango wako",
    no_membership_cash_out_group:
      "Kikundi kipo na mkopo, lipa ili kutoa mchango wako",
    no_fine_cash_out_personal: "Lipa mkopo kwanza kuwezesha kutoa mchango wako",
    no_fine_cash_out_group:
      "Kikundi kipo na mkopo, lipa ili kutoa mchango wako",
    no_welfare_cash_out_personal:
      "Lipa mkopo kwanza kuwezesha kutoa mchango wako",
    no_welfare_cash_out_group:
      "Kikundi kipo na mkopo, lipa ili kutoa mchango wako",
    cash_out_success: "Sasa unaweza kuto mchango wa mwanachama",
    success: "Hongera",
    cannot_end_meeting: "Can't end the meeting while a transaction in progress",
    failed: "Failed",
    repay_loans_message: "Repay the loans these members have",
    cannot_cash_out: "Can't cash out while a transaction in progress",
    all_members_cashed_out: "All members have been cashed out",
    no_internet: "No Internet",
    uploading_transactions: "Uploading transactions...",
    member_cashed_out: "Member has been cashed out",
    member_pending_loan: "Member has a loan they need to repay",
    cannot_delete_member_cashout_msg:
      "Hauwezi kumfuta mwanachama wakati malipo ya mwisho yanaendelea",
    cannot_delete_member_secretary_msg: "Hauwezi kumfuta Katibu wa Kikundi",
    cannot_delete_member_has_balance_msg:
      "Hauwezi kumfuta. Mwanachama anahitaji kulipwa malipo yake ya mwisho kwanza",
  },

  dropdowns: {
    secretary: "Mweka Kumbukumbu",
    chairperson: "Mwenyekiti",
    box_keeper: "Mweka Hazina",
    organiser: "Organiser",
    keyHolder: "Key Holder",
    money_counter: "Mhesabu Pesa",
    member: "Mwanachama",
    yes: "Ndiyo",
    no: "Hapana",
    nine_months: "miezi 9",
    six_months: "miezi 6",
    year: "mwaka",
    establishment_date: "Tarehe ya kuanzishwa",
    business_development: "Business development",
    personal_emergency: "Personal emergency",
    other: "Other",
  },

  cards: {
    total_member: "Jumla ya Wanachama",
    cycle_duration: "Muda wa mzunguko",
    country: "Nchi",
    locality: "Mahala",
    federation: "Shirikisho",
    share_value: "Thamani ya Hisa",
    months: "miezi",
    created_at: "Kimetengenezwa tarehe",
    established_on: "Uanzishwaji juu ya",
  },

  notifications: {
    group_exist_error:
      "Jina la kikundi tayari limechukuliwa. Tafadhali chagua jina tofauti",
    loan_error: "Error: Member has an active loan",
    edit_success: "Imesasishwa kwa mafanikio",
    edit_error: "Kosa kwenye kusahihisha",
    signup_success: "Umeunganishwa kwa mafanikio",
    success: "Imekamilika kwa mafanikio",
    error: "Haikumaliza kwa mafanikio",
    group_create_success: "Kikundi kimeundwa kwa mafanikio",
    group_create_error: "Imeshindikana kuunda kikundi",
    activation_success: "Kikundi kimeamilishwa kwa mafanikio",
    activation_error: "Imeshindikana kuamilisha kikundi",
    meeting_end_success: "Mkutano umemalizika",
    meeting_end_error: "Kosa limetokea kwenye kufunga mkutano",
    meeting_create_success: "Mkutano umeanza",
    meeting_create_error: "Mkutano umeshindwa kuanza",
    member_create_success: "Mwanachama ameundwa kwa mafanikio",
    member_create_error: "Imeshindakana kumuunda mwanachama",
    no_previous_meeting: "Hamna kumbukumbu za mikutano iliyopita",
    no_transactions: "Hakuna miamala kwa mkutano huu",
    meeting_no_member_warning:
      "Hauwezi kuanza mkutano kabla ya kuingiza wanachama kwenye kikundi",
    loan_amount_error: "Kiasi kinazidi kiasi kinachoruhusiwa",
    share_amount_error: "Kiasi cha Hisa kinazidi kiwango kinachoruhusiwa",
    share_value_loading: "Tunatafuta thamani ya hisa iliyopo",
    cant_update_shares: "Mkutano unaendelea, hauwezi kubadili thamani ya hisa",
    member_phone_error: "Namba tayari inatumika",
    savings_fund_mandatory:
      "Ondoa mifuko isiyohitajika. Mfuko wa akiba lazima kuwepo",
    fund_create_success: "Mfuko Umeundwa kwa Mafanikio",
    gdpr_warning:
      "Kwa kuendelea kuunda akaunti, unathibitisha kuwa una umri wa miaka 18 au zaidi",
    can_not_request_welfare: "Mchango wa jamii  hauwepo kwasasa",
    no_reversible_transactions: "Ratiba ya malipo haipo kwa sasa",
    activate_reversible_transactions:
      "Bonyeza kisanduku ili kuwezesha kurekebisha",
    welfare_amount_warning: "Kiasi kinazidi kiasi kinachoruhusiwa",
    pin_success: "Nambari ya siri imebadilishwa",
    pin_error: "Ingiza namba sahihi! Jaribu tena",
    cancel_txn: "Transaction cancelled",
    processing: "Processing Transaction",
    cant_delete_member_has_funds:
      "Huwezi futa akaunti ya mwanachama ikiwa na fedha ama mkutano ukiwa unaendelea",
    cant_delete_group_has_funds:
      "Huwezi futa kikundi kama mkutano unaendelea ama kikundi kiko na fedha mfukoni",
    end_of_cycle_cash_out_warning:
      "Once you've started end of cycle cashout, you won't be able to add any new transaction records until cash out is completed.",
    cash_out_success_msg: "Cash out completed",
    share_value_information:
      "You are starting a new saving cycle. Do you want to change the share value?",
    share_value_updated_msg: "Share value updated!",
    reversal: "Tunachakata ombi",
    mid_cycle_cash_out_warning:
      "Ukishaanza kutoa pesa katikati ya mzunguko, hutaweza kuongeza rekodi zozote mpya za miamala hadi utoaji pesa ukamilike.",
    proceed_to_cashout_msg: "Andika “gawanyapesa” ili kuendelea",
    cashout: "gawanyapesa",
    incorrect_username_password_msg: "Jina la mtumiaji au PIN si sahihi",
    contactHiveForSupportMsg:
      "Hitilafu katika kutuma nambari ya kuthibitisha. Wasiliana na hiveonline kwa usaidizi",
  },

  badge: {
    meeting_on: "Funga Mkutano",
  },

  span: {
    current_cycle: "Current Cycle",
    previous_cycles: "Previous cycles",
    transaction_status: "Transactions status",
    login_span: "Nahitaji kujiandikisha ?",
    signup: "Jiunge",
    signup_span: "Tayari nimejiandikisha ?",
    login: "Ingia",
    welfare: "Jamii",
    savings: "Akiba",
    fine: "Faini",
    endOfCycle: "Mwisho wa kipindi",
    midCycle: "Kati ya kipindi",
    membership: "Usajili",
    sharedBased: "Shares Based",
    equal: "Equal",
    refund: "Refund",
    selectCashOutType: "Select Group Cash Out Type",
    savingsFund: "Savings Fund",
    membershipFund: "Membership Fund",
    welfareFund: "Welfare Fund",
    fineFund: "Fine Fund",
    equally: "Equally",
    fineCashOutType: "Fine Cash Out Type",
    welfareCashOutType: "Welfare Cash Out Type",
    CashOutTypeSelect: "Select Cash Out Type",
    selectFund: "Select Fund",
    loanServiceCharge: "Loan Service Charge",
    processing: "processing",
    back: "Back",
    continue: "Continue",
    no_records_found: "No transactions records found",
    loan_purpose: "Loan purpose",
    cancel: "Cancel",
    save: "Save",
    saving: "Saving...",
    bc_wallet: "Mkoba wa BC",
    link: "Kiungo",
  },
  banner: {
    install: "Install",
  },
  locale: {
    date: "en-EN",
  },
  memberActions: {
    confirm_message: `Futa Mwanachama?`,
  },
  cashOut: {
    settings_summary_title:
      "Hizi ndizo aina za kutoa pesa zilizowekwa kwa kila mfuko:",
    savings: "Kulingana na hisa",
    membership: "Rejesha",
    Welfare: "Kwa usawa",
    fine: "Fine Fund",
    loan_interest: "ya mkopo: Kulingana na hisa",
    call_to_action: "Unaweza kubadilisha mipangilio hii hapa",
    write_here: "Andika hapa",
  },
  settings: {
    disclaimer:
      "Mipangilio hii inaweza kubadilishwa ikiwa hakuna miamala iliyoongezwa kwenye mkutano unaoendelea",
  },
  status: {
    SUCCESSFUL: "SUCCESSFUL",
    PENDING: "PENDING",
    FAILED: "FAILED",
  },
  locations: {
    country: "Inchi",
    county: "Kaunti",
    province: "Mkoa",
    region: "Mkoa",
    district: "Wilaya",
    gh_district: "Metropolitan/Manispaa/Wilaya",
    municipality: "Manispaa",
    sub_county: "Kaunti-ndogo",
    administrative_post: "Chapisho la utawala",
    locality: "Mahali",
    select_country: "chagua nchi",
    select_county: "kata chagua",
    select_sub_county: "chagua kata ndogo",
    select_province: "chagua mkoa",
    select_region: "chagua mkoa",
    select_district: "chagua wilaya",
    select_municipality: "chagua manispaa",
    select_administrative_post: "chagua Tarafa",
    select_locality: "chagua Kata",
    neigbourhood_name: "Jina la mtaa",
  },
};

// Also translate: (dont delete this part add transalations below)

// Are you sure you want to end the meeting?
// Una uhakika unataka kufunga mkutano? --
// This field is required
// Ni lazima kujaza nafasi hii
// Incorrect number format
// Umekosea jinsi ya kuandika namba
// Maximum allowed characters are 9
// Mwisho ni tarakimu au herufi 9
// Enter digits only
// Ingiza tarakimu pekee
// Shares number should be between 1 and 99
// Idadi ya hisa ni kati ya 1 hadi 99
// Amount between 0 and 999999999
// Kiasi kati ya 0 na 999999999
// Enter text only
// Ingiza herufi pekee
// PIN don't match
// PIN haifanani
// PIN should be 4 digits
// PIN mwisho tarakimu 4
// Phone number should be 10 digits
// Namba ya simu mwisho ni tarakimu 10
// Maximum allowed characters are 100
// Mwisho ni tarakimu au herufi 100
// Number in use
// Namba tayari inatumika
// Confirm loan of 500 Francs for member
// Thibitisha mkopo wa shilingi 500 kwa mwanachama
// Confirm buy 500 shares for member
// Thibitisha ununuzi wa hisa 500 kwa mwanachama
// Delete member
// Futa Mwanachama
// Confirm loan repayment of 500 Francs for member
// Thibitisha kwamba mwanachama amerejesha shilingi 500
// Confirm contribution of 500 Francs for member
// Thibitisha mwanachama ametoa mchango wa shilingi 500
// Share
// Hisa
