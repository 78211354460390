import React, { Component } from "react";
import counterpart from "counterpart";
import ZARMA from "../../view/shared/lang/ZARMA";
import ENGLISH from "../../view/shared/lang/ENGLISH";
import SWAHILI from "../../view/shared/lang/SWAHILI";
import HAOUSSA from "../../view/shared/lang/HAOUSSA";
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS";
import SPANISH from "../../view/shared/lang/SPANISH";
import LEBANESE from "../../view/shared/lang/LEBANESE";
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE";
import ARABIC from "../../view/shared/lang/ARABIC";
import { connect } from "react-redux";
counterpart.registerTranslations("ARABIC", ARABIC);

counterpart.registerTranslations("PORTUGUESE", PORTUGUESE);

counterpart.registerTranslations("LEBANESE", LEBANESE);
counterpart.registerTranslations("ZARMA", ZARMA);
counterpart.registerTranslations("SWAHILI", SWAHILI);
counterpart.registerTranslations("ENGLISH", ENGLISH);
counterpart.registerTranslations("HAOUSSA", HAOUSSA);
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS);
counterpart.registerTranslations("SPANISH", SPANISH);

export class LangSelector extends Component {
  state = {
    lang: "",
  };

  onChange = (e) => {
    localStorage.setItem("lang", e.target.value);
    this.setState({ lang: e.target.value });
    this.props.toggleBack();
    if (this.props.isConnected) {
      window.location.reload();
    }
  };

  render() {
    counterpart.setLocale(localStorage.getItem("lang"));

    return (
      <div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="exampleRadios"
            id="exampleRadios1"
            onChange={this.onChange}
            value="ENGLISH"
            checked={"ENGLISH" === localStorage.getItem("lang")}
          />
          <label
            className="form-check-label"
            htmlFor="exampleRadios1"
            style={{ fontFamily: "Fira Sans", fontSize: "16px" }}
          >
            English
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="exampleRadios"
            id="exampleRadios2"
            onChange={this.onChange}
            value="FRANÇAIS"
            checked={"FRANÇAIS" === localStorage.getItem("lang")}
          />
          <label
            className="form-check-label"
            htmlFor="exampleRadios2"
            style={{ fontFamily: "Fira Sans", fontSize: "16px" }}
          >
            Francais
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="exampleRadios"
            id="exampleRadios3"
            onChange={this.onChange}
            value="SWAHILI"
            checked={"SWAHILI" === localStorage.getItem("lang")}
          />
          <label
            className="form-check-label"
            htmlFor="exampleRadios3"
            style={{ fontFamily: "Fira Sans", fontSize: "16px" }}
          >
            Kiswahili
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="exampleRadios"
            id="exampleRadios3"
            onChange={this.onChange}
            value="LEBANESE"
            checked={"LEBANESE" === localStorage.getItem("lang")}
          />
          <label
            className="form-check-label"
            htmlFor="exampleRadios3"
            style={{ fontFamily: "Fira Sans", fontSize: "16px" }}
          >
            LEBANESE ENGLISH
          </label>
        </div>
        <div className="form-check">
          <input
            disabled={!this.props.isConnected}
            className="form-check-input"
            type="radio"
            name="exampleRadios"
            id="exampleRadios3"
            onChange={this.onChange}
            value="ARABIC"
            checked={"ARABIC" === localStorage.getItem("lang")}
          />
          <label
            className="form-check-label"
            htmlFor="exampleRadios3"
            style={{ fontFamily: "Fira Sans", fontSize: "16px" }}
          >
            العربية
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="exampleRadios"
            id="exampleRadios3"
            onChange={this.onChange}
            value="PORTUGUESE"
            checked={"PORTUGUESE" === localStorage.getItem("lang")}
          />
          <label
            className="form-check-label"
            htmlFor="exampleRadios3"
            style={{ fontFamily: "Fira Sans", fontSize: "16px" }}
          >
            PORTUGUESE
          </label>
        </div>
        {/* <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="exampleRadios"
            id="exampleRadios4"
            onChange={this.onChange}
            value="ZARMA"
            checked={"ZARMA" === localStorage.getItem("lang")}
          />
          <label
            className="form-check-label"
            htmlFor="exampleRadios4"
            style={{ fontFamily: "Fira Sans", fontSize: "16px" }}
          >
            Zarma
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="exampleRadios"
            id="exampleRadios5"
            onChange={this.onChange}
            value="HAOUSSA"
            checked={"HAOUSSA" === localStorage.getItem("lang")}
          />
          <label
            className="form-check-label"
            htmlFor="exampleRadios5"
            style={{ fontFamily: "Fira Sans", fontSize: "16px" }}
          >
            Haoussa
          </label>
        </div> */}
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            name="exampleRadios"
            id="exampleRadios6"
            onChange={this.onChange}
            value="SPANISH"
            checked={"SPANISH" === localStorage.getItem("lang")}
          />
          <label
            className="form-check-label"
            htmlFor="exampleRadios6"
            style={{ fontFamily: "Fira Sans", fontSize: "16px" }}
          >
            Español
          </label>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ isConnected: state.isConnected });

export default connect(mapStateToProps, null)(LangSelector);
