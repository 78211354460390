import { backendAPI } from "../../api";
import { fetchMeetingTransactions } from "../getTransactions";
import { meetingsLoaded } from "./getAllGroupMeetings";

export const meetingLoadStarted = (bool) => ({
  type: "MEETING_LOAD_START",
  isloading: bool,
});

export const meetingLoaded = (meeting) => ({
  type: "MEETING_ITEM",
  meeting,
});

export const meetingLoadError = (bool) => ({
  type: "MEETING_LOAD_ERROR",
  error: bool,
});

export function fetchMeeting(headers) {
  return async function (dispatch, getState) {
    dispatch(meetingLoadStarted(true));
    try {
      const group_id = getState().loadedvslas[0].id;
      const url = `/api/v1/groups/${group_id}/meetings`;
      const res = await backendAPI.get(url, headers);
      const currentMeeting = res.data.filter(({status}) => status === "ongoing")
      dispatch(
        meetingLoaded(currentMeeting)
      );
      if (currentMeeting.length) {
        const id = currentMeeting[0].id
        dispatch(fetchMeetingTransactions(id))
      }
      dispatch(meetingLoadStarted(false));
      dispatch(meetingsLoaded(res.data));
      // return Promise.resolve();
    } catch (error) {
      dispatch(meetingLoadError(error));
      return Promise.reject();
    }
  };
}
