import React from "react";
import { Form, FormGroup, Label } from "reactstrap";
import Select from "react-select";

const Selector = ({
  onChange,
  selectorOptions,
  placeholderText,
  defaultOptions,
  error,
  isLoading = false,
  isDisabled = false,
}) => {
  /**
   * Selector Component
   * This component renders a customizable single-select dropdown. It allows users to select one option from a list.
   *
   * @param {function} onChange - Callback function that is triggered when the selection changes.
   *      It receives the new selected value as an argument.
   * @param {Array} selectorOptions - Array of objects representing the selectable options.
   *      Each object must include 'id' and 'name' properties to be compatible with this component.
   * @param {string} placeholderText - Text to display when no option is selected, serving as a prompt for the user.
   * @param {Object} defaultOptions - The default selected option. It should be an object with 'id' and 'name' properties.
   * @param {string} error - Error message to display when there is a validation error.
   * @param {boolean} isLoading - Flag to indicate if the selector is in a loading state.
   * @param {boolean} isDisabled - Flag to indicate if the selector is disabled.
   */
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [options, setOptions] = React.useState([]);

  /* This effect is necessary to transform the selector data options into the format required by the react-select library.
     It runs whenever the 'selector options' prop changes to ensure the options are updated correctly.*/
  React.useEffect(() => {
    if (Array.isArray(selectorOptions)) {
      const result = selectorOptions.reduce((acc, option) => {
        if (option.id !== null && option.name !== null) {
          acc.push({ value: option.id, label: option.name });
        }
        return acc;
      }, []);

      setOptions(result);
    } else {
      setOptions([]);
    }
  }, [selectorOptions]);

  React.useEffect(() => {
    if (defaultOptions && defaultOptions.id) {
      setSelectedOption(defaultOptions.id);
    } else {
      setSelectedOption(null);
    }

    // Cleanup function to reset selectedOption when component unmounts
    return () => {
      setSelectedOption(null);
    };
  }, [defaultOptions, placeholderText]);

  const handleChange = (selectedOption) => {
    const id = selectedOption ? selectedOption.value : null;
    const name = selectedOption ? selectedOption.label : null;
    setSelectedOption(id);
    onChange({ id, name });
  };

  return (
    <Form>
      <FormGroup name={`${placeholderText.split(" ").join("_")}selector`}>
        {selectedOption && (
          <Label className="selected-label">{placeholderText}</Label>
        )}
        <Select
          placeholder={placeholderText}
          options={options}
          className="group-multi-select"
          classNamePrefix="select"
          onChange={handleChange}
          value={
            options.find((option) => option.value === selectedOption) || []
          } // Ensures the selected item is highlighted
          isClearable={true} // Enables clearing the selection
          isSearchable={true} // Enables searching the options
          isLoading={isLoading}
          isDisabled={isDisabled}
        />
        {error && (
          <div
            name={`${placeholderText.split(" ").join("_")}Error`}
            style={{ color: "red" }}
          >
            {error}
          </div>
        )}
      </FormGroup>
    </Form>
  );
};

export default Selector;
