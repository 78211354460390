export function gettingOngoingMeeting(state = false, action) {
  switch (action.type) {
    case "MEETING_LOAD_START":
      return action.isloading;
    default:
      return state;
  }
}

export function currentOngoingMeeting(state = [], action) {
  switch (action.type) {
    case "MEETING_ITEM":
      return action.meeting;
    default:
      return state;
  }
}

export function meetingLoadingError(state = false, action) {
  switch (action.type) {
    case "MEETING_LOAD_ERROR":
      return action.error;
    default:
      return state;
  }
}
