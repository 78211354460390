import React from "react";
import { Container } from "reactstrap";
import { Form, Input, FormGroup } from "reactstrap";
import stellarProcessing from "../../view/assets/stellar-animation.gif";
import counterpart from "counterpart";
import Translate from "react-translate-component";
import ZARMA from "../../view/shared/lang/ZARMA";
import ENGLISH from "../../view/shared/lang/ENGLISH";
import SWAHILI from "../../view/shared/lang/SWAHILI";
import HAOUSSA from "../../view/shared/lang/HAOUSSA";
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS";
import SPANISH from "../../view/shared/lang/SPANISH";
import { toast } from "react-toastify";
import { WarningMessage } from "../../view/shared/notifications/Notifications";
import validateInput from "../../view/shared/validations/validateAmount";
import { connect } from "react-redux";
import LastTransaction from "../reverseTransactions/LastTrasaction";
import { currentTransactionContext } from "../../store/actions/transactionContext";
import { initiateTransaction } from "../../store/reducers/transactions/actions";
import LEBANESE from "../../view/shared/lang/LEBANESE";
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE";
import ARABIC from "../../view/shared/lang/ARABIC";
import { selectCreatedVslaInfo } from "../../store/reducers/getVslas";
import { setCurrentPage } from "../../store/reducers/navigation";
import { arabicToInt } from "../../view/shared/utilities/arabicNumParser";
import { getTranslation } from "../../view/shared/helpers";
import { db } from "../../view/shared/utilities/cache";
import { useLiveQuery } from "dexie-react-hooks";

counterpart.registerTranslations("ARABIC", ARABIC);
counterpart.registerTranslations("PORTUGUESE", PORTUGUESE);
counterpart.registerTranslations("LEBANESE", LEBANESE);
counterpart.registerTranslations("ZARMA", ZARMA);
counterpart.registerTranslations("SWAHILI", SWAHILI);
counterpart.registerTranslations("ENGLISH", ENGLISH);
counterpart.registerTranslations("HAOUSSA", HAOUSSA);
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS);
counterpart.registerTranslations("SPANISH", SPANISH);

const notify = (msg) => toast(msg);
const headerStyle = {
  color: "#E36F1E",
  fontFamily: "quicksand",
  fontWeight: "bolder",
  fontSize: "1.8em",
  textAlign: "center",
  paddingBottom: "0.5em",
};

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
};

const spinnerStyle = {
  height: "90%",
  width: "90%",
};

const WelfareGiven = (props) =>{
  const cachedMeeting = useLiveQuery(async () => {
    try {
      const items = await db.offlineMeeting.toArray();
      return items.filter(({vslaId})=>vslaId===props.vsla.id);
    } catch (error) {
      console.log(`Error: ${error}`);
      return [];
    }
  }, []);

  const [offlineMeeting, setOfflineMeeting] = React.useState(null);

  React.useEffect(()=> {
  if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
    setOfflineMeeting(null);
    return
  }
  setOfflineMeeting(cachedMeeting[0])
  }, []);

  React.useEffect(()=> {
  if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
    setOfflineMeeting(null);
    return
  }
  setOfflineMeeting(cachedMeeting[0])
  }, [cachedMeeting]);
  
  const [state, setState] = React.useState({
    amount: "",
    asset: "SC",
    source: "issuer",
    dest: "member",
    loading: false,
    errors: {},
  });

  React.useEffect(()=> {
    counterpart.setLocale(localStorage.getItem("lang"));
    props.pushTransactionContext("Membership paid");
  }, []);

  const onChange = (e) => setState({...state, [e.target.name]: e.target.value });

  const isValid = () => {
    const { errors, isValid } = validateInput(state);

    if (!isValid) {
      setState({ ...state, errors });
    }
    return isValid;
  }
  const redirectBack = () => {
    props.setPage("group-memberList");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (isValid()) {
      processTransaction(
        arabicToInt(state.amount),
        state.asset,
        state.source,
        state.dest
      );
      setState({...state, amount: "" });
    }
  };

  const processTransaction = (amount, asset, source, dest) => {
    const lang_code = localStorage.getItem("lang");

    const lang = {
      FRANÇAIS: {
        message: `Confirmer la cotisation de ${state.amount} pour le membre`,
      },
      ENGLISH: {
        message: `Confirm contribution of ${state.amount} for member`,
      },
      SWAHILI: {
        message: `Thibitisha mwanachama ametoa mchango wa shilingi ${state.amount}`,
      },
      ZARMA: {
        message: `Zarma for Confirm contribution of ${state.amount} for member`,
      },
      HAOUSSA: {
        message: `Tabbatar da taimakon dala ${state.amount} daga ỳar rukuni`,
      },
      SPANISH: {
        message: `Confirmar contribución de ${state.amount} lempiras para miembro`,
      },
      LEBANESE: {
        message: `Confirm contribution of ${state.amount} for household`,
      },
      PORTUGUESE: {
        message: `Confirme a contribuição de ${state.amount} para o membro`,
      },
      ARABIC: {
        message: `للأسرة ${state.amount} أكد مساهمة`,
      },
    };

    if (window.confirm(lang[lang_code].message)) {
      setState({ loading: true });
      const uuid = props.currentMember.pubkey;
      props.commitTransaction({
        uid: uuid,
        meetingId: props.ongoingMeeting.length
          ? props.ongoingMeeting[0].id
          : offlineMeeting.data.id,
        type: "WELFARE_CONTRIBUTION",
        groupId: props.group[0].blockchain_key,
        memberId: props.currentMember.blockchain_wallet_id,
        amount: parseFloat(amount),
      }, offlineMeeting);
      notify(<WarningMessage msg={"notifications.processing"} />);
      setState({ ...state, loading: false });
      redirectBack();
    } else {
      notify(<WarningMessage />);
    }
  };

  if (!state.loading) {
    return (
      <div>
        <div>
          <h4 style={headerStyle}>{props.currentMember.full_name}</h4>
        </div>
        <div>
          <Translate
            content="title_copy.welfare"
            component="h4"
            style={headerStyle}
          />
        </div>
        <Form onSubmit={onSubmit}>
          <Container>
            <FormGroup>
              <span style={{ color: "red" }}>{state.errors.amount}</span>
              <Input
                type="number"
                name="amount"
                style={loadingStyle}
                placeholder={getTranslation("placeholder_copy","welfare_amount")}
                onChange={onChange}
                value={state.amount}
              />
            </FormGroup>
          </Container>
          <Container>
            <FormGroup>
              <Input
                type="submit"
                value={getTranslation("placeholder_copy","welfare_submit")}
                name="submit"
                className="btn btn-primary"
              />
            </FormGroup>
          </Container>
        </Form>
        <LastTransaction
          redirectBack={redirectBack.bind(this)}
          reversal_details={{
            sourceFundType: "welfare",
            destFundType: "issuer",
            reversalTransactionType: "Welfare reversed",
          }}
          transactions={props.transactions.filter(
            ({ memberId, type }) =>
              memberId === props.currentMember.blockchain_wallet_id
          )}
        />
      </div>
    );
  }

  return (
    <div>
      <center>
        <img src={stellarProcessing} style={spinnerStyle} alt="Stellar" />
      </center>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isConnected: state.isConnected,
    ongoingMeeting: state.currentOngoingMeeting,
    currentMember: state.loadedMember,
    group: state.loadedvslas,
    transactions: state.loadedMeetingTransaction.filter(
      ({ type, memberId }) =>
        memberId === state.loadedMember.blockchain_wallet_id &&
        type === "WELFARE_CONTRIBUTION"
    ),
    memberDetails: state.loadedMemberHOL,
    vsla: state.vsla,
    vslaSummary: selectCreatedVslaInfo(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPage: (page) => dispatch(setCurrentPage(page)),
    commitTransaction: (data, meeting) => dispatch(initiateTransaction(data, meeting)),
    pushTransactionContext: (type) => dispatch(currentTransactionContext(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WelfareGiven);
