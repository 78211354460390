import React from "react";
import {
  CardTitle,
  FormGroup,
  Container,
  CardBody,
  Form,
  Input,
  Label,
  Alert,
} from "reactstrap";
import { connect } from "react-redux";
import counterpart from "counterpart";
import Translate from "react-translate-component";
import ENGLISH from "../../view/shared/lang/ENGLISH";
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS";
import SWAHILI from "../../view/shared/lang/SWAHILI";
import HAOUSSA from "../../view/shared/lang/HAOUSSA";
import ZARMA from "../../view/shared/lang/ZARMA";
import SPANISH from "../../view/shared/lang/SPANISH";
import LEBANESE from "../../view/shared/lang/LEBANESE";
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE";
import ARABIC from "../../view/shared/lang/ARABIC";
import { setCurrentPage } from "../../store/reducers/navigation";
import { getTranslation } from "../../view/shared/helpers";
import {GroupCreateLoader} from "../layout/StellarLoader";
import { fetchVsla } from "../../store/actions/getVslas";
import { createFundAccounts } from "../../store/actions/FundAccounts";
import { isCreatingFundAccount } from "../../store/actions/FundAccounts/createFundAccounts";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../view/shared/utilities/cache";
counterpart.registerTranslations("ARABIC", ARABIC);

counterpart.registerTranslations("PORTUGUESE", PORTUGUESE);
counterpart.registerTranslations("LEBANESE", LEBANESE);
counterpart.registerTranslations("ENGLISH", ENGLISH);
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS);
counterpart.registerTranslations("SWAHILI", SWAHILI);
counterpart.registerTranslations("ZARMA", ZARMA);
counterpart.registerTranslations("HAOUSSA", HAOUSSA);
counterpart.registerTranslations("SPANISH", SPANISH);

const headerStyle = {
  color: "#E36F1E",
  fontFamily: "quicksand",
  fontWeight: "bolder",
  fontSize: "1.8em",
  textAlign: "center",
  paddingBottom: "0.5em",
};

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
};

const AddGroupFund = (props) => {
  const [state, setState] = React.useState({
    welfare: "welfare",
    fine: "fine",
    loanInterest: "loan_interest",
    membership: "membership",
    loading: false,
    types: new Set(["welfare", "loan_interest", "fine", "membership"]),
  });

  React.useEffect(() => {
    counterpart.setLocale(localStorage.getItem("lang"));
  }, []);

  const onChange = (e) => {
    if (getItemCheckedStatus(e.target.value)) removeItem(e.target.value);
    else
      setState({ ...state, types: new Set(state.types.add(e.target.value)) });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    props.setPayloadData({accounts: Array.from(state.types)});
  };

  const cachedAccounts = useLiveQuery(() => {
    try {
      return db.accounts.filter(({vslaId})=> vslaId === props.vsla.id).toArray();
    } catch (error) {
      console.error(error);
      return null;
    }
  }, []);

  React.useEffect(() => {
    if (!Array.isArray(cachedAccounts)) {return}
    if (!Array.isArray(props.existingVsla) && props.existingVsla.accounts.length > 1) {
      props.setPage("group-main");
      setState({ ...state, loading: false });
      props.setAccountsLoader(false);
    }
  }, [cachedAccounts]);

  const getItemCheckedStatus = (item) => state.types.has(item);

  const removeItem = (item) => {
    const newChecked = state.types;
    newChecked.delete(item);
    setState({ ...state, types: new Set(newChecked) });
  };

  if (props.isCreatingVsla) {
    return <GroupCreateLoader msg={getTranslation("placeholder_copy", "add_account_loader_msg")}/>;
  }

  return (
    <Container>
      <CardTitle>
        <Translate
          content="title_copy.addFundAccount"
          component="h4"
          className="textStyle"
          style={headerStyle}
        />
      </CardTitle>
      <Alert color="warning">
        <span>
          <Translate
            content="notifications.savings_fund_mandatory"
            style={{
              fontFamily: "Fira Sans",
              fontSize: "16px",
            }}
          />
        </span>
      </Alert>
      <CardBody>
        <Form onSubmit={onSubmit}>
          <FormGroup
            check
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Label check>
              <Input
                disabled={true}
                type="checkbox"
                checked={true}
                onChange={onChange}
              />
              <Translate
                content="menu.savingsFund"
                component="h5"
                className="textStyle"
                style={loadingStyle}
              />
            </Label>
          </FormGroup>

          {!["ARABIC", "LEBANESE"].includes(localStorage.getItem("lang")) && (
            <>
              <FormGroup
                check
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Label check>
                  <Input
                    type="checkbox"
                    name="welfare"
                    id="welfare"
                    onChange={onChange}
                    checked={getItemCheckedStatus(state.welfare) ? true : false}
                    value={state.welfare}
                  />
                  <Translate
                    content="menu.welfareFund"
                    component="h5"
                    className="textStyle"
                    style={loadingStyle}
                  />
                </Label>{" "}
              </FormGroup>
              <FormGroup
                check
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Label check>
                  <Input
                    type="checkbox"
                    name="loanInterest"
                    id="loanInterest"
                    checked={
                      getItemCheckedStatus(state.loanInterest) ? true : false
                    }
                    onChange={onChange}
                    value={state.loanInterest}
                  />
                  <Translate
                    content="menu.loanInterestFund"
                    component="h5"
                    className="textStyle"
                    style={loadingStyle}
                  />
                </Label>{" "}
              </FormGroup>
              <FormGroup
                check
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Label check>
                  <Input
                    type="checkbox"
                    name="fine"
                    id="fine"
                    checked={getItemCheckedStatus(state.fine) ? true : false}
                    onChange={onChange}
                    value={state.fine}
                  />
                  <Translate
                    content="menu.fineFund"
                    component="h5"
                    className="textStyle"
                    style={loadingStyle}
                  />
                </Label>{" "}
              </FormGroup>
              <FormGroup
                check
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Label check>
                  <Input
                    type="checkbox"
                    name="membership"
                    id="membership"
                    checked={
                      getItemCheckedStatus(state.membership) ? true : false
                    }
                    onChange={onChange}
                    value={state.membership}
                  />
                  <Translate
                    content="menu.membershipFund"
                    component="h5"
                    className="textStyle"
                    style={loadingStyle}
                  />
                </Label>{" "}
              </FormGroup>
            </>
          )}
          <hr />
          <FormGroup>
            <Input
              type="submit"
              value={getTranslation("placeholder_copy", "create_fund_submit")}
              name="submit"
              className="btn btn-primary"
            />
          </FormGroup>
        </Form>
      </CardBody>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
    vsla: state.vslaCreated,
    existingVsla: state.vsla,
    isCreatingVsla: state.isCreatingVsla,
  };
};
const mapDispatchToProps = (dispatch) => ({
  reloadVsla: () => dispatch(fetchVsla()),
  setPage: (page) => dispatch(setCurrentPage(page)),
  createAccounts: types => dispatch(createFundAccounts(types)),
  setAccountsLoader: (val)=> dispatch(isCreatingFundAccount(val)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddGroupFund);
