import { fetchPreviousMeetingsData } from "./meetings";
import { fetchVslasMembers } from "./members/getVslaMembers";
import { backendAPI, blockchainAPI } from "../api";
import { fetchMeeting } from "./meetings/getMeeting";
import { getProjects } from "./createVsla";
import { getCountries } from "./location";

export const vslaLoadStarted = (bool) => ({
  type: "VSLA_LOAD_START",
  isloading: bool,
});

export const vslaLoaded = (vslas) => ({
  type: "LOADED_VSLA",
  vslas,
});

export const vslaLoadError = (error) => ({
  type: "VSLA_LOAD_ERROR",
  error,
});

export const loadVslaStart = (bool) => ({
  type: "LOAD_VSLA_START",
  isloading: bool,
});

export const loadVslaSuccess = (vsla) => ({
  type: "LOAD_VSLA_SUCCESS",
  vsla,
});

export const loadVslaError = (bool) => ({
  type: "LOAD_VSLA_ERROR",
  error: bool,
});

export function fetchVslas(token, tokenBC) {
  const cachedToken = localStorage.getItem("token");
  if (!cachedToken) {
    return;
  }
  return async function (dispatch, getState) {
    try {
      const headers = {
        headers: {
          Authorization: `Bearer ${token || localStorage.getItem("token")}`,
        },
      };
      const { user, loadedvslas } = getState();
      const url = `/api/v1/groups/${user.id}/groups`;
      /* when creating group accounts we are loading the vsla object with the
       * updated accounts list
       * this can happen multiple times since the users has the option to
       * create up to 4 accounts this logic will show the loader only if we
       * have not loaded the inintial vsla object otherwise reload the updated
       * vsla object "silently"
       */
      if (!loadedvslas.length) {
        dispatch(vslaLoadStarted(true));
      }
      //fetch project information
      await dispatch(getProjects());
      //fetch all countries
      await dispatch(getCountries());
      const res = await backendAPI.get(url, headers);
      dispatch(vslaLoaded(res.data));
      await dispatch(fetchVsla());
      await dispatch(fetchVslasMembers());
      await dispatch(fetchMeeting(headers));
      await dispatch(fetchPreviousMeetingsData());
      dispatch(vslaLoadStarted(false));
      return Promise.resolve();
    } catch (error) {
      console.log("Error: ", error);
      dispatch(vslaLoadStarted(false));
      if (error && error.response.status === 404) return Promise.resolve();
      //prevent setting reducer state as undefined
      dispatch(vslaLoadError(error || ""));
      return Promise.reject(error);
    }
  };
}

export function fetchVsla() {
  return async function (dispatch, getState) {
    dispatch(loadVslaStart(true));
    try {
      const headers = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("tokenBC"),
        },
      };
      const loadedvslas = getState().loadedvslas;
      let vsla_uuid;

      if (Array.isArray(loadedvslas) && loadedvslas.length > 0) {
        vsla_uuid = loadedvslas[0].blockchain_wallet_id;
      } else {
        throw new Error("invalid wallet id");
      }
      const url = `/vsla/${vsla_uuid}/author`;
      const res = await blockchainAPI.get(url, headers);
      dispatch(loadVslaSuccess(res.data));
      dispatch(loadVslaStart(false));
      return Promise.resolve();
    } catch (error) {
      console.log("Error in fetchVsla:", error); // Log the error here
      dispatch(loadVslaError(true));
      return Promise.reject();
    }
  };
}

/*
  Function to loan vsla data from backend and blockchain only
*/
export function getVslas(token, tokenBC) {
  const cachedToken = localStorage.getItem("token");
  if (!cachedToken) {
    return;
  }
  return async function (dispatch, getState) {
    try {
      const headers = {
        headers: {
          Authorization: `Bearer ${token || localStorage.getItem("token")}`,
        },
      };
      const { user, loadedvslas } = getState();
      const url = `/api/v1/groups/${user.id}/groups`;
      /* when creating group accounts we are loading the vsla object with the
       * updated accounts list
       * this can happen multiple times since the users has the option to
       * create up to 4 accounts this logic will show the loader only if we
       * have not loaded the inintial vsla object otherwise reload the updated
       * vsla object "silently"
       */
      if (!loadedvslas.length) {
        dispatch(vslaLoadStarted(true));
      }
      const res = await backendAPI.get(url, headers);
      dispatch(vslaLoaded(res.data));
      await dispatch(fetchVsla());
      dispatch(vslaLoadStarted(false));
      return Promise.resolve();
    } catch (error) {
      console.log("Error: ", error);
      dispatch(vslaLoadStarted(false));
      if (error && error.response.status === 404) return Promise.resolve();
      //prevent setting reducer state as undefined
      dispatch(vslaLoadError(error || ""));
      return Promise.reject(error);
    }
  };
}
