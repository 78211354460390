import React from "react";
import { Form, FormGroup, Label, Container, Row, Col, Input } from "reactstrap";
import stellarProcessing from "../../view/assets/stellar-animation.gif";
import cow from "../../view/assets/cow.png";
import counterpart from "counterpart";
import Translate from "react-translate-component";
import ZARMA from "../../view/shared/lang/ZARMA";
import ENGLISH from "../../view/shared/lang/ENGLISH";
import SWAHILI from "../../view/shared/lang/SWAHILI";
import HAOUSSA from "../../view/shared/lang/HAOUSSA";
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS";
import SPANISH from "../../view/shared/lang/SPANISH";
import { toast } from "react-toastify";
import { WarningMessage } from "../../view/shared/notifications/Notifications";
import { connect } from "react-redux";
import validateInput from "../../view/shared/validations/buyShares";
import LastSharesBought from "../reverseTransactions/LastSharesBought";
import { currentTransactionContext } from "../../store/actions/transactionContext";
import { getCurrency } from "../../view/shared/utilities/currency";
import { initiateTransaction } from "../../store/reducers/transactions/actions";
import LEBANESE from "../../view/shared/lang/LEBANESE";
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE";
import ARABIC from "../../view/shared/lang/ARABIC";
import { selectCreatedVslaInfo } from "../../store/reducers/getVslas";
import { setCurrentPage } from "../../store/reducers/navigation";
import { arabicToInt } from "../../view/shared/utilities/arabicNumParser";
import { getTranslation } from "../../view/shared/helpers";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../view/shared/utilities/cache";
counterpart.registerTranslations("ARABIC", ARABIC);

counterpart.registerTranslations("PORTUGUESE", PORTUGUESE);
counterpart.registerTranslations("LEBANESE", LEBANESE);
counterpart.registerTranslations("ZARMA", ZARMA);
counterpart.registerTranslations("SWAHILI", SWAHILI);
counterpart.registerTranslations("ENGLISH", ENGLISH);
counterpart.registerTranslations("HAOUSSA", HAOUSSA);
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS);
counterpart.registerTranslations("SPANISH", SPANISH);

const notify = (msg) => toast(msg);
const spinnerStyle = {
  height: "90%",
  width: "90%",
};

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
};

const headerStyle = {
  color: "#E36F1E",
  fontFamily: "quicksand",
  fontWeight: "bolder",
  fontSize: "1.8em",
  textAlign: "center",
  paddingBottom: "0.5em",
};

const BuyShares =(props) => {

  const cachedMeeting = useLiveQuery(async () => {
   try {
      const items = await db.offlineMeeting.toArray();
      return items.filter(({vslaId})=>vslaId===props.vsla.id);
    } catch (error) {
      console.log(`Error: ${error}`);
      return [];
    }
  }, []);
  const [offlineMeeting, setOfflineMeeting] = React.useState(null);

  React.useEffect(()=> {
  if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
    setOfflineMeeting(null);
    return
  }
  setOfflineMeeting(cachedMeeting[0])
  }, []);

  React.useEffect(()=> {
  if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
    setOfflineMeeting(null);
    return
  }
  setOfflineMeeting(cachedMeeting[0])
  }, [cachedMeeting]);


  React.useEffect(()=> {
    props.pushTransactionContext("Shares bought");
    counterpart.setLocale(localStorage.getItem("lang"));
  }, []);

  const [share_value, setShareValue] = React.useState(props.vsla.metadata.share_value);
  React.useEffect(()=>{
    if (!props.vsla) {return}
    setShareValue(props.vsla.metadata.share_value)
  },[props.vsla])

  const [state, setState] = React.useState({
    amount: "",
    omt_receipt_number: "",
    asset: "SC",
    source: "issuer",
    dest: "member",
    loading: false,
    errors: {},
  });

  const onChange = (e) => setState({...state, [e.target.name]: e.target.value });

  const isValid = () => {
    const { errors, isValid } = validateInput(state);

    if (!isValid) {
      setState({ ...state, errors });
    }

    return isValid;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (isValid()) {
      processTransaction(
        arabicToInt(state.amount),
        state.asset,
        state.source,
        state.dest,
        state.omt_receipt_number
      );
      setState({ ...state, amount: "", omt_recipt_number: "" });
    }
  };

  const redirectBack = () => {
    props.setPage("group-memberList");
  };

  const processTransaction = (amount, asset, source, dest, omt_receipt_number) => {
    const lang_code = localStorage.getItem("lang");

    const lang = {
      FRANÇAIS: {
        message: `Confirmer ajouter ${state.amount} versements pour le membre`,
      },
      ENGLISH: {
        message: `Confirm buy ${state.amount} shares for member`,
      },
      SWAHILI: {
        message: `Thibitisha ununuzi wa hisa ${state.amount} kwa mwanachama`,
      },
      ZARMA: {
        message: `Zarma for Confirm buy ${state.amount} shares for member`,
      },
      HAOUSSA: {
        message: `Tabbatar da ỳar rukuni ta shiga adashe hannu ${state.amount}`,
      },
      SPANISH: {
        message: `Confirmar comprar ${state.amount} acciones para miembro`,
      },
      LEBANESE: {
        message: `Confirm adding ${state.amount} of savings for household`,
      },
      PORTUGUESE: {
        message: `Confirme a compra de ${state.amount} ações para o membro`,
      },
      ARABIC: {
        message: `أكد إضافة ${state.amount} إلى مدخرات الأسرة`,
      },
    };

    if (window.confirm(lang[lang_code].message)) {
      const franc = parseFloat(
        parseFloat(amount) * parseFloat(share_value)
      ).toFixed(2);
      if (amount !== "") {
        buyShares(franc.toString(), omt_receipt_number);
      }
    } else {
      notify(<WarningMessage />);
    }
  };

  const buyShares = (amount, omt_receipt_number) => {
    const uuid = props.currentMember.pubkey;

    props.commitTransaction(
      {
        uid: uuid,
        meetingId: props.ongoingMeeting.length
          ? props.ongoingMeeting[0].id
          : offlineMeeting.data.id,
        type: "BUY_SHARES",
        groupId: props.group[0].blockchain_key,
        memberId: props.currentMember.blockchain_wallet_id,
        sharePrice: 1,
        amount: parseFloat(amount),
        metadata: {
          omtReceiptNumber: omt_receipt_number,
        },
      },
      offlineMeeting
    );
    notify(<WarningMessage msg={"notifications.processing"} />);
    redirectBack();
  };

  if (!state.loading) {
    return (
      <Container>
        <div>
          <h4 style={headerStyle}>{props.currentMember.full_name}</h4>
        </div>
        <div>
          <Translate
            content="title_copy.buy_shares"
            component="h4"
            style={headerStyle}
          />
        </div>

        <Row>
          <br />
          <Col>
            <Form onSubmit={onSubmit}>
              <Container>
                {props.group[0].country !== "Lebanon" &&
                  localStorage.getItem("lang") !== "ARABIC" && (
                    <FormGroup>
                      <Row>
                        <Col xs="3" sm="4">
                          <img src={cow} alt="CARE VSLA" />
                        </Col>
                        <Col xs="7" sm="4"></Col>
                        <Col xs="2" sm="4">
                          <Input
                            name="amount"
                            type="radio"
                            id="cow1"
                            style={loadingStyle}
                            onChange={onChange}
                            value="1"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6" sm="4">
                          <img src={cow} alt="CARE VSLA" />
                          <img src={cow} alt="CARE VSLA" />
                        </Col>
                        <Col xs="4" sm="4"></Col>
                        <Col xs="2" sm="4">
                          <Input
                            name="amount"
                            type="radio"
                            id="cow2"
                            style={loadingStyle}
                            onChange={onChange}
                            value="2"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6" sm="4">
                          <img src={cow} alt="CARE VSLA" />
                          <img src={cow} alt="CARE VSLA" />
                          <img src={cow} alt="CARE VSLA" />
                        </Col>
                        <Col xs="4" sm="4"></Col>
                        <Col xs="2" sm="4">
                          <Input
                            name="amount"
                            type="radio"
                            id="cow3"
                            style={loadingStyle}
                            onChange={onChange}
                            value="3"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="10" sm="8">
                          <img src={cow} alt="CARE VSLA" />
                          <img src={cow} alt="CARE VSLA" />
                          <img src={cow} alt="CARE VSLA" />
                          <img src={cow} alt="CARE VSLA" />
                        </Col>

                        <Col xs="2" sm="4">
                          <Input
                            name="amount"
                            type="radio"
                            id="cow4"
                            style={loadingStyle}
                            onChange={onChange}
                            value="4"
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="10" sm="8">
                          <img src={cow} alt="CARE VSLA" />
                          <img src={cow} alt="CARE VSLA" />
                          <img src={cow} alt="CARE VSLA" />
                          <img src={cow} alt="CARE VSLA" />
                          <img src={cow} alt="CARE VSLA" />
                        </Col>
                        <Col xs="2" sm="4">
                          <Input
                            name="amount"
                            type="radio"
                            id="cow5"
                            style={loadingStyle}
                            onChange={onChange}
                            value="5"
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  )}

                <FormGroup>
                  {["ARABIC", "LEBANESE"].includes(
                    localStorage.getItem("lang")
                  ) && <Label style={loadingStyle}>{getTranslation("placeholder_copy", "savingsAmount")}</Label>}
                  <span style={{ color: "red" }}>{state.errors.amount}</span>
                  <Input
                    type="number"
                    name="amount"
                    style={loadingStyle}
                    placeholder={getTranslation("placeholder_copy", "number_of_shares")}
                    onChange={onChange}
                    value={state.amount}
                  />
                </FormGroup>
                {["ARABIC", "LEBANESE"].includes(
                  localStorage.getItem("lang")
                ) && (
                  <FormGroup>
                    <Label style={loadingStyle}>{getTranslation("placeholder_copy", "omt_receipt_number")}</Label>
                    <span style={{ color: "red" }}>
                      {state.errors.omt_receipt_number}
                    </span>
                    <Input
                      type="text"
                      name="omt_receipt_number"
                      style={loadingStyle}
                      placeholder={getTranslation("placeholder_copy", "enter_receipt_number")}
                      onChange={onChange}
                      value={state.omt_receipt_number}
                    />
                  </FormGroup>
                )}
                {!["ARABIC", "LEBANESE"].includes(
                  localStorage.getItem("lang")
                ) && (
                  <div
                    className="clearfix"
                    style={{
                      padding: ".5rem",
                      textAlign: "center",
                      fontFamily: "Fira Sans",
                      fontSize: "20px",
                      color: "#696969",
                    }}
                  >
                    {share_value !== 0 ? (
                      <p>
                        <i>
                          1 {getTranslation("placeholder_copy","share")} = {parseFloat(share_value).toFixed(2)}{" "}
                          {!["ARABIC", "LEBANESE"].includes(
                            localStorage.getItem("lang")
                          ) && getCurrency(props.group[0].country).symbol}
                          {["ARABIC", "LEBANESE"].includes(
                            localStorage.getItem("lang")
                          ) &&
                            (props.vsla.metadata.currency === "USD"
                              ? getCurrency(props.group[0].country).usd_symbol
                              : getCurrency(props.group[0].country).lbp_symbol)}
                        </i>
                      </p>
                    ) : (
                      <Translate
                        content="notifications.share_value_loading"
                        component="span"
                        style={loadingStyle}
                      />
                    )}
                    {state.amount && (
                      <p>
                        <b>
                          {getTranslation("placeholder_copy", "total_amount")} ={" "}
                          {parseFloat(
                            parseFloat(state.amount) * parseFloat(share_value)
                          ).toFixed(2)}{" "}
                          {!["ARABIC", "LEBANESE"].includes(
                            localStorage.getItem("lang")
                          ) && getCurrency(props.group[0].country).symbol}
                          {["ARABIC", "LEBANESE"].includes(
                            localStorage.getItem("lang")
                          ) &&
                            (props.vsla.metadata.currency === "USD"
                              ? getCurrency(props.group[0].country).usd_symbol
                              : getCurrency(props.group[0].country).lbp_symbol)}
                        </b>
                      </p>
                    )}
                  </div>
                )}
              </Container>
              <Container>
                <FormGroup>
                  <Input
                    type="submit"
                    value={getTranslation("placeholder_copy", "shares_submit")}
                    name="submit"
                    className="btn btn-primary"
                  />
                </FormGroup>
              </Container>
            </Form>
          </Col>
        </Row>
        {props.isConnected && (
          <LastSharesBought
            redirectBack={redirectBack.bind(this)}
            reversal_details={{
              destFundType: "issuer",
              reversalTransactionType: "Shares bought reversed",
            }}
            offlineMeeting={offlineMeeting}
            transactions={props.transactions.filter(
              ({ memberId, type }) =>
                memberId === props.currentMember.blockchain_wallet_id &&
                type === "BUY_SHARES"
            )}
          />
        )}
      </Container>
    );
  }

  return (
    <div>
      <center>
        <img src={stellarProcessing} style={spinnerStyle} alt="Stellar" />
        <br />
      </center>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    vsla: state.vsla,
    error: state.error,
    isConnected: state.isConnected,
    ongoingMeeting: state.currentOngoingMeeting,
    currentMember: state.loadedMember,
    group: state.loadedvslas,
    vslaSummary: selectCreatedVslaInfo(state),
    numberOfMeetings: state.groupMeetings,
    transactions: state.loadedMeetingTransaction.filter(
      ({ type, memberId }) =>
        memberId === state.loadedMember.blockchain_wallet_id &&
        type === "BUY_SHARES"
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPage: (page) => dispatch(setCurrentPage(page)),
    commitTransaction: (data, meeting) =>
      dispatch(initiateTransaction(data, meeting)),
    pushTransactionContext: (type) => dispatch(currentTransactionContext(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyShares);
