import React from "react";
import { ListGroup, ListGroupItem, Container } from "reactstrap";
import meetingLogo from "../../view/assets/meeting.svg";
import groupLogo from "../../view/assets/group.svg";
import counterpart from "counterpart";
import Translate from "react-translate-component";
import ZARMA from "../../view/shared/lang/ZARMA";
import ENGLISH from "../../view/shared/lang/ENGLISH";
import SWAHILI from "../../view/shared/lang/SWAHILI";
import HAOUSSA from "../../view/shared/lang/HAOUSSA";
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS";
import SPANISH from "../../view/shared/lang/SPANISH";
import { connect } from "react-redux";
import { selectCreatedVslaInfo } from "../../store/reducers/getVslas";
import { setCurrentPage } from "../../store/reducers/navigation";

counterpart.registerTranslations("ZARMA", ZARMA);
counterpart.registerTranslations("SWAHILI", SWAHILI);
counterpart.registerTranslations("ENGLISH", ENGLISH);
counterpart.registerTranslations("HAOUSSA", HAOUSSA);
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS);
counterpart.registerTranslations("SPANISH", SPANISH);
const iconStyle = {
  float: "left",
};

const headerStyle = {
  color: "#E36F1E",
  fontFamily: "quicksand",
  fontWeight: "bolder",
  fontSize: "1.8em",
  textAlign: "center",
  paddingBottom: "0.5em",
};

const subheaderStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
  textAlign: "left",
  marginLeft: "2.4em",
  lineHeight: "1.5em",
};

const VslaMenu = (props) => {
  React.useEffect(() => {
    counterpart.setLocale(localStorage.getItem("lang"));
  }, []);

  return (
    <div>
      <Translate content="menu.vlsa" component="h4" style={headerStyle} />
      <Container>
        <ListGroup
          flush
          style={{
            float: localStorage.getItem("lang") === "ARABIC" ? "right" : "none",
          }}
        >
          <ListGroupItem>
            <span
              onClick={() => props.setPage("group-details")}
              // to={{
              //   pathname: `/Groups/${this.props.vslas[0].id}/members`,
              //   state: {
              //     description: description,
              //     vsla_id: id,
              //     group_id: group_id,
              //     locality: locality,
              //   },
              // }}
            >
              <img style={iconStyle} src={groupLogo} alt="Stellar" />
              <Translate
                content="menu.group"
                className="textStyle"
                style={subheaderStyle}
              />
            </span>
          </ListGroupItem>
          <ListGroupItem>
            <span
              onClick={() => props.setPage("group-meetingsMenu")}

              // to={{
              //   pathname: `/Groups/${id}/meeting`,
              //   state: {
              //     description: description,
              //     vsla_id: id,
              //     group_id: group_id,
              //   },
              // }}
            >
              <img style={iconStyle} src={meetingLogo} alt="Stellar" />
              <Translate
                content="menu.meeting"
                className="textStyle"
                style={subheaderStyle}
              />
            </span>
          </ListGroupItem>
        </ListGroup>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    vslas: state.loadedvslas,
    vslaSummary: selectCreatedVslaInfo(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return { setPage: (page) => dispatch(setCurrentPage(page)) };
};

export default connect(mapStateToProps, mapDispatchToProps)(VslaMenu);
