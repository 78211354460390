import { getGroupMemberBalances } from "../../reducers/transactions/actions";
import { backendAPI } from "../../api";
import {
  deleteCacheByKey,
  getCacheItemByRefID,
} from "../../../view/shared/utilities/cache";

export const vslaMembersLoadStarted = (bool) => ({
  type: "VSLA_MEM_LOAD_START",
  isloading: bool,
});

export const vslaMemberLoaded = (members) => ({
  type: "LOADED_VSLA_MEMBERS",
  members,
});

export const vslaMemberLoadError = (error) => ({
  type: "VSLA_MEM_LOAD_ERROR",
  error: error,
});

export function fetchVslasMembers() {
  return async (dispatch, getState) => {
    try {
      const { loadedvslas, user } = getState();
      if (loadedvslas && loadedvslas.length) {
        const { data } = await backendAPI.get(
          `/api/v1/groups/${loadedvslas[0].id}/members`,
          {
            headers: {
              Authorization:
                "Bearer " + user.token || localStorage.getItem("token"),
            },
          }
        );

        // Dispatch the action to get group member balances before
        await dispatch(getGroupMemberBalances());

        // Update the cache for each member
        for (const member of data) {
          // Remove member from the cache if the status is Successful.
          // This prevents subsequent WebSocket calls from affecting other members in the Processing state.
          if (
            member.processing_status &&
            member.processing_status.toLocaleLowerCase() === "successful"
          ) {
            const cachedMember = await getCacheItemByRefID(
              "members",
              member.pubkey
            );
            if (cachedMember && cachedMember.length) {
              await deleteCacheByKey("members", "refId", cachedMember[0].refId);
            }
          }
        }

        // Dispatch the updated loadedvslaMembers array
        dispatch(vslaMemberLoaded(data));
        return Promise.resolve(data);
      } else {
        dispatch(vslaMemberLoaded([]));
        return Promise.resolve([]);
      }
    } catch (error) {
      dispatch(vslaMemberLoadError(error));
      return Promise.reject();
    }
  };
}
