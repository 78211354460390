export default {
  countries: {
    Niger: "Niger",
    Tanzania: "Tanzania",
    Rwanda: "Rwanda",
    Zambia: "Zambia",
    Honduras: "Honduras",
    Nigeria: "Nigeria",
    Lebanon: "Lebanon",
    Mozambique: "Moçambique",
    Moçambique: "Moçambique",
    Ghana: "Ghana",
    Syria: "Syria",
    Palestine: "Palestine",
  },
  title_copy: {
    search_no_result_message: "Extraviado",
    search_placeholder: "Search name",
    status: "status",
    version_msg: "You're using the test version of vsla.online",
    cycles_title: "Saving cycles",
    header_login: "Iniciar Sesión",
    header_signup: "Regístrate",
    create_group: "Crea Un Grupo",
    group_finance: "Fondo Social:",
    group_header: "GRUPO",
    loading: "Cargando...",
    updating: "Actualización y activación ....",
    check_status: "Confirmando el estado del grupo",
    no_vsla: "Ningún grupo encontrado",
    create_meeting: "CREAR REUNIÓN",
    meeting_members: "Miembros",
    meeting: "REUNIÓN",
    prev_meeting: "REUNIONES ANTERIORES",
    offline_meeting: "Offline meeting",
    add_member: "INFORMACION PERSONAL",
    buy_shares: "COMPRAR ACCIONES",
    sell_shares: "VENDER ACCIONES",
    fine: "PAGAR MULTA",
    welfare: "CONTRIBUCIÓN SOCIAL",
    welfare_request: "SOLICITUD FONDO SOCIAL",
    membership_fee: "CUOTA DE MIEMBRO",
    loan: "PRÉSTAMO",
    funds: "FONDOS",
    more_details: "Más detalles",
    vsla_actions: "Acciones Grupales",
    mem_actions: "Acciones de Miembro",
    memberlist_overview: "LISTA",
    mem_menu: "AÑADIR MIEMBROS",
    loan_repayment: "PAGO DE PRÉSTAMO",
    loan_writeoff: "CANCELAR PRÉSTAMO",
    outstanding_loan: "Préstamo Pendiente:",
    outstanding_service_charge: "Cargo por servicio pendiente:",
    attendees: "Asistentes a la Reunión",
    share_value: "Valor de la acción",
    cashOutType: "Retirar dinero",
    settings: "CONFIGURACIONES",
    current_savings: "Ahorros",
    cycle_duration: "Duración del ciclo",
    addFundAccount: "AÑADIR CUENTA DE FONDO",
    supported_accounts: "Cuentas soportadas",
    lastTransaction: "Última transacción",
    otherIncome: "OTROS INGRESOS",
    otherExpenses: "OTROS GASTOS",
    cashOut: "EFECTIVO",
    warning: "Warning",
    end_of_cycle_cash_out: "End-cycle cash out",
    reminder: "Reminder",
  },

  menu: {
    vlsa: "VSLA",
    group: "Grupo",
    meeting: "Reunión",
    create_vsla: "Crea Un Grupo",
    vsla_overview: "Resumen del Grupo",
    new_meeting: "Nueva Reunión",
    prev_meeting: "Reuniones Anteriores",
    offline_meeting: "Offline meeting",
    vsla_saving: "Fondo de ahorro:",
    vlsa_loan: "Préstamos totales:",
    vlsa_balance: "Saldo Actual:",
    funds: "Fondos",
    members: "Miembros",
    members_overview: "Resumen de los Miembros",
    add_member: "Añadir Miembro",
    end_meeting: "Terminar la Reunión",
    current_meeting: "Reunión Actual",
    settings: "Configuraciones",
    options: "Opciones",
    language: "Idioma",
    close: "Cerrar",
    savingsFund: "Fondo de ahorro",
    welfareFund: "Fondo Social",
    loanInterestFund: "Fondo de intereses de préstamos",
    fineFund: "Fondo de multas",
    membershipFund: "Fondo de membresía",
    end_of_cycle_cash_out: "End of cycle cash out",
  },

  menu_mem: {
    overview: "Resumen de miembros",
    welfare: "Contribución Social",
    welfare_request: "Solicitud Fondo Social",
    buy_shares: "Comprar acciones",
    create_loan: "Solicitud Préstamo",
    repay_loan: "Pagar el préstamo",
    writeoff_loan: "Cancelar Préstamo",
    pay_fine: "Pagar la multa",
    membership_fee: "Cuota de miembro",
    sell_shares: "Vender acciones",
    transaction_amount: "Valor de la transacción",
    other_income: "Otros Ingresos",
    other_expenses: "Otros Gastos",
    cashout: "Retirar dinero",
    group_income: "Group Income",
    group_expense: "Group Expenses",
    fine_payment: "Fine Payment",
    welfare_contribution: "Welfare Contribution",
    loan_repayment: "LOAN REPAYMENT",
    loan_interest_repayment: "Loan Interest Repayment",
    fine_payment_reversal: "Fine Payment Reversed",
    buy_shares_reversal: "Buy Shares Reversed",
    welfare_contribution_reversal: "Welfare Contribution Reversed",
    sell_shares_reversal: "Sell Shares Reversed",
    loan_interest_repayment_reversal: "Loan Interest Repayment Reversed",
    welfare_request_reversal: "Welfare Request Reversed",
    membership_fee_reversal: "Membership Fee Reversed",
    savings_cash_out: "Savings cashout",
    midCycle_Cashout: "Mid-cycle cash out",
    group_income_fund: "Ingresos del grupo - Ahorros",
    group_income_fine: "Ingresos del grupo - Multa",
    group_income_welfare: "Ingresos del grupo - Bienestar",
    group_expense_fund: "Gasto de grupo - Ahorros",
    group_expense_fine: "Gasto de grupo - Multa",
    group_expense_welfare: "Gasto de grupo - Bienestar",
  },

  mem_overview: {
    welfare: "Contribución Social:",
    shares_in_francs: "Acciones en moneda:",
    loan: "Valor del préstamo:",
    shares_in_numbers: "Acciones en números:",
    welfare_total: "Social:",
    shares_total: "Acciones de miembros:",
  },

  header: {
    home: "Inicio",
    vsla: "Grupo",
    logout: "Cerrar Sesión",
    whats_new: "¿Qué hay de Nuevo?",
    tutorials: "Tutoriales",
    legal: "Legal",
  },

  placeholder_copy: {
    loader_msg1: "Crear perfil de grupo",
    loader_msg2: "Crear entradas de cuentas",
    loader_msg3: "Asignar cuentas al grupo",
    loader_msg4: "Asignar una secretaria de aplicación",
    add_account_loader_msg: "Crear cuentas, último paso",
    group_loader_msg: "Creando grupo, solo un momento.",
    secretary_pay_interest: "¿El secretario paga intereses por préstamos?",
    nationality: "Nationality",
    gender: "género",
    female: "mujer",
    male: "masculina",
    sms_code: "Código de verificación",
    submit: "Enviar",
    income_amount: "valor...",
    login_email: "Número de teléfono",
    login_password: "Contraseña...",
    login_submit: "Iniciar sesión",
    login_submitting: "Iniciando sesión...",
    signUp_submit: "Regístrate",
    signUp_submitting: "Registrando...",
    create_group_name: "Nombre del grupo...",
    create_group_location: "Localización del grupo...",
    create_group_submit: "Crear un grupo",
    create_fund_submit: "Crear fondo",
    meeting_date: "Seleccione fecha",
    meeting_submit: "Crear reunión",
    meeting_submitting: "Crear reunión",
    member_submit: "Crear miembro",
    member_name: "Nombre completo",
    member_phone: "Número de teléfono",
    number_of_shares: "Numero de acciones...",
    shares_submit: "Comprar Acciones",
    share: "Acciones",
    loan_amount: "Valor del Préstamo...",
    loan_submit: "Solicitud Préstamo",
    welfare_amount: "Valor Social...",
    welfare_submit: "Pagar Social",
    payment_amount: "Valor de Pago...",
    repay_submit: "Pagar Préstamo",
    loan_writeoff: "Cancelar Préstamo",
    share_value_amount: "Valor de la acción..",
    share_value_submit: "Actualizar configuraciones",
    create_group_share_value: "Valor de la acción del ciclo de grupo",
    role_in_group: "Rol en el grupo",
    login_pin: "Código PIN",
    login_pin_confirm: "Confirmar Código PIN",
    create_group_federation: "Federación...",
    savings_cycle: "Seleccionar ciclo de ahorro...",
    pays_interest: "¿El miembro paga intereses de préstamos por préstamos?",
    country: "Seleccionar país",
    date_of_birth: "Fecha de nacimiento",
    add_secretary_header: "Agregando Secretario/a",
    add_secretary_msg: "El/La secretario/a se agregará primero.",
    add_secretary_status: "Agregando...",
    add_secretary: "Agregar Secretario/a",
    total_amount: "Valor Total",
    fine_amount: "Valor de la multa...",
    fine_submit: "Pagar la Multa",
    membership_amount: "Cuota de Miembro...",
    membership_submit: "Pagar membresía",
    sell_shares: "Vender acciones",
    membership_reverse: "Inversar",
    service_charge_amount: "Valor del Cargo por servicio",
    forgot_pin: "Olvidaste tú Código PIN?",
    account_associated: "Ingrese el número de teléfono asociado a su cuenta",
    token_sent: "Ingrese el código de 4 dígitos que recibió por SMS.",
    resend_token: "Reenviar el código",
    submit_smscode: "Ingrese su nuevo código PIN de 4 dígitos",
    income_submit: "Retirar dinero",
    select_nationality: "Select Nationalities",
    next: "Next",
    cashingOut: "Cashing out...",
    finish: "Finish",
    currency: "Select currency",
    select: "Select",
    savings_amount: "Savings amount",
    omt_recipt_number: "OMT Receipt number",
    enter_recipt_number: "Enter receipt number",
    savings_withdrawal: "Savings withdrawal",
    project_name: "Nombre del proyecto",
    retry: "Reintentar",
    remove: "Eliminar",
    secretary_creation_msg: "Actualmente se está creando la secretaría...",
    secretary_creation_failed_msg:
      "La creación de secretaria fracasó. Por favor, intenta de nuevo",
  },

  delete: "Eliminar grupo",
  delete_member: "Eliminar miembro",
  edit: "Editar grupo",
  save: "Salvar",
  edit_member: "Editar miembro",

  alert: {
    upload_error:
      "offline transaction upload encountered an error. Go to offline meeting record to see the error",
    upload_success:
      "Offline transactions have been uploaded and resolved. Check previous meetings records to see it",
    no_members: "No hay miembros en este grupo",
    no_savings_cash_out_personal:
      "Ahorro en efectivo bloqueado, préstamo personal sin liquidar",
    no_savings_cash_out_group:
      "Ahorro en efectivo bloqueado, préstamo sin liquidar en grupo",
    no_membership_cash_out_personal:
      "Pago de membresía bloqueado, préstamo personal no liquidado",
    no_membership_cash_out_group:
      "Retiro de membresía bloqueado, préstamo sin liquidar en grupo",
    no_fine_cash_out_personal:
      "Pago de multa bloqueado, préstamo personal sin liquidar",
    no_fine_cash_out_group:
      "Pago de multa bloqueado, préstamo sin liquidar en grupo",
    no_welfare_cash_out_personal:
      "Retiro de fondos sociales bloqueado, préstamo personal sin liquidar",
    no_welfare_cash_out_group:
      "Retiro de fondos socials bloqueado, préstamo no liquidado en grupo",
    cash_out_success:
      "Se han cumplido todas las condiciones para permitir el retiro",
    success: "Éxito",
    cash_out_type_not_define:
      "El tipo de retiro de efectivo para este fondo no está definido, actualícelo desde el menú de configuración",
    cannot_end_meeting: "Can't end the meeting while a transaction in progress",
    failed: "Failed",
    repay_loans_message: "Repay the loans these members have",
    cannot_cash_out: "Can't cash out while a transaction in progress",
    all_members_cashed_out: "All members have been cashed out",
    no_internet: "No Internet",
    uploading_transactions: "Uploading transactions...",
    member_cashed_out: "Member has been cashed out",
    member_pending_loan: "Member has a loan they need to repay",
    cannot_delete_member_cashout_msg:
      "No se puede eliminar a un miembro mientras el retiro está en curso",
    cannot_delete_member_secretary_msg:
      "No se puede eliminar el secretario del grupo.",
    cannot_delete_member_has_balance_msg:
      "No se puede eliminar, el miembro debe ser retirado primero",
  },

  dropdowns: {
    secretary: "Secretario/a",
    chairperson: "Presidente/a",
    keyHolder: "Llavero/a",
    box_keeper: "Guardián de caja",
    organiser: "organizador",
    money_counter: "Contador de dinero",
    member: "Miembro",
    yes: "Si",
    no: "No",
    nine_months: "9 meses",
    six_months: "6 meses",
    year: "1 año",
    establishment_date: "Fecha de establecimiento",
    business_development: "Business development",
    personal_emergency: "Personal emergency",
    other: "Other",
  },

  cards: {
    total_member: "Miembros totales",
    cycle_duration: "Duración del ciclo",
    country: "País",
    locality: "Localidad",
    federation: "Federación",
    share_value: "Valor de la acción",
    months: "Meses",
    created_at: "Creado en",
    established_on: "Establecimiento en",
  },

  notifications: {
    group_exist_error: "Nom du groupe déjà pris. Veuillez en choisir un autre",
    loan_error: "Error: Member has an active loan",
    edit_success: "Actualizado exitosamente",
    edit_error: "Error al actualizar",
    signup_success: "Cuenta creada con éxito",
    success: "Completado satisfactoriamente",
    error: "No se completó correctamente",
    group_create_success: "Grupo creado correctamente",
    group_create_error: "Error al crear el grupo",
    activation_success: "Grupo activado con éxito",
    activation_error: "Error al activar el grupo",
    meeting_end_success: "Reunión terminó",
    meeting_end_error: "Error al finalizar la reunión",
    meeting_create_success: "Reunión iniciada",
    meeting_create_error: "La reunión no comenzó",
    member_create_success: "Miembro creado correctamente",
    member_create_error: "Error al crear miembro",
    meeting_no_member_warning:
      "No se puede iniciar la reunión antes de agregar miembros a VSLA",
    no_previous_meeting: "No hay reuniones previas para esta VSLA",
    no_transactions: "No hay transacciones para esta reunión",
    loan_amount_error: "El valor del préstamo supera el valor permitido",
    share_amount_error: "La cantidad de acciones excede la cantidad permitida",
    share_value_loading: "Obtener el valor actual de las acciones",
    cant_update_shares:
      "La reunión está en curso. No se puede actualizar el valor de la acción",
    member_phone_error: "Número en uso",
    savings_fund_mandatory:
      "Deseleccione los fondos no utilizados. Se requieren fondos de ahorro",
    fund_create_success: "Fondo creado con éxito",
    gdpr_warning:
      "Al proceder a crear la cuenta, confirma que tiene 18 años o más",
    can_not_request_welfare:
      "El saldo social actual es cero, la solicitud no es posible",
    no_reversible_transactions: "No se encontraron transacciones reversibles",
    activate_reversible_transactions:
      "Marque la casilla para activar la reversión de transacciones",
    welfare_amount_warning: "El valor excede el valor permitido",
    pin_success: "El código PIN se cambió correctamente",
    pin_error: "¡Ingrese el código correcto! Inténtalo de nuevo",
    cancel_txn: "Transacción cancelada",
    processing: "Procesamiento de transacción",
    pay_out_standing_charge_first:
      "El cargo por servicio debe eliminarse antes del reembolso del préstamo",
    cant_delete_member_has_funds:
      "No se puede eliminar al miembro si le queda dinero o mientras la reunión está en curso",
    cant_delete_group_has_funds:
      "El grupo no se puede eliminar si la billetera está financiada o si la reunión está en curso",
    end_of_cycle_cash_out_warning:
      "Once you've started end of cycle cashout, you won't be able to add any new transaction records until cash out is completed.",
    cash_out_success_msg: "Cash out completed",
    share_value_information:
      "You are starting a new saving cycle. Do you want to change the share value?",
    share_value_updated_msg: "Share value updated!",
    reversal: "Inversión de Procesamiento",
    mid_cycle_cash_out_warning:
      "Una vez que haya iniciado el retiro a mitad del ciclo, no podrá agregar ningún registro de transacción nuevo hasta que se complete el retiro.",
    proceed_to_cashout_msg: "Escribe “retiro” para continuar",
    cashout: "retiro",
    incorrect_username_password_msg: "Nombre de usuario o PIN incorrecto",
    contactHiveForSupportMsg:
      "Error al enviar el código. Póngase en contacto con hiveonline para obtener ayuda",
  },

  badge: {
    meeting_on: "Finalizar reunión",
  },

  span: {
    current_cycle: "Current Cycle",
    previous_cycles: "Previous cycles",
    transaction_status: "Transactions status",
    login_span: "¿No tienes una cuenta?",
    signup: "Regístrate",
    signup_span: "¿Ya tienes una cuenta?",
    login: "Iniciar sesión",
    welfare: "Social",
    savings: "Ahorros",
    fine: "Multa",
    endOfCycle: "Fin de ciclo",
    midCycle: "Mitad de ciclo",
    membership: "Afiliación",
    sharedBased: "Basado en acciones",
    equal: "Igual",
    refund: "Reembolso",
    selectCashOutType: "Seleccionar tipo de Retiro de Efectivo",
    savingsFund: "Fondo de Ahorro",
    membershipFund: "Fondo de cuotas de membresía",
    welfareFund: "Fondo de Asistencia Social",
    fineFund: "Fundo de Multa",
    equally: "Igualmente",
    fineCashOutType: "Tipo de Retiro de Efectivo - Multa",
    welfareCashOutType: "Tipo de Retiro de Efectivo - Fondo Social",
    CashOutTypeSelect: "Seleccionar tipo de Retiro de Efectivo",
    selectFund: "Seleccionar Fondo",
    loanServiceCharge: "Intereses por Prestamo",
    member_role: "Rol de miembro",
    processing: "processing",
    back: "Back",
    continue: "Continue",
    no_records_found: "No transactions records found",
    loan_purpose: "Loan purpose",
    cancel: "Cancel",
    save: "Save",
    saving: "Saving...",
    bc_wallet: "Cartera BC",
    link: "Enlace",
  },
  banner: {
    install: "Descargar",
  },
  locale: {
    date: "es-ES",
  },
  cashOut: {
    write_here: "Escribe aquí",
  },
  status: {
    SUCCESSFUL: "SUCCESSFUL",
    PENDING: "PENDING",
    FAILED: "FAILED",
  },
  locations: {
    country: "País",
    county: "Condado",
    province: "Provincia",
    region: "Región",
    district: "Distrito",
    gh_district: "Metropolitana/Municipal/Distrital",
    municipality: "Municipio",
    sub_county: "Subcondado",
    administrative_post: "Posto administrativo",
    locality: "Localidad",
    select_country: "seleccionar país",
    select_county: "seleccionar condado",
    select_sub_county: "seleccione subcondado",
    select_province: "seleccione provincia",
    select_region: "seleccione región",
    select_district: "seleccionar distrito",
    select_municipality: "seleccione municipio",
    select_administrative_post: "seleccionar puesto administrativo",
    select_locality: "seleccionar dirección",
    neigbourhood_name: "Nombre del barrio/calle",
  },
};
