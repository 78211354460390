import React from "react";
import { ListGroup, ListGroupItem, Container } from "reactstrap";
import counterpart from "counterpart";
import Translate from "react-translate-component";
import ZARMA from "../../view/shared/lang/ZARMA";
import ENGLISH from "../../view/shared/lang/ENGLISH";
import SWAHILI from "../../view/shared/lang/SWAHILI";
import HAOUSSA from "../../view/shared/lang/HAOUSSA";
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS";
import SPANISH from "../../view/shared/lang/SPANISH";
import { connect } from "react-redux";
import uuid from "uuid";
import { itemsFetchBalances } from "../../store/actions/getVslaBalance";
import { selectBalance } from "../../store/reducers/getVslaBalance";
import { selectVslaDebtBalance } from "../../store/reducers/getVslaBalance";
import { getCurrency } from "../../view/shared/utilities/currency";
import LEBANESE from "../../view/shared/lang/LEBANESE";
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE";
import ARABIC from "../../view/shared/lang/ARABIC";
import { getBalances } from "../../store/reducers/transactions/actions";
counterpart.registerTranslations("ARABIC", ARABIC);

counterpart.registerTranslations("PORTUGUESE", PORTUGUESE);
counterpart.registerTranslations("LEBANESE", LEBANESE);
counterpart.registerTranslations("ZARMA", ZARMA);
counterpart.registerTranslations("SWAHILI", SWAHILI);
counterpart.registerTranslations("ENGLISH", ENGLISH);
counterpart.registerTranslations("HAOUSSA", HAOUSSA);
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS);
counterpart.registerTranslations("SPANISH", SPANISH);

const headerStyle = {
  color: "#E36F1E",
  fontFamily: "quicksand",
  fontWeight: "bolder",
  fontSize: "1.8em",
  textAlign: "center",
  paddingBottom: "0.5em",
  margin: "34px 0 0 0",
};

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
};

const summary = {
  ARABIC: {
    savings: "المدخرات",
    loan_interest: "فائدة القرض",
    Debt: "الدين",
    membership: "العضوية",
    fine: "الغرامة",
    welfare: "الرعاية",
    shares: ":عدد الأسهم",
    loan: "قروض غير مدفوعة",
    welfare_requests: "إجمالي طلبات الرعاية",
  },
  ENGLISH: {
    savings: "Savings fund",
    shares: "Number of shares",
    loan_interest: "Loan interest fund",
    loan: "Unpaid loans",
    Debt: "Debt",
    membership: "Membership fee fund",
    welfare_requests: "Total welfare requests",
    "Fine paid": "Fines fund",
    welfare: "Welfare fund",
  },
  FRANÇAIS: {
    savings: "Économies",
    loan_interest: "Intérêts d'emprunt",
    Debt: "Dette",
    membership: "Adhésion",
    "Fine paid": "Amende",
    welfare: "Sociale",
  },
  SWAHILI: {
    savings: "Mfuku wa Akiba",
    shares: "Hizi zilizopo",
    loan: "Mikopo haijalipwa",
    loan_interest: "Mfuku wa riba ya mkopo",
    Debt: "Deni",
    membership: "Mfuko wa ada ya uanachama",
    "Fine paid": "Mfuko wa faini",
    welfare: "Mfuko wa jamii",
  },
  SPANISH: {
    savings: "Ahorro",
    loan_interest: "Interés del préstamo",
    Debt: "Deuda",
    membership: "Membresía",
    fine: "Multa",
    welfare: "Bienestar",
  },
  LEBANESE: {
    savings: "Savings fund",
    shares: "Number of shares",
    loan_interest: "Loan interest fund",
    loan: "Unpaid loans",
    Debt: "Debt",
    membership: "Membership fee fund",
    welfare_requests: "Total welfare requests",
    "Fine paid": "Fines fund",
    welfare: "Welfare fund",
  },
  PORTUGUESE: {
    savings: "Poupanças",
    loan_interest: "Juros do empréstimo",
    Debt: "Dívida",
    membership: "Filiação de Mebro",
    fine: "Multa",
    welfare: "Fundo Social",
    shares: "Número de Ações",
    loan: "Empréstimos não pagos",
    welfare_requests: "Pedidos Totais de Fundo Social",
    "Fine paid": "Fundo de Multas",
  },
};

export const processBalanceData = (balance, welfareReqs) => {
    const sumWelfareRequests = (welfareReqs || []).reduce((acc, item)=> {
      acc+=item.balance
      return acc;
    }, 0)
    const item = {
      ...welfareReqs[0],
      balance: sumWelfareRequests,
    }
  return [...balance, item]
    .filter((balance) => balance.balance > 0 && [
      "savings", "Fine paid", "loan", "shares", "loan_interest", "membership", "welfare"].includes(
        balance.accountType))
    .sort((a, b)=> a.balance > b.balance ? 0: b.balance > a.balance ? 1: -1)
    .map((balance) => {
      return [
        {
          issuer: balance.issuer,
          asset: balance.assetType,
          type: balance.accountType,
          amount: balance.balance,
        },
      ];
    });
};

const FundSummary = (props) => {
  const [processedData, setProcessedData] = React.useState([]);
  const setHook = (fn, args) => fn(args);
  React.useEffect(() => {
    counterpart.setLocale(localStorage.getItem("lang"));
    const { account, fetchBalances } = props;
    const vslaBalance = processBalanceData(
      Object.values(account.balances),
      Object.values(account.members).filter(({welfare_requests})=>welfare_requests))
    setHook(
      setProcessedData,
      ["ARABIC", "LEBANESE"].includes(localStorage.getItem("lang"))
        ? vslaBalance.filter((bal) => bal[0].type !== "shares")
        : vslaBalance
    );
    fetchBalances();
  }, []);

  React.useEffect(()=> {
    const { account } = props;
    const vslaBalance = processBalanceData(
      Object.values(account.balances),
      Object.values(account.members).filter(({welfare_requests})=>welfare_requests))
    setHook(
      setProcessedData,
      ["ARABIC", "LEBANESE"].includes(localStorage.getItem("lang"))
        ? vslaBalance.filter((bal) => bal[0].type !== "shares")
        : vslaBalance
    );
  }, [props.account])

  const isProcessing = () => {
    return (
      (props.isloading && !props.vslaBalance.length) ||
      Object.keys(props.pendingTransactions)
        .filter((key) => key.split("_").length === 3)
        .map((key) => props.pendingTransactions[key]).length > 0
    );
  };

  if (!isProcessing() && !props.vslaBalance.length) {
    return (
      <div>
        <Translate
          content="title_copy.funds"
          component="h4"
          style={headerStyle}
        />
        <center>
          <span>No records exist</span>
        </center>
      </div>
    );
  }

  return (
    <div>
      <div>
        <Translate
          content="title_copy.funds"
          component="h4"
          style={headerStyle}
        />
      </div>
      {isProcessing() && (
        <span
          className="dots-loader"
          style={{
            color: "#E36F1E",
            display: "flex",
            justifyContent: "center",
            fontSize: 12,
            margin: "auto",
          }}
        >
          <div style={{ display: "flex", width: 70 }}>
            <div>Processing</div>
            <span></span>
          </div>
        </span>
      )}
      <Container>
        <ListGroup
          style={{
            textAlign:
              localStorage.getItem("lang") === "ARABIC" ? "right" : "initial",
          }}
          flush
        >
          {processedData.map((bal) => (
            <ListGroupItem
              key={uuid.v4()}
              className="textStyle"
              style={loadingStyle}
            >
              {summary[localStorage.getItem("lang")][bal[0].type]}:{" "}
              {bal[0].type === "shares"
                ? parseFloat(
                    bal[0].amount / parseFloat(props.vsla.metadata.share_value)
                  ).toFixed(2)
                : parseFloat(bal[0].amount).toFixed(2)}{" "}
              {bal[0].type !== "shares" &&
                !["ARABIC", "LEBANESE"].includes(
                  localStorage.getItem("lang")
                ) &&
                getCurrency(props.group[0].country).symbol}
              {bal[0].type !== "shares" &&
                ["ARABIC", "LEBANESE"].includes(localStorage.getItem("lang")) &&
                (props.vsla.metadata.currency === "USD"
                  ? getCurrency(props.group[0].country).usd_symbol
                  : getCurrency(props.group[0].country).lbp_symbol)}
            </ListGroupItem>
          ))}
        </ListGroup>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.balanceError,
    isloading: state.vslaBalanceLoading,
    vslaBalance: selectBalance(state),
    vslaDebt: selectVslaDebtBalance(state),
    pendingTransactions: state.pendingTransactions,
    group: state.loadedvslas,
    account: state.account,
    vsla: state.vsla,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (url, headers) => dispatch(itemsFetchBalances(url, headers)),
    fetchBalances: ()=> dispatch(getBalances())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FundSummary);
