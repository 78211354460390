import { setCurrentPage } from "../../reducers/navigation";
import { blockchainAPI } from "../../api";
import {
  addToCache,
  getCacheById,
  updateCacheItem,
} from "../../../view/shared/utilities/cache";
import { v4 as uuid } from "uuid";

export const isCreatingMember = (bool) => ({
  type: "IS_CREATING_MEMBER",
  isCreating: bool,
});

export const createMember = (member) => ({
  type: "CREATE_MEMBER",
  member,
});

export const createMemberBackendTwo = (memberTwo) => ({
  type: "CREATE_MEMBER_TWO",
  memberTwo,
});

export const createMemberError = (error) => ({
  type: "CREATE_MEMBER_ERROR",
  error,
});

export function postCreateMember(url, member, headers, ownProps) {
  return async function (dispatch, getState) {
    dispatch(isCreatingMember(true));
    try {
      await blockchainAPI.post(url, member, headers);
      dispatch(setCurrentPage("group-memberList"));
      dispatch(isCreatingMember(false));
    } catch (error) {
      dispatch(isCreatingMember(false));
      const { response } = error;
      if (!response.data) {
        return;
      }
      dispatch(createMemberError(error.response.data.detail));
    }
  };
}

export const retryAction =
  (namespace, id, member) => async (dispatch, getState) => {
    try {
      const item = await getCacheById(namespace, id);
      const pendingTransactionData =
        item && item.length ? { requestObject: item[0].requestObject } : null;
      if (!pendingTransactionData) {
        // If the data is not cached, this is a retry for secretary creation.
        // Form the request object and send the request.
        const { loadedvslas, user } = getState();
        const token = user.token || localStorage.getItem("token");
        const headers = { Authorization: `Bearer ${token}` };
        const group_id = loadedvslas[0].id;
        const url = `${
          window.REACT_APP_PWA_APP_DOMAIN
        }/api/v1/groups/${group_id}/members?requestId=${uuid()}`;

        // Adding this request via the createMember function enables the frontend
        // to display the processing status of this member using our local caching mechanism.
        // The frontend can then update itself based on the webSocket response (Failed or Successful).
        await dispatch(
          postCreateMemberTwo(
            url,
            { ...member, phone: !member.phone ? "" : member.phone },
            headers
          )
        );
        return Promise.resolve();
      }
      const requestObject = JSON.parse(pendingTransactionData.requestObject);
      // Update the URL with the new requestId
      const url = new URL(requestObject.payload.url);
      url.searchParams.set("requestId", uuid());
      requestObject.payload.url = url.toString();
      //Update token
      requestObject.payload.headers.Authorization = `Bearer ${localStorage.getItem(
        "token"
      )}`;
      await dispatch(
        namespace ? requestObject : pendingTransactionData.requestObject
      );
      await updateCacheItem(namespace, { error: null }, id);
      return Promise.resolve();
    } catch (error) {
      console.error("Error: ", error);
      return Promise.reject();
    }
  };

export function postCreateMemberTwo(url, member, headers, ownProps) {
  return async function (dispatch, getState) {
    const { vsla } = getState();
    const cacheKey = await addToCache("members", {
      data: member,
      refId: member.pubkey,
      vslaId: vsla.id,
    });
    try {
      const requestObject = {
        pushEvent: "requests",
        receiveEvent: "response",
        emit: true,
        metadata: {
          userId: vsla.id,
          cacheKey,
          callBackParams: [],
          type: "members",
        },
        payload: {
          requestId: "vsla.members.new",
          url,
          headers,
          data: member,
          userId: vsla.id,
          method: "POST",
        },
      };
      dispatch(requestObject);
      await updateCacheItem(
        "members",
        { requestObject: JSON.stringify(requestObject) },
        cacheKey
      );
      dispatch(setCurrentPage("group-memberList"));
      return Promise.resolve();
    } catch (error) {
      const { response } = error;
      if (!response.data) {
        return;
      }
      dispatch(createMemberError(error.response.data.detail));
      return Promise.reject();
    }
  };
}
