import { getCountryName } from "../../../view/shared/helpers";
import { locationAPI } from "../../api";
import {
  SET_COUNTRIES,
  SET_LOCATIONS,
  SET_USER_SELECTED_LOCATION_DATA,
  triggerAction,
} from "../../constants";

const processData = (locations) => {
  try {
    const childrenMap = new Map();
    // Transform array to Maps for efficient lookup
    locations.forEach((location) => {
      if (location.parentLocationId) {
        if (!childrenMap.has(location.parentLocationId)) {
          childrenMap.set(location.parentLocationId, []);
        }
        childrenMap.get(location.parentLocationId).push(location);
      }
    });
    return { childrenMap };
  } catch (error) {
    console.log("Error Occured:", error);
    return {
      childrenMap: new Map(),
    };
  }
};

const fetchLocations = (country) => async (dispatch) => {
  try {
    const { data } = await locationAPI.get(
      `/locations/countries/name/${country}`
    );
    dispatch(triggerAction(SET_LOCATIONS, processData(data)));
    return Promise.resolve(data);
  } catch (error) {
    console.log("Error Occured:", error);
    return Promise.reject(error);
  }
};

const getCountries = () => async (dispatch) => {
  try {
    const { data } = await locationAPI.get(`/locations/countries`);
    // Transform array to Maps for efficient lookup
    const defaultCountry = data.find(
      ({ name }) =>
        name.toLocaleLowerCase() ===
        getCountryName(localStorage.getItem("_country")).toLocaleLowerCase()
    );
    dispatch(
      triggerAction(SET_COUNTRIES, { defaultCountry, allCountries: data })
    );
  } catch (error) {
    console.log("Error Occured:", error);
  }
};

const transportLocation = (data) => (dispatch) =>
  dispatch(triggerAction(SET_USER_SELECTED_LOCATION_DATA, data));

export { fetchLocations, transportLocation, getCountries };
