import { intToArabic } from "./arabicNumParser";
var arabicMonths = [
  "يناير",
  "فبراير",
  "مارس",
  "إبريل",
  "مايو",
  "يونيو",
  "يوليو",
  "أغسطس",
  "سبتمبر",
  "أكتوبر",
  "نوفمبر",
  "ديسمبر",
];
var arabicDays = [
  "اﻷحد",
  "اﻷثنين",
  "الثلاثاء",
  "اﻷربعاء",
  "الخميس",
  "الجمعة",
  "السبت",
];

export const arabicDateConverter = (date) => {
  return (
    intToArabic(new Date(date).getDate()) +
    " " +
    arabicMonths[new Date(date).getMonth()] +
    ", " +
    intToArabic(new Date(date).getFullYear())
  );
};

export const fullArabicDateConverter = (date) => {
  return (
    arabicDays[new Date(date).getDay()] +
    ", " +
    intToArabic(new Date(date).getDate()) +
    " " +
    arabicMonths[new Date(date).getMonth()] +
    ", " +
    intToArabic(new Date(date).getFullYear())
  );
};
