import React from "react";
import { ListGroup, ListGroupItem, Container } from "reactstrap";
import Translate from "react-translate-component";
import { connect } from "react-redux";
import uuid from "uuid";
import { formatStr } from "../../view/shared/utilities/outputFormat";
import { getCurrency } from "../../view/shared/utilities/currency";
import { selectMemberSharesFund } from "../../store/reducers/getMemberBalance";
import { getMemberBalanceByMemberId } from "../../store/reducers/transactions/actions";
import { intToArabic } from "../../view/shared/utilities/arabicNumParser";
import { getTranslation } from "../../view/shared/helpers";
import StellarLoader from "../layout/StellarLoader";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../view/shared/utilities/cache";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

const MemberOverview = (props) => {
  React.useEffect(() => {
    props.getBalance(props.currentMember.blockchain_wallet_id);
  }, []);

  const cachedTransactions = useLiveQuery(() => {
    try {
      return db.pendingTransactions
        .filter(
          ({ data, error, vslaId }) =>
            vslaId === props.vsla.id &&
            data.memberId === props.currentMember.blockchain_wallet_id &&
            !error
        )
        .toArray();
    } catch (error) {
      console.log(`Error: ${error}`);
      return [];
    }
  }, []);
  const lang = localStorage.getItem("lang");

  const loadingStyle = {
    fontFamily: "Fira Sans",
    fontSize: "20px",
  };

  const headerStyle = {
    color: "#E36F1E",
    fontFamily: "quicksand",
    fontWeight: "bolder",
    fontSize: "1.8em",
    textAlign: "center",
    paddingBottom: "0.5em",
    margin: "35px 0 0",
  };

  const getTotalShares = () => {
    const transactions = props.memberBalance(
      props.currentMember.blockchain_wallet_id
    ).shares;
    return transactions.balance;
  };

  if (props.vsla.length === 0) {
    return <StellarLoader />;
  } else {
    return (
      <div>
        <div>
          <h4 style={headerStyle}>{props.memberDetails.full_name}</h4>
        </div>
        <Container>
          <span style={{ float: "right" }}>
            {Array.isArray(cachedTransactions) &&
              cachedTransactions.length > 0 &&
              props.isConnected && (
                <span
                  className="dots-loader"
                  style={{
                    color: "#E36F1E",
                    fontSize: 12,
                    margin: "auto",
                  }}
                >
                  {getTranslation("span", "processing")}
                  <span style={{ position: "absolute" }}></span>
                </span>
              )}
          </span>
          <ListGroup
            flush
            style={{
              textAlign:
                localStorage.getItem("lang") === "ARABIC" ? "right" : "intial",
            }}
          >
            {Object.values(
              props.memberBalance(props.currentMember.blockchain_wallet_id)
            )
              .filter(
                ({ accountType, balance }) =>
                  ![
                    "savings",
                    "total_loan_interest",
                    "paid_loan_interest",
                  ].includes(accountType) && balance > 0
              )
              .sort((a, b) =>
                a.balance > b.balance ? 0 : b.balance > a.balance ? 1 : -1
              )
              .map((bal) => (
                <ListGroupItem
                  key={uuid.v4()}
                  className="textStyle"
                  style={loadingStyle}
                >
                  {formatStr(txnTypes[lang][bal.accountType])} :{" "}
                  {localStorage.getItem("lang") === "ARABIC"
                    ? intToArabic(parseFloat(bal.balance).toFixed(2))
                    : parseFloat(bal.balance).toFixed(2)}{" "}
                  {!["ARABIC", "LEBANESE"].includes(
                    localStorage.getItem("lang")
                  ) && getCurrency(props.group[0].country).symbol}
                  {["ARABIC", "LEBANESE"].includes(
                    localStorage.getItem("lang")
                  ) &&
                    (props.vsla.metadata.currency === "USD"
                      ? getCurrency(props.group[0].country).usd_symbol
                      : getCurrency(props.group[0].country).lbp_symbol)}
                </ListGroupItem>
              ))}
            {!["ARABIC", "LEBANESE"].includes(localStorage.getItem("lang")) && (
              <ListGroupItem className="textStyle" style={loadingStyle}>
                <Translate content="mem_overview.shares_in_numbers" />
                {"\n"}
                {getTotalShares() === 0
                  ? "0"
                  : parseFloat(
                      getTotalShares() /
                        parseFloat(props.vsla.metadata.share_value)
                    ).toFixed(2)}
              </ListGroupItem>
            )}
            <ListGroupItem className="textStyle" style={loadingStyle}>
              <Translate content="span.member_role" /> :{" "}
              {props.memberDetails.member_role}
            </ListGroupItem>
            {props.currentMember.blockchain_address && (
              <ListGroupItem className="textStyle" style={loadingStyle}>
                {getTranslation("span", "bc_wallet")} :{" "}
                <a
                  href={`${window.REACT_APP_STELLAR_URL}/${props.currentMember.blockchain_address}`}
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    paddingRight: "15px",
                    color: "#E36F1E",
                  }}
                  rel="noopener noreferrer"
                >
                  {getTranslation("span", "link")}
                </a>
                <FontAwesomeIcon
                  style={{
                    fontSize: "20px",
                    color: "#E36F1E",
                  }}
                  icon={faExternalLinkAlt}
                />
              </ListGroupItem>
            )}
          </ListGroup>
        </Container>
      </div>
    );
  }
};

const txnTypes = {
  ARABIC: {
    welfare: "مجموع مساهمات الرعاية",
    welfareContribution: " المساهمة في الرعاية",
    welfareContrib: "المساهمات في الرعاية",
    finePaid: "دفع الغرامة",
    fine: "الغرامات",
    membershipPaid: "دفع رسوم العضوية",
    shareBought: "إضافة المدخرات",
    loanWriteOff: "شطب القرض",
    shares: "المدخرات",
    loan: "",
    loan_interest: "",
    loanRepaid: "سداد القرض",
    LoanTaken: "الاستحصال على القرض",
    shareSold: "سحب المدخرات",
    welfareReq: "طلبات الرعاية",
    welfareRequest: "طلب الرعاية",
    membershipReversed: "إلغاء رسوم العضوية",
    fineReversed: "إلغاء غرامة",
    welfareRequestR: "إلغاء طلب الرعاية",
    otherIncome: "المداخيل الأخرى",
    loanRepayR: "إلغاء سداد القرض",
    sharesBoughtR: "إلغاء إضافة المدخرات",
    sharesSoldR: "إلغاء سحب المدخرات",
    writeoffReversed: "إلغاء شطب القرض",
    otherExpenses: "النفقات الأخرى",
    membershipcashout: "قبض رسوم العضوية",
    shareSoldCashOut: "قبض – سحب المدخرات",
    savingscashout: "قبض المدخرات",
    finecashout: "قبض غرامة",
    welfarecashout: "قبض الرعاية",
    chargeaccrued: " رسوم الخدمة المستحقة",
    loanBalance: "رصيد القرض",
    sharesBalance: "رصيد المدخرات",
    membership: "العضوية",
  },
  LEBANESE: {
    welfareContribution: "Welfare contribution",
    welfareContrib: "Welfare contributions",
    finePaid: "Fine paid",
    fine: "Fines",
    membershipPaid: "Membership paid",
    shareBought: "Savings added",
    loanWriteOff: "Loan write-off",
    shares: "Savings deposited",
    loan: "Unpaid Loan",
    loan_interest: "Unpaid loan interest",
    loanRepaid: "Loan repaid",
    LoanTaken: "Loan taken",
    shareSold: "Savings withdrawn",
    welfareReq: "Welfare requests",
    welfareRequest: "Welfare request",
    membershipReversed: "Membership fee reversed",
    fineReversed: "Fine reversed",
    welfareRequestR: "Welfare request reversed",
    otherIncome: "Group income",
    loanRepayR: "Loan repayment reversed",
    sharesBoughtR: "Add savings reversed",
    sharesSoldR: "Savings withdrawal reversed",
    writeoffReversed: "Loan write-off reversed",
    otherExpenses: "Group expenses",
    membershipcashout: "Membership cash out",
    shareSoldCashOut: "Savings withdrawal - cash out",
    savingscashout: "Savings cash out",
    finecashout: "Fine cash out",
    welfarecashout: "Welfare cash out",
    chargeaccrued: "Service charge accrued",
    loanBalance: "Loan balance",
    sharesBalance: "Savings balance",
    membership: "Membership",
  },
  PORTUGUESE: {
    welfareContribution: "Contribuição social",
    welfareContrib: "Contribuições sociais",
    welfare: "Contribuições totais de bem-estar",
    finePaid: "Multa paga",
    "Fine paid": "Multa paga",
    fine: "Multas",
    membershipPaid: "Taxa de membro paga",
    shareBought: "Poupanças adicionadas",
    shares: "Poupanças adicionadas",
    loanWriteOff: "Empréstimo eliminado",
    loanRepaid: "Empréstimo pago",
    LoanTaken: "Empréstimo tomado",
    loan: "Empréstimo não pago",
    loan_interest: "Juros de empréstimo não pagos",
    shareSold: "Poupanças retiradas",
    welfareReq: "Pedidos sociais",
    welfare_requests: "Totais Pedido Social",
    welfareRequest: "Pedido social",
    membershipReversed: "Taxa de membro revertida",
    fineReversed: "Multa revertida",
    welfareRequestR: "Pedido social revertido",
    otherIncome: "Outros Rendimentos",
    loanRepayR: "Pagamento do empréstimo revertido",
    sharesBoughtR: "Adicionar poupanças revertidas",
    sharesSoldR: "Retirada de poupança revertida",
    writeoffReversed: "Eliminação de empréstimo revertida",
    otherExpenses: "Outras despesas",
    membershipcashout: "Saque de taxa de membros",
    shareSoldCashOut: "Saque de Retirada de poupança",
    savingscashout: "Saque de poupanças",
    finecashout: "Saque de Multas",
    welfarecashout: "Saque de fundo social",
    chargeaccrued: "Taxa de serviço acumulada",
    loanBalance: "Saldo do empréstimo",
    sharesBalance: "Saldo de poupanças",
    membership: "Filiação",
  },
  ENGLISH: {
    welfareContribution: "Welfare contribution",
    welfareContrib: "Welfare contributions",
    welfare: "Total welfare contributions",
    finePaid: "Fine paid",
    "Fine paid": "Fine paid",
    fine: "Fines paid",
    membership: "Membership fee paid",
    membershipPaid: "Membership paid",
    shareBought: "Shares bought",
    shares: "Shares bought",
    loanWriteOff: "Loan write-off",
    loanRepaid: "Loan repaid",
    LoanTaken: "Loan taken",
    loan: "Unpaid Loan",
    loan_interest: "Unpaid loan interest",
    shareSold: "Shares sold",
    welfareReq: "Welfare requests",
    welfare_requests: "Total welfare requests",
    welfareRequest: "Welfare request",
    membershipReversed: "Membership reversed",
    fineReversed: "Fine reversed",
    welfareRequestR: "Welfare request reversed",
    otherIncome: "Group income",
    loanRepayR: "Loan repayment reversed",
    sharesBoughtR: "Shares bought reversed",
    sharesSoldR: "Shares sold reversed",
    writeoffReversed: "Write-off reversed",
    otherExpenses: "Group expenses",
    membershipcashout: "Membership cash out",
    shareSoldCashOut: "Shares sold - cash out",
    savingscashout: "Savings cash out",
    finecashout: "Fine cash out",
    welfarecashout: "Welfare cash out",
    chargeaccrued: "Service charge accrued",
    loanBalance: "Loan balance",
    sharesBalance: "Shares balance",
  },
  FRANÇAIS: {
    welfareContribution: "Welfare contribution",
    welfareContrib: "Welfare contributions",
    finePaid: "Fine paid",
    fine: "Fines",
    membershipPaid: "Membership paid",
    shareBought: "Shares bought",
    loanWriteOff: "Loan write-off",
    loanRepaid: "Loan repaid",
    LoanTaken: "Loan taken",
    shareSold: "Shares sold",
    welfareReq: "Welfare requests",
    welfareRequest: "Welfare request",
    membershipReversed: "Membership reversed",
    fineReversed: "Fine reversed",
    welfareRequestR: "Welfare request reversed",
    otherIncome: "Group income",
    loanRepayR: "Loan repayment reversed",
    sharesBoughtR: "Shares bought reversed",
    sharesSoldR: "Shares sold reversed",
    writeoffReversed: "Write-off reversed",
    otherExpenses: "Group expenses",
    membershipcashout: "Membership cash out",
    shareSoldCashOut: "Shares sold - cash out",
    savingscashout: "Savings cash out",
    finecashout: "Fine cash out",
    welfarecashout: "Welfare cash out",
    chargeaccrued: "Service charge accrued",
    loanBalance: "Loan balance",
    sharesBalance: "Shares balance",
    membership: "Membership",
  },
  SWAHILI: {
    welfareContribution: "Welfare contribution",
    welfareContrib: "Welfare contributions",
    finePaid: "Fine paid",
    fine: "Fines",
    membershipPaid: "Membership paid",
    shareBought: "Shares bought",
    loanWriteOff: "Loan write-off",
    loanRepaid: "Loan repaid",
    LoanTaken: "Loan taken",
    shareSold: "Shares sold",
    welfareRequest: "Welfare request",
    welfareReq: "Welfare requests",
    membershipReversed: "Membership reversed",
    fineReversed: "Fine reversed",
    welfareRequestR: "Welfare request reversed",
    otherIncome: "Group income",
    loanRepayR: "Loan repayment reversed",
    sharesBoughtR: "Shares bought reversed",
    sharesSoldR: "Shares sold reversed",
    writeoffReversed: "Write-off reversed",
    otherExpenses: "Group expenses",
    membershipcashout: "Membership cash out",
    shareSoldCashOut: "Shares sold - cash out",
    savingscashout: "Savings cash out",
    finecashout: "Fine cash out",
    welfarecashout: "Welfare cash out",
    chargeaccrued: "Service charge accrued",
    loanBalance: "Loan balance",
    sharesBalance: "Shares balance",
    membership: "Membership",
  },
  SPANISH: {
    welfareContribution: "Contribución al Fondo Social",
    welfareContrib: "Contribución al Fondo Social",
    finePaid: "Multa pagada",
    fine: "Multa",
    membershipPaid: "Membresía Pagada",
    shareBought: "Acciones compradas",
    loanWriteOff: "Cancelación de préstamos",
    loanRepaid: "Préstamo Reembolsado",
    LoanTaken: "Préstamo tomado",
    shareSold: "Acciones Vendidas",
    welfareRequest: "Solicitud de fondos de Fondo Social",
    welfareReq: "Solicitud de fondos de Fondo Social",
    membershipReversed: "Membresía Revertida",
    fineReversed: "Multa Revertida",
    welfareRequestR: "Solicitud de fondos de Fondo Social Revertido",
    otherIncome: "Otros ingresos",
    loanRepayR: "Reembolso del Préstamo Revertido",
    sharesBoughtR: "Acciones Compradas Revertidas",
    sharesSoldR: "Acciones Vendidas Revertidas",
    writeoffReversed: "Cancelación Revertida",
    otherExpenses: "Otros Gastos",
    membershipcashout: "Membresía - : retiro de efectivo",
    shareSoldCashOut: "Acciones Vendidas - retiro de efectivo",
    savingscashout: "Ahorros - retiro de efectivo",
    finecashout: "Multa - retiro de efectivo",
    welfarecashout: "Fondo Social - retiro de efectivo",
    chargeaccrued: "Cargo por servicio acumulado",
    loanBalance: "Saldo de Préstamo",
    sharesBalance: "Saldo de acciones",
    membership: "Membresía",
  },
};

const mapDispatchToProps = (dispatch) => ({
  getBalance: (id) => dispatch(getMemberBalanceByMemberId(id)),
});

const mapStateToProps = (state) => {
  return {
    isloading: state.vslaIsLoading,
    vsla: state.vsla,
    isConnected: state.isConnected,
    currentMember: state.loadedMember,
    loadedvslaMembers: state.loadedvslaMembers,
    group: state.loadedvslas,
    error: state.vslaHasErrored,
    pendingTransactions: state.pendingTransactions,
    memberBalance: (id) => state.account.members[id],
    memberDetails: state.loadedvslaMembers.filter(
      ({ blockchain_wallet_id }) =>
        blockchain_wallet_id === state.loadedMember.blockchain_wallet_id
    )[0],
    membersSaving: selectMemberSharesFund(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberOverview);
