// import { backendAPI } from "../../api";

export const memberLoadStartedHOL = (bool) => ({
  type: "MEM_HOL_LOAD_START",
  isloading: bool,
});

export const memberLoadedHOL = (member) => ({
  type: "LOADED_HOL_MEMBER",
  member,
});

export const memberLoadHOLError = (error) => ({
  type: "MEM_HOL_LOAD_ERROR",
  error: error,
});

// export function fetchMemberInfo(headers) {
//   return async function (dispatch, getState) {
//     dispatch(memberLoadStartedHOL(true));
//     try {
//       const pubkey = getState().loadedMember.pubkey;
//       const url = `/api/v1/members/${pubkey}`;
//       const res = await backendAPI.get(url, headers);
//       dispatch(memberLoadStartedHOL(false));
//       dispatch(memberLoadedHOL(res.data));
//       return Promise.resolve();
//     } catch (error) {
//       dispatch(memberLoadHOLError(error));
//       dispatch(memberLoadStartedHOL(false));
//       return Promise.reject();
//     }
//   };
// }
