import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Alert } from "reactstrap";
import MemberItem from "../members/MemberItem";
import counterpart from "counterpart";
import Translate from "react-translate-component";
import ZARMA from "../../view/shared/lang/ZARMA";
import ENGLISH from "../../view/shared/lang/ENGLISH";
import SWAHILI from "../../view/shared/lang/SWAHILI";
import HAOUSSA from "../../view/shared/lang/HAOUSSA";
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS";
import SPANISH from "../../view/shared/lang/SPANISH";
import { connect } from "react-redux";
import LEBANESE from "../../view/shared/lang/LEBANESE";
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE";
import ARABIC from "../../view/shared/lang/ARABIC";
import { getTranslation } from "../../view/shared/helpers";
import { setCurrentPage } from "../../store/reducers/navigation";
import { selectCreatedVslaInfo } from "../../store/reducers/getVslas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StellarLoader from "../layout/StellarLoader";
import { useLiveQuery } from "dexie-react-hooks";
import { db } from "../../view/shared/utilities/cache";
import SearchBar from './searchBar';
counterpart.registerTranslations("ARABIC", ARABIC);

counterpart.registerTranslations("PORTUGUESE", PORTUGUESE);
counterpart.registerTranslations("LEBANESE", LEBANESE);
counterpart.registerTranslations("ZARMA", ZARMA);
counterpart.registerTranslations("SWAHILI", SWAHILI);
counterpart.registerTranslations("ENGLISH", ENGLISH);
counterpart.registerTranslations("HAOUSSA", HAOUSSA);
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS);
counterpart.registerTranslations("SPANISH", SPANISH);

function MemberList(props) {
  counterpart.setLocale(localStorage.getItem("lang"));
  const id = props.id;
  const { members, pendingTransactions, isLoading, vsla } = useSelector(
    (state) => ({
      vsla: state.vsla,
      members: state.loadedvslaMembers,
      pendingTransactions: state.pendingTransactions,
      isLoading: state.vlsaMembersLoadingStarted,
    })
  );
  const [cachedIds, setCachedIds] = React.useState([]);

  const cachedMembers = useLiveQuery(() => {
    try {
      return db.members.filter(({ vslaId }) => vslaId === vsla.id).toArray();
    } catch (error) {
      console.error(error);
      return [];
    }
  }, []);

  const [processedData, setMembers] = React.useState([]);
  const [filterMembers, setFilterMembers] = React.useState([])
  const dispatch = useDispatch();

  const [showTransactionStatusBtn, setShowTransactionStatusBtn] =
    React.useState(true);

  const [offlineMeeting, setOfflineMeeting] = React.useState(null);

  const cachedMeeting = useLiveQuery(() => {
    try {
      return db.offlineMeeting
        .filter(({ vslaId }) => vslaId === vsla.id)
        .toArray();
    } catch (error) {
      console.log(`Error: ${error}`);
      return [];
    }
  }, []);

  React.useEffect(() => {
    if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
      return;
    }
    setOfflineMeeting({
      ...cachedMeeting[0].data,
      cacheId: cachedMeeting[0].cacheId,
    });
  }, [cachedMeeting]);

  React.useEffect(() => {
    if (
      props.isConnected &&
      (props.onGoingMeetings.length ||
        (offlineMeeting &&
          offlineMeeting.id &&
          offlineMeeting.status === "ongoing"))
    ) {
      setShowTransactionStatusBtn(true);
      return;
    }
    setShowTransactionStatusBtn(false);
  }, [props.isConnected, props.onGoingMeetings, offlineMeeting]);

  React.useEffect(() => {
    // Combine members and cachedMembers into a single array
    const allItems = [...members, ...(cachedMembers || [])];
    // Create a map to ensure unique members based on pubkey
    const uniqueMembersMap = new Map();
    allItems.forEach((item) => {
      // Destructure necessary properties from item
      const { cacheId, data, error } = item;
      // If data exists, merge data with error and cacheId; otherwise, use item directly
      const member = data
        ? { ...data, error, cacheId }
        : { ...item, error, cacheId };

      // If the member is not already in the map or has an error, add/overwrite in the map
      if (!uniqueMembersMap.has(member.pubkey) || member.error) {
        uniqueMembersMap.set(member.pubkey, member);
      }
    });
    // Convert the map values to an array and update the state
    setMembers(Array.from(uniqueMembersMap.values()));
  }, [members, cachedMembers]);

  React.useEffect(() => {
    if (!Array.isArray(cachedMembers)) {
      return;
    }
    setCachedIds(cachedMembers.map(({ data }) => data.pubkey));
  }, [cachedMembers]);

  const goToTransactionStatuses = () => {
    dispatch(setCurrentPage("group-meetingTransactions"));
  };

  const [hasFailedTransactions, setHasFailedTransactions] =
    React.useState(false);
  React.useEffect(() => {
    if (!props.onGoingMeetings.length) {
      return;
    }
    setHasFailedTransactions(
      props.meetingTransactions.filter(
        (item) => item.status.toLocaleLowerCase() === "failed"
      ).length > 0
    );
  }, [pendingTransactions]);

  React.useEffect(()=>{
    setFilterMembers(processedData)
  }, [processedData])

  const headerStyle = {
    color: "#E36F1E",
    fontFamily: "quicksand",
    fontWeight: "bolder",
    fontSize: "1.8em",
    textAlign: "center",
    paddingBottom: "0.5em",
  };

  const buttonStyle = {
    position: "fixed",
    color: "#fff",
    left: "50%",
    top: "85%",
    transform: "translate(-50%, -50%)",
    fontFamily: "Quicksand",
    fontSize: "20px",
    fontWeight: 600,
    filter: "drop-shadow(0px 8px 16px rgba(0,0,0,0.15))",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 16px",
    width: "222px",
    height: localStorage.getItem("lang") === "PORTUGUESE" ? "55px" : "46px",
    background: "#E36F1E",
    borderRadius: "4.8px",
    flex: "none",
    border: "0",
    flexGrow: "0",
    textTransform: "capitalize",
  };

  if (isLoading) {
    return <StellarLoader />;
  }

  if (!processedData.length) {
    return (
      <Container>
        <Alert
          color="warning"
          style={{
            textAlign:
              localStorage.getItem("lang") === "ARABIC" ? "right" : "initial",
          }}
        >
          <span>
            <Translate
              content="alert.no_members"
              style={{ fontSize: "16px", fontFamily: "Fira Sans" }}
            />
          </span>
        </Alert>
      </Container>
    );
  }

  const secretary = filterMembers.find(
    (member) => member && member.member_role.toLowerCase() === "secretary"
  );

  const isSecretaryPending = () => {
    return (
      (secretary.processing_status &&
        secretary.processing_status.toLocaleLowerCase() === "pending") ||
      (!secretary.blockchain_wallet_id && !secretary.processing_status) ||
      (cachedIds.includes(secretary.pubkey) && !secretary.error)
    );
  };
  const isSecretaryFailed = () => {
    return (
      !!secretary.error ||
      (secretary.processing_status &&
        secretary.processing_status.toLocaleLowerCase() === "failed")
    );
  };

  return (
    <div>
    <SearchBar setter={setFilterMembers} data={processedData}/>
      <Container style={{ marginBottom: "140px" }}>
        <div>
          <br />
          <Translate
            content="title_copy.memberlist_overview"
            component="h4"
            style={headerStyle}
          />
        </div>
        {secretary && (
          <>
            {isSecretaryPending() ? (
              <div className="alert alert-info">
                {getTranslation("placeholder_copy", "secretary_creation_msg")}
              </div>
            ) : (
              isSecretaryFailed() && (
                <div className="alert alert-danger">
                  {getTranslation(
                    "placeholder_copy",
                    "secretary_creation_failed_msg"
                  )}
                </div>
              )
            )}
          </>
        )}
        <div key={filterMembers.length}>
          {filterMembers.map((member) => {
            return (
              member && (
                <MemberItem
                  isFailed={
                    !!member.error ||
                    (member.processing_status &&
                      member.processing_status.toLocaleLowerCase() === "failed")
                  }
                  isPending={
                    (cachedIds.includes(member.pubkey) && !member.error) ||
                    (member.processing_status &&
                      member.processing_status.toLocaleLowerCase() ===
                        "pending") ||
                    /*
                      Use Case:
                      When a member is a secretary and lacks a processing status and blockchain wallet id,
                      it indicates that the member is being created on the backend.
                      In this scenario, the response will be received through WebSocket.
                    */
                    (member.member_role.toLocaleLowerCase() === "secretary" &&
                      !member.processing_status &&
                      !member.blockchain_wallet_id)
                  }
                  members={members}
                  key={member.pubkey}
                  cacheKey={member.cacheId}
                  member_details={member}
                  vsla_id={id}
                />
              )
            );
          })}
          {filterMembers.length === 0 && processedData.length > 0 ? <span style={{
            fontWeight: '600'
          }}>
            {getTranslation('placeholder_copy','search_no_result_message')}
          </span>: <></>}
        </div>
        {showTransactionStatusBtn && (
          <button onClick={goToTransactionStatuses} style={buttonStyle}>
            {getTranslation("span", "transaction_status")}
            {hasFailedTransactions && (
              <FontAwesomeIcon
                style={{
                  position: "absolute",
                  top: "-15px",
                  color: "red",
                  right: 0,
                }}
                icon="exclamation-circle"
              />
            )}
          </button>
        )}
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isConnected: state.isConnected,
  onGoingMeetings: state.currentOngoingMeeting,
  vslaSummary: selectCreatedVslaInfo(state),
  meetingTransactions: state.meetingTransactions,
});
export default connect(mapStateToProps, null)(MemberList);
