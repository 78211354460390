import React from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { useDispatch } from "react-redux";
import { deleteCacheByKey } from "../../view/shared/utilities/cache";
import { getTranslation } from "../../view/shared/helpers";

const DropMenu = (props) => {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const dispatch = useDispatch();
  const toggle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownOpen((prevState) => !prevState);
  };

  const remove = () => {
    deleteCacheByKey(props.namespace, "cacheId", props.cacheKey);
  };

  const retry = () => {
    try {
      dispatch(props.retryFunc(props.namespace, props.cacheKey, props.member));
      setDisabled(true);
    } catch (error) {
      console.log("Error: ", error);
      setDisabled(false);
    }
  };

  return (<>{
    props.member.member_role.toLocaleLowerCase() === "secretary" ?
    <button
    style={{
      margin: "4px 0",
      background: "#E36F1E",
      borderRadius: "4.8px",
      borderWidth: 0,
      fontWeight: 600,
      color: "#fff",
      padding: "8px",
      width: "80px",
      opacity: disabled ? "0.4" : "1",
    }}
    disabled={disabled}
    onClick={retry}
  >
    {getTranslation("placeholder_copy", "retry")}
  </button>:
  <Dropdown direction="end" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
        style={{
          color: "#000",
          fontSize: "15px",
          fontWeight: 600,
          cursor: "pointer",
        }}
      >
        &#8942;
      </DropdownToggle>
      <DropdownMenu
        style={{
          minWidth: "fit-content",
          padding: "8px",
          fontSize: 13,
          cursor: "pointer",
        }}
      >
        <button
          style={{
            margin: "4px 0",
            background: "#E36F1E",
            borderRadius: "4.8px",
            borderWidth: 0,
            fontWeight: 600,
            color: "#fff",
            padding: "8px",
            width: "80px",
            opacity: disabled ? "0.4" : "1",
          }}
          disabled={disabled}
          onClick={retry}
        >
          {getTranslation("placeholder_copy", "retry")}
        </button>
        {props.member.member_role.toLocaleLowerCase() !== "secretary" && (
          <button
            style={{
              padding: "8px",
              fontWeight: 600,
              borderWidth: 0,
              background: "red",
              borderRadius: "4.8px",
              marginTop: 10,
              color: "#fff",
              width: "80px",
              display: props.showDelete ? "inline" : "none",
            }}
            onClick={remove}
          >
            
            {getTranslation("placeholder_copy", "remove")}
          </button>
        )}
      </DropdownMenu>
    </Dropdown>
  }
  </>
  );
};

export default DropMenu;
