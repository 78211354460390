export function loadedMemberHOL(state = [], action) {
  switch (action.type) {
    case "LOADED_HOL_MEMBER":
      return action.member;
    default:
      return state;
  }
}

export function memberLoadingStartedHOL(state = false, action) {
  switch (action.type) {
    case "MEM_HOL_LOAD_START":
      return action.isloading;
    default:
      return state;
  }
}

export function memberLoadingHOLError(state = [], action) {
  switch (action.type) {
    case "MEM_HOL_LOAD_ERROR":
      return action.error;
    default:
      return state;
  }
}
