import React from "react";
import counterpart from "counterpart";
import validateInput from "../../view/shared/validations/validateShares";
import { WarningMessage } from "../../view/shared/notifications/Notifications";
import Translate from "react-translate-component";
import ZARMA from "../../view/shared/lang/ZARMA";
import ENGLISH from "../../view/shared/lang/ENGLISH";
import SWAHILI from "../../view/shared/lang/SWAHILI";
import HAOUSSA from "../../view/shared/lang/HAOUSSA";
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS";
import SPANISH from "../../view/shared/lang/SPANISH";
import { FormInput } from "./formComponents/FormInput";
import { FormTitle } from "./formComponents/FormTitle";
import { FormButton } from "./formComponents/FormButton";
import { Form, Container, FormGroup, Label } from "reactstrap";
import { connect } from "react-redux";
import stellarProcessing from "../../view/assets/stellar-animation.gif";
import { toast } from "react-toastify";
import { selectGroupWelfareBalance } from "../../store/reducers/getVslaBalance";
import { DisplayOutput } from "./formComponents/DisplayOutput";
import LastSharesSold from "../reverseTransactions/LastSharesSold";
import { currentTransactionContext } from "../../store/actions/transactionContext";
import { initiateTransaction } from "../../store/reducers/transactions/actions";
import LEBANESE from "../../view/shared/lang/LEBANESE";
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE";
import ARABIC from "../../view/shared/lang/ARABIC";
import { selectCreatedVslaInfo } from "../../store/reducers/getVslas";
import { setCurrentPage } from "../../store/reducers/navigation";
import {
  arabicToInt,
  intToArabic,
} from "../../view/shared/utilities/arabicNumParser";
import { getTranslation } from "../../view/shared/helpers";
import { db } from "../../view/shared/utilities/cache";
import { useLiveQuery } from "dexie-react-hooks";

counterpart.registerTranslations("ARABIC", ARABIC);
counterpart.registerTranslations("PORTUGUESE", PORTUGUESE);
counterpart.registerTranslations("LEBANESE", LEBANESE);
counterpart.registerTranslations("ZARMA", ZARMA);
counterpart.registerTranslations("SWAHILI", SWAHILI);
counterpart.registerTranslations("ENGLISH", ENGLISH);
counterpart.registerTranslations("HAOUSSA", HAOUSSA);
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS);
counterpart.registerTranslations("SPANISH", SPANISH);

const notify = (msg) => toast(msg);
const spinnerStyle = {
  height: "90%",
  width: "90%",
};

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "20px",
};

const headerStyle = {
  color: "#E36F1E",
  fontFamily: "quicksand",
  fontWeight: "bolder",
  fontSize: "1.8em",
  textAlign: "center",
  paddingBottom: "0.5em",
};

const infoStyle = {
  padding: ".3rem",
  textAlign: "center",
  fontFamily: "Fira Sans",
  fontSize: "20px",
  color: "#696969",
  align: "inline-block",
};

const spanStyle = {
  textAlign: "center",
  fontFamily: "Fira Sans",
  fontSize: "20px",
  color: "#696969",
  align: "inline-block",
};
const SellShares = (props) => {

  const cachedMeeting = useLiveQuery(async () => {
    try {
      const items = await db.offlineMeeting.toArray();
      return items.filter(({vslaId})=>vslaId===props.vsla.id);
    } catch (error) {
      console.log(`Error: ${error}`);
      return [];
    }
  }, []);

  const [offlineMeeting, setOfflineMeeting] = React.useState(null);

  React.useEffect(()=> {
  if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
    setOfflineMeeting(null);
    return
  }
  setOfflineMeeting(cachedMeeting[0])
  }, []);

  React.useEffect(()=> {
  if (!Array.isArray(cachedMeeting) || !cachedMeeting.length) {
    setOfflineMeeting(null);
    return
  }
  setOfflineMeeting(cachedMeeting[0])
  }, [cachedMeeting]);

  React.useEffect(()=> {
    counterpart.setLocale(localStorage.getItem("lang"));
    props.pushTransactionContext("Shares sold");
  }, []);

  const [state, setState] = React.useState({
    number_of_shares: "",
    errors: {},
    loading: false,
    omt_receipt_number: "",
  });

  const onChange = (e) => setState({ ...state, [e.target.name]: e.target.value });

  const isValid = () => {
    const { errors, isValid } = validateInput(state);

    if (!isValid) {
      setState({ ...state, errors });
    }
    return isValid;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if (isValid()) {
      processTransaction(
        arabicToInt(state.number_of_shares),
        state.omt_receipt_number
      );
      setState({ ...state, number_of_shares: "", omt_receipt_number: "" });
    }
  };

  const processTransaction = (number_of_shares, omt_receipt_number) => {
    const lang_code = localStorage.getItem("lang");

    const lang = {
      FRANÇAIS: {
        message: `Confirmer la saisie de ${state.number_of_shares} actions pour le membre`,
      },
      ENGLISH: {
        message: `Confirm selling of ${state.number_of_shares} shares for member`,
      },
      SWAHILI: {
        message: `Thibitisha mwanachama anauza hisa ${state.number_of_shares}`,
      },
      ZARMA: {
        message: `Zarma for Confirm selling of ${state.number_of_shares} shares for member`,
      },
      HAOUSSA: {
        message: `Haoussa for Confirm selling of ${state.number_of_shares} shares for member`,
      },
      SPANISH: {
        message: `Confirmar venta de ${state.number_of_shares} acciones para miembro`,
      },
      LEBANESE: {
        message: `Confirm withdrawal of ${state.number_of_shares} savings for household`,
      },
      PORTUGUESE: {
        message: `Confirme a venda de ${state.number_of_shares} ações para o membro`,
      },
      ARABIC: {
        message: `أكد سحب ${state.number_of_shares} من المدخرات للأسرة`,
      },
    };

    if (window.confirm(lang[lang_code].message)) {
      setState({ ...state, loading: true });
      const uuid = props.currentMember.pubkey;
      const share_value = props.vsla.metadata.share_value;
      const amount = (number_of_shares * share_value).toString();
      props.commitTransaction(
        {
          uid: uuid,
          meetingId: props.ongoingMeeting.length
            ? props.ongoingMeeting[0].id
            : offlineMeeting.data.id,
          type: "SELL_SHARES",
          groupId: props.group[0].blockchain_key,
          memberId: props.currentMember.blockchain_wallet_id,
          sharePrice: 1,
          amount: parseFloat(amount),
          metadata: {
            omtReceiptNumber: omt_receipt_number,
          },
        },
        offlineMeeting
      );
      notify(<WarningMessage msg={"notifications.processing"} />);
      props.setPage("group-memberList");
    } else {
      notify(<WarningMessage />);
    }
  };

  const redirectBack = () => {
    props.setPage("group-memberList");
  };

  if (!state.loading) {
    return (
      <Container>
        <div>
          <h4 style={headerStyle}>{props.currentMember.full_name}</h4>
        </div>
        <FormTitle style={headerStyle} title="title_copy.sell_shares" />
        <DisplayOutput title="mem_overview.shares_total" style={infoStyle} />
        <p style={spanStyle}>
          {localStorage.getItem("lang") === "ARABIC"
            ? intToArabic(
                parseFloat(
                  props.membersShares(
                    props.currentMember.blockchain_wallet_id
                  )
                ).toFixed(2)
              )
            : parseFloat(
                props.membersShares(
                  props.currentMember.blockchain_wallet_id
                )
              ).toFixed(2)}
        </p>
        <Form onSubmit={onSubmit}>
          <Container>
            <span style={{ color: "red" }}>{state.errors.amount}</span>
            {localStorage.getItem("lang") !== "ARABIC" && (
              <FormGroup>
                {["ARABIC", "LEBANESE"].includes(
                  localStorage.getItem("lang")
                ) && <Label style={loadingStyle}>{getTranslation("placeholder_copy", "savings_withdrawal")}</Label>}
                <FormInput
                  type="number"
                  name="number_of_shares"
                  placeholder={getTranslation("placeholder_copy", "number_of_shares")}
                  value={state.number_of_shares}
                  onChange={onChange}
                  className="form-control"
                />
              </FormGroup>
            )}
            {localStorage.getItem("lang") === "ARABIC" && (
              <FormGroup>
                <Label style={loadingStyle}>{getTranslation("placeholder_copy", "savings_withdrawal")}</Label>
                <FormInput
                  type="number"
                  name="number_of_shares"
                  placeholder={getTranslation("placeholder_copy", "number_of_shares")}
                  value={state.number_of_shares}
                  onChange={onChange}
                  className="form-control"
                />
              </FormGroup>
            )}
            {["ARABIC", "LEBANESE"].includes(
              localStorage.getItem("lang")
            ) && (
              <FormGroup>
                <Label style={loadingStyle}>{getTranslation("placeholder_copy", "omt_receipt_number")}</Label>
                <FormInput
                  type="text"
                  name="omt_receipt_number"
                  placeholder={getTranslation("placeholder_copy", "enter_recipt_number")}
                  value={state.omt_receipt_number}
                  onChange={onChange}
                  className="form-control"
                />
              </FormGroup>
            )}
            {parseFloat(
              parseFloat(
                props.membersShares(
                  props.currentMember.blockchain_wallet_id
                )
              )
            ) < parseFloat(state.number_of_shares) && (
              <span style={{ color: "red" }}>
                <Translate content="notifications.share_amount_error" />
              </span>
            )}
            <FormButton
              type={"submit"}
              value="placeholder_copy.sell_shares"
              name="submit"
              className="btn btn-lg form-control"
              disabled={
                parseFloat(
                  props.membersShares(
                    props.currentMember.blockchain_wallet_id
                  )
                ) < parseFloat(state.number_of_shares)
                  ? true
                  : false
              }
            />
          </Container>
        </Form>
        {props.isConnected &&
        <LastSharesSold
          offlineMeeting={props.offlineMeeting}
          redirectBack={redirectBack}
          reversal_details={{
            sourceFundType: "issuer",
            reversalTransactionType: "Shares sold reversed",
          }}
          transactions={props.transactions.filter(
            ({ memberId, type }) =>
              memberId === props.currentMember.blockchain_wallet_id &&
              type === "SELL_SHARES"
          )}
        />}
      </Container>
    );
  }

  return (
    <div>
      <center>
        <img src={stellarProcessing} style={spinnerStyle} alt="Stellar" />
      </center>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isConnected: state.isConnected,
    vslaSummary: selectCreatedVslaInfo(state),
    ongoingMeeting: state.currentOngoingMeeting,
    currentMember: state.loadedMember,
    welfareBalance: selectGroupWelfareBalance(state),
    membersShares: (id) =>
      state.account.members[id].shares.balance === 0
        ? 0
        : parseFloat(
            state.account.members[id].shares.balance /
              state.vsla.metadata.share_value
          ),
    vsla: state.vsla,
    group: state.loadedvslas,
    transactions: state.loadedMeetingTransaction.filter(
      ({ type }) => type === "SELL_SHARES"
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPage: (page) => dispatch(setCurrentPage(page)),
    commitTransaction: (data, meeting) =>
      dispatch(initiateTransaction(data, meeting)),
    pushTransactionContext: (type) => dispatch(currentTransactionContext(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SellShares);
