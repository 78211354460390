import { triggerAction } from "../../constants";
import { selectCreatedVslaInfo } from "../../reducers/getVslas";
import { backendAPI, transactionAPI } from "../../api";
import { patchMeeting } from "../../actions/meetings/createMeeting";
import { fetchMeeting } from "../../actions/meetings/getMeeting";
import {
  deleteCacheByKey,
} from "../../../view/shared/utilities/cache";

const EIGHT_HOURS_IN_MILLISECONDS = 2.88e+7;
export const fetchActiveMeetingTransactions =
  (meetingId) => async (dispatch, getState) => {
    const { currentOngoingMeeting, user } = getState();

    const id = meetingId ? meetingId: currentOngoingMeeting.length ? currentOngoingMeeting[0].id: null;
    if (!id) {
      return Promise.reject();
    }
    try {
      const url = `/meetings/${id}`;
      const headers = {
        headers: {
          Authorization:
            "Bearer " + user.token || localStorage.getItem("token"),
        },
      };
      const res = await transactionAPI.get(url, headers);
      const parsed = res.data.sort((a, b) => new Date(b.date) - new Date(a.date))
      dispatch(triggerAction("SET_ACTIVE_MEETING_TRANSACTIONS", parsed));
      const ids = res.data.map(({reversedTransactionId})=>reversedTransactionId);
      if (ids.length){
        dispatch(triggerAction('REMOVE_REVERSED_TRANSACTIONS', ids))
      }

      if (currentOngoingMeeting.length && parsed.length) {
        const timePassed = new Date(parsed[0].transactionDate);
        const currentTime = new Date();
        const timeDiff = currentTime.getTime() - timePassed.getTime();
        const tRef = localStorage.getItem("_tRef");

        const timerId = setTimeout(function() {
          // auto close the meeting
          const temp = currentOngoingMeeting[0];
          temp.status = "completed";
          const headers = {
            headers: {
              Authorization:
                "Bearer " + localStorage.getItem("token"),
            },
          };

          const data = {
            date: new Date().toISOString(),
            status: "completed",
            request_id: temp.requestId,
            group_id: temp.group_id,
            msg: 'The meeting ended by the system',
          };
          dispatch(patchMeeting(`/api/v1/meetings/${id}`, data, headers))
            .then((res) => {
              dispatch(fetchMeeting(headers));
              localStorage.removeItem('_tRef');
            });
            // if 8 hours passed without adding a transaction close the meeting immediatly
            // otherwise close it after the time window completes 8 hours.
        },
            timeDiff > 0 && timeDiff >= EIGHT_HOURS_IN_MILLISECONDS ? // if the meeting been stale for 8 or more hours
            0: // immediately end the meeting
            EIGHT_HOURS_IN_MILLISECONDS - timeDiff // remaining time for the meeting to complete 8 hours
        );
        if (tRef) {
            // clear previous function call schedule
            clearTimeout(tRef);
        }
        // store new function ref
        localStorage.setItem("_tRef", timerId);
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  };

export const fetchPreviousMeetingsData = () => async (dispatch, getState) => {
  try {
    const { group } = selectCreatedVslaInfo(getState())[0];
    const { user } = getState();
    const { data } = await backendAPI.get(
      `/api/v1/groups/${group.id}/meetings`,
      {
        headers: {
          Authorization:
            "Bearer " + user.token || localStorage.getItem("token"),
        },
      }
    );
    dispatch(
      triggerAction(
        "SET_PREVIOUS_MEETING_DATA",
        data.sort((a, b) => new Date(b.date) - new Date(a.date))
      )
    );
    dispatch(fetchCycles());
    return Promise.resolve();
  } catch (error) {
    console.log("Error: ", error);
    return Promise.reject();
  }
};

export const fetchCycles = () => {
  return async (dispatch, getState) => {
    try {
      const { user } = getState();
      const { group } = selectCreatedVslaInfo(getState())[0];
      const res = await backendAPI.get(`/api/v1/groups/${group.id}/cycles`, {
        headers: { Authorization: "Bearer " + user.token },
      });
      dispatch(triggerAction("SET_CYCLES", res.data));
    } catch (error) {
      dispatch(triggerAction("SET_CYCLES", []));
    }
  };
};

export const selectPreviousMeeting = (item) => (dispatch) => {
  dispatch(triggerAction("SET_SELECTED_PREVIOUS_MEETING_DATA", item));
};

export const selectCycle = (item) => (dispatch) => {
  dispatch(triggerAction("SET_SELECTED_CYCLE", item));
};

export const uploadOfflineMeetings = (meeting) => async (dispatch, getState) => {
  const { vsla, user, uploadStatus } = getState();
  const cacheKey = meeting.cacheId
  delete meeting.cacheId
  try {
    if (meeting.status !== "completed" || uploadStatus.isUpLoading) {
      return;
    }
    dispatch(triggerAction("TOGGLE_IS_UPLOADING", true));
    const requestObject = {
      pushEvent: "requests",
      receiveEvent: "response",
      emit: true,
      metadata: {
        userId: vsla.id,
        cacheKey,
        callBackParams: [],
        type: "offlineMeeting",
      },
      payload: {
        requestId: "vsla.offline_meeting.new",
        url: `${window.REACT_APP_PWA_APP_DOMAIN}/api/v1/meetings/transactions`,
        headers: {
          Authorization:
            "Bearer " + user.token || localStorage.getItem("token"),
        },
        data: { ...meeting, userId: user.id },
        userId: vsla.id,
        method: "POST",
      },
    };
    dispatch(requestObject);
  } catch (error) {
    console.log("error uploading ", error);
    dispatch(triggerAction("TOGGLE_FAILED_TO_UPLOAD", true));
  }
  setTimeout(()=>{
    const cacheId = cacheKey;
    deleteCacheByKey("offlineMeeting", "cacheId", cacheId);
  }, 10000);
};
