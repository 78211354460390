import React, { useState } from "react";
import { Input, Container, Form, FormGroup, Alert } from "reactstrap";
import validateInput from "../../view/shared/validations/pinReset";
import counterpart from "counterpart";
import { toast } from "react-toastify";
import { createBrowserHistory } from "history";
import ZARMA from "../../view/shared/lang/ZARMA";
import ENGLISH from "../../view/shared/lang/ENGLISH";
import SWAHILI from "../../view/shared/lang/SWAHILI";
import HAOUSSA from "../../view/shared/lang/HAOUSSA";
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS";
import SPANISH from "../../view/shared/lang/SPANISH";
import Translate from "react-translate-component";
import LEBANESE from "../../view/shared/lang/LEBANESE";
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE";
import ARABIC from "../../view/shared/lang/ARABIC";
import { AuthAPI } from "../../store/api";

counterpart.registerTranslations("ARABIC", ARABIC);
counterpart.registerTranslations("PORTUGUESE", PORTUGUESE);
counterpart.registerTranslations("LEBANESE", LEBANESE);
counterpart.registerTranslations("ZARMA", ZARMA);
counterpart.registerTranslations("SWAHILI", SWAHILI);
counterpart.registerTranslations("ENGLISH", ENGLISH);
counterpart.registerTranslations("HAOUSSA", HAOUSSA);
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS);
counterpart.registerTranslations("SPANISH", SPANISH);

const history = createBrowserHistory({ forceRefresh: true });

const SuccessMessage = () => {
  return (
    <Translate
      content="notifications.pin_success"
      component="div"
      style={loadingStyle}
    />
  );
};

const ErrorMessage = () => {
  return (
    <Translate
      content="notifications.pin_error"
      component="div"
      style={loadingStyle}
    />
  );
};

const PinReset = (props) => {
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [errors, setErrors] = useState({});

  const notify = (msg) => toast.info(msg);

  const onChange = (e) => {
    if (e.target.name === "pin") {
      setPin(e.target.value);
    } else if (e.target.name === "confirm_pin") {
      setConfirmPin(e.target.value);
    }
  };

  const isValid = () => {
    const { errors, isValid } = validateInput({ pin, confirm_pin: confirmPin });
    if (!isValid) {
      setErrors(errors);
    }
    return isValid;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (isValid()) {
      resetPin()
        .then((res) => {
          notify(<SuccessMessage />);
          history.push({
            pathname: `/`,
          });
        })
        .catch((error) => {
          const { response } = error;
          if (!response.data) {
            return;
          }
          notify(<ErrorMessage />);
        });
    } else {
      setPin("");
      setConfirmPin("");
    }
  };

  const resetPin = async () => {
    const { phone, pincode } = props.location;
    await AuthAPI.post(
      `/api/v1/reset-pin?lan=${window.localStorage.getItem("lang")}`,
      {
        otp: pincode,
        newPin: pin,
        phoneNumber: phone,
      }
    );
  };

  const login_pin = counterpart.translate("placeholder_copy.login_pin");
  const login_pin_confirm = counterpart.translate(
    "placeholder_copy.login_pin_confirm"
  );
  const submit = counterpart.translate("placeholder_copy.submit");

  const formStyle = {
    flex: "10",
    padding: "5px",
    fontFamily: "Fira Sans",
    fontSize: "20px",
    height: "50px",
  };

  return (
    <div>
      <Container>
        <Alert color="secondary" style={loadingStyle}>
          <Translate content="placeholder_copy.submit_smscode" />
        </Alert>
        <Form onSubmit={onSubmit}>
          <FormGroup>
            <span style={{ color: "red" }}>{errors.pin}</span>
            <Input
              type="password"
              name="pin"
              style={formStyle}
              placeholder={login_pin}
              onChange={onChange}
              error={errors.pin}
              value={pin}
            />
          </FormGroup>
          <FormGroup>
            <span style={{ color: "red" }}>{errors.confirm_pin}</span>
            <Input
              type="password"
              name="confirm_pin"
              style={formStyle}
              placeholder={login_pin_confirm}
              onChange={onChange}
              error={errors.confirm_pin}
              value={confirmPin}
            />
          </FormGroup>
          <FormGroup>
            <Input
              type="submit"
              value={submit}
              name="submit"
              className="btn btn-primary"
              size="lg"
            />
          </FormGroup>
        </Form>
      </Container>
    </div>
  );
};

const loadingStyle = {
  fontFamily: "Fira Sans",
  fontSize: "18px",
};

export default PinReset;
