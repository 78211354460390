import React from "react";
import SignUpForm from "./SignUpForm";
import { Container } from "reactstrap";
import { NavLink } from "react-router-dom";
import counterpart from "counterpart";
import ZARMA from "../../view/shared/lang/ZARMA";
import ENGLISH from "../../view/shared/lang/ENGLISH";
import SWAHILI from "../../view/shared/lang/SWAHILI";
import HAOUSSA from "../../view/shared/lang/HAOUSSA";
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS";
import SPANISH from "../../view/shared/lang/SPANISH";
import Translate from "react-translate-component";
import LEBANESE from "../../view/shared/lang/LEBANESE";
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE";
import ARABIC from "../../view/shared/lang/ARABIC";
import { useSelector } from "react-redux";

counterpart.registerTranslations("ARABIC", ARABIC);
counterpart.registerTranslations("PORTUGUESE", PORTUGUESE);
counterpart.registerTranslations("LEBANESE", LEBANESE);
counterpart.registerTranslations("ZARMA", ZARMA);
counterpart.registerTranslations("SWAHILI", SWAHILI);
counterpart.registerTranslations("ENGLISH", ENGLISH);
counterpart.registerTranslations("HAOUSSA", HAOUSSA);
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS);
counterpart.registerTranslations("SPANISH", SPANISH);

export default function SignUpPage() {
  const { loadingToggleState } = useSelector(({ loadingToggleState }) => ({
    loadingToggleState,
  }));
  counterpart.setLocale("ENGLISH");

  const loadingStyle = {
    fontFamily: "Fira Sans",
    fontSize: "18px",
  };
  return (
    <Container>
      <SignUpForm />
      {!loadingToggleState && (
        <Container style={{ textAlign: "center" }}>
          <Translate
            content="span.signup_span"
            component="span"
            style={loadingStyle}
          />
          <NavLink to={{ pathname: `/` }}>
            {" "}
            <b>
              <Translate content="span.login" component="u" />
            </b>
          </NavLink>
        </Container>
      )}
    </Container>
  );
}
