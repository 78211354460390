import { triggerAction } from "../../constants";
import { loadBalancesStart } from "../../actions/getVslaBalance";
import { fetchMeetingMemberTransactions } from "../../actions/getTransactions";
import uuid from "uuid";
import { transactionAPI } from "../../api";
import {
  addToCache,
  deleteCacheByKey,
  getCacheById,
  updateCacheItem,
} from "../../../view/shared/utilities/cache";

export const reverseTransaction = (data, offlineMeeting) => async (dispatch, getState) => {
  const {
    vsla,
    isConnected,
    loadedMember,
    loadedvslas,
    user,
    currentOngoingMeeting,
  } = getState();
  if (loadedvslas[0].is_cashout_active) {
    alert("Cash out is in progress, can not processed");
    return;
  }
  if (!isConnected || (!currentOngoingMeeting.length && (offlineMeeting && !offlineMeeting.data.id))) {
    return;
  }
  const meetingId = currentOngoingMeeting.length ? currentOngoingMeeting[0].id: offlineMeeting.data.id;
  const requestId = data.uid + uuid.v4();
  delete data.uid;
  const cacheKey = await addToCache("pendingTransactions", {
    data: {
      ...data,
      requestId,
      transactionType: `${data.type} Reversal`,
      transactionPartyName: loadedMember.full_name,
    },
    vslaId: vsla.id
  });

  const requestObject = {
    pushEvent: "requests",
    receiveEvent: "response",
    emit: true,
    metadata: {
      userId: vsla.id,
      callBackParams: [data.memberId, cacheKey],
      cacheKey,
      type: "pendingTransactions",
    },
    payload: {
      meetingId,
      requestId,
      url: `${window.REACT_APP_PWA_TRANSACTIONS}/reverse`,
      headers: {
        Authorization:
          "Bearer " + user.tokenBC || localStorage.getItem("tokenBC"),
      },
      userId: vsla.id,
      method: "POST",
      data: { ...data, requestId },
    },
  };
  dispatch(requestObject);

  // if (!isConnected || meetingCacheId) {
  //   requestObject.payload.data.transactionPartyName = loadedMember.full_name;
  //   requestObject.payload.data.transactionType = `REVERSE_${data.type}`;
  //   requestObject.payload.data.createdAt = new Date();
  //   requestObject.payload.data.status = "pending";
  // } else {
  //   dispatch(requestObject);
  // }
  // await updateCacheItem("pendingTransactions", {
  //     requestObject: JSON.stringify(requestObject)
  // }, cacheKey)
};

export const initiateTransaction =
  (data, offlineMeeting) => async (dispatch, getState) => {
    const { vsla, isConnected, loadedvslas, loadedMember } = getState();
    if (loadedvslas[0].is_cashout_active) {
      alert("Cash out is in progress, can not processed");
      return;
    }
    const requestId = data.uid + uuid.v4();
    delete data.uid;
    let cacheKey = requestId;
    // if connected and is partial meeting/ commit the transaction directly
    const meeting = isConnected && offlineMeeting && offlineMeeting.data.id ? null: offlineMeeting
    if (
      (isConnected && !meeting) ||
      (meeting && meeting.data.id && isConnected)
    ) {
      cacheKey = await addToCache("pendingTransactions", {
        data: {
          ...data,
          requestId,
          transactionPartyName: loadedMember.full_name,
        },
        vslaId: vsla.id
      });
    }

    try {
      const requestObject = {
        pushEvent: "requests",
        receiveEvent: "response",
        emit: true,
        metadata: {
          userId: vsla.id,
          callBackParams: [data.memberId, cacheKey],
          cacheKey,
          type: "pendingTransactions",
        },
        payload: {
          requestId,
          url: window.REACT_APP_PWA_TRANSACTIONS,
          headers: {
            Authorization: "Bearer " + localStorage.getItem("tokenBC"),
          },
          userId: vsla.id,
          method: "POST",
          data: {
            requestId,
            ...data,
          },
        },
      };
      if (!isConnected || (meeting && !meeting.data.id)) {
        requestObject.payload.data.transactionPartyName =
          loadedMember.full_name;
        requestObject.payload.data.transactionType = data.type;
        requestObject.payload.data.createdAt = new Date();
        requestObject.payload.data.status = "pending";
        await updateCacheItem(
          "offlineMeeting",
          {
            data: {
              ...meeting.data,
              is_offline: true,
              transactions: [
                ...(meeting.data.transactions || []),
                requestObject.payload.data,
              ],
            },
          },
          meeting.cacheId
        );
      } else {
        dispatch(requestObject);
        await updateCacheItem(
          "pendingTransactions",
          {
            requestObject: JSON.stringify(requestObject),
          },
          cacheKey
        );
      }
      return Promise.resolve();
    } catch (error) {
      const { response } = error;
      if (!response || !response.data) {
        return;
      }
      return Promise.reject();
    }
  };

export const getBalances = () => async (dispatch, getState) => {
  const id = getState().loadedvslas[0].blockchain_key;
  try {
    dispatch(loadBalancesStart(true));
    const { data } = await transactionAPI.get(`/${id}/balances`);
    dispatch(triggerAction("SET_BALANCES", data));
    dispatch(loadBalancesStart(false));
  } catch (error) {
    dispatch(loadBalancesStart(false));
    console.log("Error: ", error);
  }
};

export const getBalanceByType = (type) => async (dispatch, getState) => {
  const id = getState().loadedvslas[0].blockchain_wallet_id;
  try {
    const { data } = await transactionAPI.get(`/${id}/type/${type}/balances`);
    dispatch(
      triggerAction("SET_TYPE_BALANCES", {
        data,
        transactionType: type,
      })
    );
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const getMemberBalanceByMemberId =
  (memberId, cacheId) => async (dispatch, getState) => {
    const id = getState().loadedvslas[0].blockchain_key;
    const transactions = getState().meetingTransactions;
    const ids = transactions.map(({requestId})=> requestId);
    try {
      const { data } = await transactionAPI.get(
        `/${id}/members/${memberId}/balances`
      );
      await dispatch(fetchMeetingMemberTransactions(memberId));
      // dispatch(removePendingTransaction(`${vsla.id}_${memberId}_cashout`));
      const cacheItem = await getCacheById("pendingTransactions", "cacheId", cacheId)
      if (cacheItem.length && ids.includes(cacheItem[0].requestId)) {
        deleteCacheByKey("pendingTransactions", "cacheId", cacheId);
      }
      data.forEach((item) => {
        dispatch(
          triggerAction("SET_MEMBER_BALANCES", {
            data: item,
            memberId: memberId,
            transactionType: item.accountType,
          })
        );
      });
      dispatch(getBalances());
      return Promise.resolve();
    } catch (error) {
      console.log("Error: ", error);
    }
  };

export const getMemberBalanceByMemberAndType =
  (memberId, type) => async (dispatch, getState) => {
    const id = getState().loadedvslas[0].blockchain_wallet_id;
    try {
      const { data } = transactionAPI.get(
        `/${id}/membrs/${memberId}/type/${type}/balances`
      );
      dispatch(
        triggerAction("SET_MEMBER_BALANCES", {
          data,
          transactionType: type,
        })
      );
    } catch (error) {
      console.log("Error: ", error);
    }
  };

export const getGroupMemberBalances = () => async (dispatch, getState) => {
  try {
    const id = getState().loadedvslas[0].blockchain_key;
    const { data } = await transactionAPI.get(`/${id}/member-balances`);
    const membersAccounts = {};
    for (const memberId in data) {
      const account = data[memberId];
      account.forEach(
        (item) =>
          (membersAccounts[memberId] = {
            ...membersAccounts[memberId],
            [item.accountType]: item,
          })
      );
    }
    dispatch(triggerAction("SET_MEMBER_ACCOUNTS", membersAccounts));
  } catch (error) {
    console.log("error: ", error);
  }
};
