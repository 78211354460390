import io from "socket.io-client";
import {
  deleteCacheByKey,
  updateCacheItem,
} from "../../view/shared/utilities/cache";
import { triggerAction } from "../constants";
import { fetchVslasMembers } from "../actions/members/getVslaMembers";
import { fetchPreviousMeetingsData } from "../actions/meetings";
import { getMemberBalanceByMemberId } from "../reducers/transactions/actions";
import { fetchVslas } from "../actions/getVslas";
export const appPrefix = "vsla";
const callbacksMap = {
  members: [fetchVslasMembers],
  offlineMeeting: [fetchPreviousMeetingsData],
  pendingTransactions: [getMemberBalanceByMemberId],
  accounts: [fetchVslas],
};
export default () => {
  const socket = io.connect(window.REACT_APP_WS_URL, {
    transports: ["websocket"],
  });

  return ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      if (typeof action === "function") {
        return next(action);
      }
      if (!action) {
        return;
      }

      const { pushEvent, receiveEvent, leave, payload, emit } = action;
      let { metadata } = action;

      if (!pushEvent) {
        return next(action);
      }

      if (leave) {
        socket.removeListener(pushEvent);
      }

      if (emit) {
        socket.emit(pushEvent, payload);
      }

      const handleReceivedData = async (payload) => {
        const { cacheKey, callBackParams } = metadata || {};
        //Emit acknowledgement to websocket
        socket.emit("ack", {
          userId: payload.userId,
          messageId: payload.messageId,
        });
        let { type } = metadata || {};
        /*
          Use Case: Handle the creation of a secretary wallet based on success or failure responses.
       */
        if (
          [
            "Secretary wallet created successfully",
            "Member wallet creation failed",
          ].includes(payload.description)
        ) {
          type = "members";
          metadata = {};
        }

        if (!metadata || !type) {
          return;
        }

        if (payload.processStatus === "FAILED") {
          if (type === "offlineMeeting") {
            dispatch(triggerAction("TOGGLE_FAILED_TO_UPLOAD", false));
          }
          /*
            If member creation fails, query the backend to retrieve the updated member list with the Failed status.
          */
          if (type === "members") {
            await dispatch(fetchVslasMembers());
            updateCacheItem(type, { error: payload }, cacheKey);
          }
          updateCacheItem(type, { error: payload }, cacheKey);
        } else if (payload.processStatus === "SUCCESSFUL") {
          if (type === "offlineMeeting" && cacheKey) {
            dispatch(triggerAction("TOGGLE_UPLOAD_COMPLETE", true));
            dispatch(triggerAction("TOGGLE_IS_UPLOADING", false));
            if (getState().currentPage === "group-offlineMeeting") {
              dispatch(triggerAction("SET_PAGE", "group-previousMeetings"));
            }
          }
          for (const fn of callbacksMap[type]) {
            await dispatch(fn(...(callBackParams || [])));
          }
          // For member creation, the callback function from the WebSocket
          // should be the sole source of truth for deleting cache data.
          if (type !== "members") {
            deleteCacheByKey(type, "cacheId", cacheKey);
          }
        }
      };
      return socket.on(receiveEvent, handleReceivedData);
    };
};
