import { transactionAPI } from "../api";
import { triggerAction } from "../constants";

export const meetingTransactionsLoadStarted = (bool) => ({
  type: "MEETING_TRANSACTION_LOAD_START",
  isloading: bool,
});

export const meetingTransactionsLoaded = (transactions) => ({
  type: "LOADED_MEETING_TRANSACTION",
  transactions,
});

export const meetingTransactionsLoadError = (bool) => ({
  type: "MEETING_TRANSACTION_LOAD_ERROR",
  error: bool,
});

export const fetchMeetingMemberTransactions = (bcWalletId) => async (dispatch, getState) =>{
  try {
    const meeting = getState().currentOngoingMeeting;

    let member = getState().loadedvslaMembers.filter(({blockchain_wallet_id})=>blockchain_wallet_id===bcWalletId);
    member = member.length ? member[0]: null
    const meetingId = meeting.length ? meeting[0].id: localStorage.getItem('_m_id');
    if (!meetingId || !member) {return}
    const res = await transactionAPI.get(`/meeting/${meetingId}/members/${member.blockchain_wallet_id}`);
    const parsed = res.data.map(t => ({ ...t, full_name: member.full_name })).filter(({ status }) => status !== "REVERSED");
    if (!parsed.length) {return;}

    dispatch(triggerAction('UPDATE_ONGOING_MEETING_TRANSACTIONS', parsed))
  } catch (error) {
    console.log("error ", error)
  }
}

export function fetchMeetingTransactions(meetingId) {
  return async (dispatch, getState) => {
    const members = getState().loadedvslaMembers;
    const names = {};

    dispatch(meetingTransactionsLoadStarted(true));
    await Promise.all(
      members.map(async ({ blockchain_wallet_id, full_name }) => {
        names[blockchain_wallet_id] = full_name;
        return await transactionAPI.get(
          `/meeting/${meetingId}/members/${blockchain_wallet_id}`
        );
      })
    )
      .then((items) => {
        const parsed = items
          .map(({ data }) => {
            return data
              .map((t) => ({ ...t, full_name: names[t.memberId] }))
              .filter(({ status }) => status !== "REVERSED");
          })
          .flat();
        dispatch(meetingTransactionsLoadStarted(false));
        dispatch(
          meetingTransactionsLoaded(
            parsed.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          )
        );
      })
      .catch((err) => {
        console.log("Error: ", err);
        dispatch(meetingTransactionsLoadError(true));
        return [];
      });
  };
}
