import React, { Component } from "react";
import {
  ListGroup,
  ListGroupItem,
  Col,
  Row,
  Container,
  Alert,
} from "reactstrap";
import counterpart from "counterpart";
import ZARMA from "../../view/shared/lang/ZARMA";
import ENGLISH from "../../view/shared/lang/ENGLISH";
import SWAHILI from "../../view/shared/lang/SWAHILI";
import HAOUSSA from "../../view/shared/lang/HAOUSSA";
import FRANÇAIS from "../../view/shared/lang/FRANÇAIS";
import SPANISH from "../../view/shared/lang/SPANISH";

import { toast } from "react-toastify";
import { connect } from "react-redux";
import uuid from "uuid";
import redArrow from "../../view/assets/arrowRed.png";
import greenArrow from "../../view/assets/arrowGreen.png";
import Translate from "react-translate-component";
import { getCurrency } from "../../view/shared/utilities/currency";
import LEBANESE from "../../view/shared/lang/LEBANESE";
import PORTUGUESE from "../../view/shared/lang/PORTUGUESE";
import ARABIC from "../../view/shared/lang/ARABIC";
import { setCurrentPage } from "../../store/reducers/navigation";
import { fullArabicDateConverter } from "../../view/shared/utilities/arabicDateConverter";
import { getTranslation } from "../../view/shared/helpers";

counterpart.registerTranslations("ARABIC", ARABIC);

counterpart.registerTranslations("PORTUGUESE", PORTUGUESE);
counterpart.registerTranslations("LEBANESE", LEBANESE);
counterpart.registerTranslations("ZARMA", ZARMA);
counterpart.registerTranslations("SWAHILI", SWAHILI);
counterpart.registerTranslations("ENGLISH", ENGLISH);
counterpart.registerTranslations("HAOUSSA", HAOUSSA);
counterpart.registerTranslations("FRANÇAIS", FRANÇAIS);
counterpart.registerTranslations("SPANISH", SPANISH);

export class MeetingItem extends Component {
  notify = (msg) => toast(msg);

  componentDidMount() {
    if (!this.props.selectedPreviousMeeting) {
      this.props.setPage("group-previousMeetings");
    }
  }

  render() {
    const { selectedPreviousMeeting, vsla, group, transactions } = this.props;
    const lang = localStorage.getItem("lang");

    const dateStyle = {
      color: "#E36F1E",
      fontFamily: "quicksand",
      fontWeight: "bolder",
      fontSize: "25px",
      padding: ".5rem",
      textAlign: "center",
      margin: "34px 0 0 0",
    };

    if (transactions.length < 1) {
      return (
        <div>
          <div style={dateStyle}>
            <span>
              {!["ARABIC"].includes(localStorage.getItem("lang")) &&
                new Date(
                  selectedPreviousMeeting.date
                  // update the translation to use helper's module function
                ).toLocaleString(counterpart.translate("locale.date"), {
                  dateStyle: "full",
                })}

              {["ARABIC"].includes(localStorage.getItem("lang")) &&
                fullArabicDateConverter(selectedPreviousMeeting.date)}
            </span>
          </div>
          <br />
          <Container>
            <Alert color="warning">
              <span>
                <Translate
                  content="notifications.no_transactions"
                  style={{ fontSize: "16px", fontFamily: "Fira Sans" }}
                />
              </span>
            </Alert>
          </Container>
        </div>
      );
    }

    return (
      <div>
        <div style={dateStyle}>
          <span>
            {!["ARABIC"].includes(localStorage.getItem("lang")) &&
              new Date(selectedPreviousMeeting.date).toLocaleString(
                // update the translation to use helper's module function
                counterpart.translate("locale.date"),
                { dateStyle: "full" }
              )}
            {["ARABIC"].includes(localStorage.getItem("lang")) &&
              fullArabicDateConverter(selectedPreviousMeeting.date)}
          </span>
        </div>
        <br />

        <Container style={{ paddingBottom: "30px" }}>
          <ListGroup flush style={{ lineHeight: "1.4em" }}>
            {transactions.map((transaction) =>
              [
                "welfare_contribution",
                "loan_repayment",
                "loan_interest_repayment",
                "fine_payment",
                "group_income",
                "membership_fee",
                "buy_shares",
              ].includes(transaction.transactionType.toLowerCase()) ? (
                transaction.type === "write_off_loan" || (
                  <ListGroupItem key={uuid.v4()}>
                    <Row>
                      <Col xs="2">
                        <img
                          src={greenArrow}
                          alt="fine"
                          style={{ marginTop: "0.5em" }}
                        />
                      </Col>
                      <Col xs="10">
                        <span
                          style={{
                            color: "#02294a",
                            fontFamily: "Fira Sans",
                            fontSize: "20px",
                          }}
                        >
                          {transaction.transactionPartyName}
                        </span>
                        <br />
                        <span
                          style={{
                            color: "#0fbe15",
                            fontFamily: "Fira Sans",
                            fontSize: "20px",
                          }}
                        >
                          {transaction.fund &&
                          transaction.transactionType.toLocaleLowerCase() !==
                            "create_loan"
                            ? getTranslation(
                                "menu_mem",
                                `${transaction.transactionType.toLocaleLowerCase()}_${
                                  transaction.fund
                                }`
                              )
                            : txnTypes[lang][transaction.transactionType]}
                          {":  "}
                          {"+"}
                          {transaction.amount}{" "}
                          {!["ARABIC", "LEBANESE"].includes(
                            localStorage.getItem("lang")
                          ) && getCurrency(vsla[0].country).symbol}
                          {["ARABIC", "LEBANESE"].includes(
                            localStorage.getItem("lang")
                          ) &&
                            (group.metadata.currency === "USD"
                              ? getCurrency(vsla[0].country).usd_symbol
                              : getCurrency(vsla[0].country).lbp_symbol)}
                        </span>
                      </Col>
                    </Row>
                  </ListGroupItem>
                )
              ) : (
                <ListGroupItem key={uuid.v4()}>
                  <Row>
                    <Col xs="2">
                      <img
                        src={redArrow}
                        alt="fine"
                        style={{ marginTop: "0.5em" }}
                      />
                    </Col>
                    <Col xs="10">
                      <span
                        style={{
                          color: "#02294a",
                          fontFamily: "Fira Sans",
                          fontSize: "20px",
                        }}
                      >
                        {transaction.transactionPartyName}
                      </span>
                      <br />
                      <span
                        style={{
                          color: "#ff0707",
                          fontFamily: "Fira Sans",
                          fontSize: "20px",
                        }}
                      >
                        {transaction.fund &&
                        transaction.transactionType.toLocaleLowerCase() !==
                          "create_loan"
                          ? getTranslation(
                              "menu_mem",
                              `${transaction.transactionType.toLocaleLowerCase()}_${
                                transaction.fund
                              }`
                            )
                          : txnTypes[lang][transaction.transactionType]}
                        {":  "}
                        {"-"}
                        {Math.abs(transaction.amount)}{" "}
                        {!["ARABIC", "LEBANESE"].includes(
                          localStorage.getItem("lang")
                        ) && getCurrency(vsla[0].country).symbol}
                        {["ARABIC", "LEBANESE"].includes(
                          localStorage.getItem("lang")
                        ) &&
                          (group.metadata.currency === "USD"
                            ? getCurrency(vsla[0].country).usd_symbol
                            : getCurrency(vsla[0].country).lbp_symbol)}
                      </span>
                    </Col>
                  </Row>
                </ListGroupItem>
              )
            )}
          </ListGroup>
        </Container>
      </div>
    );
  }
}

const txnTypes = {
  LEBANESE: {
    SELL_SHARES: "Shares sold",
    LOAN_REPAYMENT: "Loan repayment",
    WELFARE_REQUEST: "Welfare request",
    welfareContribution: "Welfare contribution",
    welfareContrib: "Welfare contributions",
    finePaid: "Fine paid",
    fine: "Fines",
    membershipPaid: "Membership paid",
    shareBought: "Savings added",
    WRITE_OFF_LOAN: "Loan write-off",
    loanRepaid: "Loan repaid",
    LoanTaken: "Loan taken",
    shareSold: "Savings withdrawn",
    welfareReq: "Welfare requests",
    welfareRequest: "Welfare request",
    membershipReversed: "Membership fee reversed",
    fineReversed: "Fine reversed",
    welfareRequestR: "Welfare request reversed",
    otherIncome: "Group income",
    loanRepayR: "Loan repayment reversed",
    sharesBoughtR: "Add savings reversed",
    sharesSoldR: "Savings withdrawal reversed",
    writeoffReversed: "Loan write-off reversed",
    otherExpenses: "Group expenses",
    MEMBERSHIP_CASH_OUT: "Membership cash out",
    SHARESOLD_CASH_OUT: "Shares sold - cash out",
    SAVINGS_CASH_OUT: "Savings cash out",
    FINE_CASH_OUT: "Fine cash out",
    WELFARE_CASH_OUT: "Welfare cash out",
    chargeaccrued: "Service charge accrued",
    loanBalance: "Loan balance",
    sharesBalance: "Savings balance",
    membership: "Membership",
    BUY_SHARES_REVERSAL: "Buy Shares reversed",
    WELFARE_CONTRIBUTION_REVERSAL: "Welfare Contribution reversed",
    FINE_PAYMENT_REVERSAL: "Pay fine reversed",
    LOAN_REPAYMENT_REVERSAL: "Repay Loan reversed",
    LOAN_INTEREST_REPAYMENT_REVERSAL: "Pay loan interest reversed",
    SELL_SHARES_REVERSAL: "Sell Shares reversed",
    WELFARE_REQUEST_REVERSAL: "Welfare Request reversed",
    MEMBERSHIP_FEE_REVERSAL: "Membership Fee reversed",
    LOAN_INTEREST_CASH_OUT: "Loan interest cash out",
  },
  ARABIC: {
    BUY_SHARES: "إضافة المدخرات",
    WELFARE_CONTRIBUTION: "المساهمة في الرعاية",
    SELL_SHARES: "سحب المدخرات",
    BUY_SHARES_REVERSAL: "إضافة المدخرات (عكس)",
    SELL_SHARES_REVERSAL: "سحب المدخرات (عكس)",
  },
  PORTUGUESE: {
    LOAN_REPAYMENT: "Pagamento de empréstimo",
    welfareContribution: "Contribuição social",
    welfareContrib: "Contribuições sociais",
    finePaid: "Multa paga",
    fine: "Multas",
    membershipPaid: "Taxa de membro paga",
    shareBought: "Ações compradas",
    WRITE_OFF_LOAN: "Eliminar empréstimo",
    loanRepaid: "Empréstimo pago",
    LoanTaken: "Empréstimo tomado",
    shareSold: "Ações vendidas",
    welfareReq: "Pedidos Sociais",
    WELFARE_REQUEST: "Pedido Social",
    welfareRequest: "Pedido Social",
    membershipReversed: "Associação de membro revertida",
    fineReversed: "Multa invertida",
    welfareRequestR: "Pedido Social Revertido",
    otherIncome: "Outros rendimentos",
    loanRepayR: "Pagamento do empréstimo revertido",
    sharesBoughtR: "Ações compradas revertidas",
    sharesSoldR: "Ações vendidas revertidas",
    writeoffReversed: "Eliminação revertida",
    otherExpenses: "Outras despesas",
    MEMBERSHIP_CASH_OUT: "Saque de afiliação",
    SHARESOLD_CASH_OUT: "Ações vendidas - sacar",
    SAVINGS_CASH_OUT: "Saque de poupança",
    FINE_CASH_OUT: "Saque de multa",
    WELFARE_CASH_OUT: "Saque de fundo social",
    chargeaccrued: "Taxa de serviço acumulada",
    loanBalance: "Saldo do empréstimo",
    sharesBalance: "Saldo de ações",
    membership: "Afiliação de Membro",
    BUY_SHARES_REVERSAL: "Ações compradas revertidas",
    WELFARE_CONTRIBUTION_REVERSAL: "Contribuição social revertidas",
    FINE_PAYMENT_REVERSAL: "Multa invertida",
    LOAN_REPAYMENT_REVERSAL: "Pagamento do empréstimo revertido",
    LOAN_INTEREST_REPAYMENT_REVERSAL: "Pagar juros do empréstimo revertidos",
    SELL_SHARES_REVERSAL: "Ações vendidas revertidas",
    WELFARE_REQUEST_REVERSAL: "Pedido Social Revertido",
    MEMBERSHIP_FEE_REVERSAL: "Taxa de adesão revertida",
    WELFARE_CONTRIBUTION: "Contribuição social",
    FINE_PAYMENT: "Multa paga",
    MEMBERSHIP_FEE: "Taxa de membro paga",
    BUY_SHARES: "Ações compradas",
    CREATE_LOAN: "Empréstimo tomado",
    SELL_SHARES: "Ações vendidas",
    GROUP_INCOME: "Renda do grupo",
    GROUP_EXPENSE: "Despesa do grupo",
    LOAN_INTEREST_REPAYMENT: "Reembolso de juros do empréstimo",
    LOAN_INTEREST_CASH_OUT: "Saque de juros do empréstimo",
  },
  ENGLISH: {
    LOAN_REPAYMENT: "Loan repayment",
    WELFARE_REQUEST: "Welfare request",
    WELFARE_CONTRIBUTION: "Welfare contribution",
    welfareContrib: "Welfare contributions",
    FINE_PAYMENT: "Fine paid",
    fine: "Fines",
    MEMBERSHIP_FEE: "Membership paid",
    BUY_SHARES: "Shares bought",
    WRITE_OFF_LOAN: "Loan write-off",
    loanRepaid: "Loan repaid",
    CREATE_LOAN: "Loan taken",
    SELL_SHARES: "Shares sold",
    welfareReq: "Welfare requests",
    welfareRequest: "Welfare request",
    membershipReversed: "Membership reversed",
    GROUP_INCOME: "Group income",
    GROUP_EXPENSE: "Group expense",
    MEMBERSHIP_CASH_OUT: "Membership cash out",
    SHARESOLD_CASH_OUT: "Shares sold - cash out",
    SAVINGS_CASH_OUT: "Savings cash out",
    FINE_CASH_OUT: "Fine cash out",
    WELFARE_CASH_OUT: "Welfare cash out",
    chargeaccrued: "Service charge accrued",
    loanBalance: "Loan balance",
    sharesBalance: "Shares balance",
    membership: "Membership",
    LOAN_INTEREST_REPAYMENT: "Loan interest repayment",
    BUY_SHARES_REVERSAL: "Buy Shares reversed",
    WELFARE_CONTRIBUTION_REVERSAL: "Welfare Contribution reversed",
    FINE_PAYMENT_REVERSAL: "Pay fine reversed",
    LOAN_REPAYMENT_REVERSAL: "Repay Loan reversed",
    LOAN_INTEREST_REPAYMENT_REVERSAL: "Pay loan interest reversed",
    SELL_SHARES_REVERSAL: "Sell Shares reversed",
    WELFARE_REQUEST_REVERSAL: "Welfare Request reversed",
    MEMBERSHIP_FEE_REVERSAL: "Membership Fee reversed",
    writeoffReversed: "Write-off reversed",
    LOAN_INTEREST_CASH_OUT: "Loan interest cash out",
  },
  FRANÇAIS: {
    LOAN_REPAYMENT: "Loan repayment",

    WELFARE_CONTRIBUTION: "Welfare contribution",
    welfareContrib: "Welfare contributions",
    FINE_PAYMENT: "Fine paid",
    fine: "Fines",
    MEMBERSHIP_FEE: "Membership paid",
    BUY_SHARES: "Shares bought",
    WRITE_OFF_LOAN: "Loan write-off",
    loanRepaid: "Loan repaid",
    CREATE_LOAN: "Loan taken",
    SELL_SHARES: "Shares sold",
    welfareReq: "Welfare requests",
    welfareRequest: "Welfare request",
    WEKFARE_REQUREST: "Welfare request",
    membershipReversed: "Membership reversed",
    fineReversed: "Fine reversed",
    welfareRequestR: "Welfare request reversed",
    GROUP_INCOME: "Group income",
    loanRepayR: "Loan repayment reversed",
    sharesBoughtR: "Shares bought reversed",
    sharesSoldR: "Shares sold reversed",
    writeoffReversed: "Write-off reversed",
    GROUP_EXPENSE: "Group expenses",
    MEMBERSHIP_CASH_OUT: "Membership cash out",
    SHARESOLD_CASH_OUT: "Shares sold - cash out",
    SAVINGS_CASH_OUT: "Savings cash out",
    FINE_CASH_OUT: "Fine cash out",
    WELFARE_CASH_OUT: "Welfare cash out",
    chargeaccrued: "Service charge accrued",
    loanBalance: "Loan balance",
    sharesBalance: "Shares balance",
    membership: "Membership",
    LOAN_INTEREST_REPAYMENT: "Loan interest repayment",
    BUY_SHARES_REVERSAL: "Buy Shares reversed",
    WELFARE_CONTRIBUTION_REVERSAL: "Welfare Contribution reversed",
    FINE_PAYMENT_REVERSAL: "Pay fine reversed",
    LOAN_REPAYMENT_REVERSAL: "Repay Loan reversed",
    LOAN_INTEREST_REPAYMENT_REVERSAL: "Pay loan interest reversed",
    SELL_SHARES_REVERSAL: "Sell Shares reversed",
    WELFARE_REQUEST_REVERSAL: "Welfare Request reversed",
    MEMBERSHIP_FEE_REVERSAL: "Membership Fee reversed",
    LOAN_INTEREST_CASH_OUT: "Retrait des intérêts du prêt",
  },
  SWAHILI: {
    LOAN_REPAYMENT: "Loan repayment",

    WELFARE_CONTRIBUTION: "Welfare contribution",
    welfareContrib: "Welfare contributions",
    FINE_PAYMENT: "Fine paid",
    fine: "Fines",
    MEMBERSHIP_FEE: "Membership paid",
    BUY_SHARES: "Shares bought",
    WRITE_OFF_LOAN: "Loan write-off",
    loanRepaid: "Loan repaid",
    LOAN_INTEREST_REPAYMENT: "Loan interest repayment",
    CREATE_LOAN: "Loan taken",
    SELL_SHARES: "Shares sold",
    welfareRequest: "Welfare request",
    WEKFARE_REQUREST: "Welfare request",
    welfareReq: "Welfare requests",
    membershipReversed: "Membership reversed",
    fineReversed: "Fine reversed",
    welfareRequestR: "Welfare request reversed",
    GROUP_INCOME: "Group income",
    loanRepayR: "Loan repayment reversed",
    sharesBoughtR: "Shares bought reversed",
    sharesSoldR: "Shares sold reversed",
    writeoffReversed: "Write-off reversed",
    GROUP_EXPENSE: "Group expenses",
    MEMBERSHIP_CASH_OUT: "Membership cash out",
    SHARESOLD_CASH_OUT: "Shares sold - cash out",
    SAVINGS_CASH_OUT: "Savings cash out",
    FINE_CASH_OUT: "Fine cash out",
    WELFARE_CASH_OUT: "Welfare cash out",
    chargeaccrued: "Service charge accrued",
    loanBalance: "Loan balance",
    sharesBalance: "Shares balance",
    membership: "Membership",
    BUY_SHARES_REVERSAL: "Buy Shares reversed",
    WELFARE_CONTRIBUTION_REVERSAL: "Welfare Contribution reversed",
    FINE_PAYMENT_REVERSAL: "Pay fine reversed",
    LOAN_REPAYMENT_REVERSAL: "Repay Loan reversed",
    LOAN_INTEREST_REPAYMENT_REVERSAL: "Pay loan interest reversed",
    SELL_SHARES_REVERSAL: "Sell Shares reversed",
    WELFARE_REQUEST_REVERSAL: "Welfare Request reversed",
    MEMBERSHIP_FEE_REVERSAL: "Membership Fee reversed",
    LOAN_INTEREST_CASH_OUT: "Pesa za riba ya mkopo",
  },
  SPANISH: {
    LOAN_REPAYMENT: "Loan repayment",

    welfareContribution: "Contribución al Fondo Social",
    welfareContrib: "Contribución al Fondo Social",
    finePaid: "Multa pagada",
    fine: "Multa",
    membershipPaid: "Membresía Pagada",
    shareBought: "Acciones compradas",
    WRITE_OFF_LOAN: "Cancelación de préstamos",
    loanRepaid: "Préstamo Reembolsado",
    LoanTaken: "Préstamo tomado",
    shareSold: "Acciones Vendidas",
    welfareRequest: "Solicitud de fondos de Fondo Social",
    WEKFARE_REQUREST: "Solicitud de fondos de Fondo Social",
    welfareReq: "Solicitud de fondos de Fondo Social",
    membershipReversed: "Membresía Revertida",
    fineReversed: "Multa Revertida",
    welfareRequestR: "Solicitud de fondos de Fondo Social Revertido",
    otherIncome: "Otros ingresos",
    loanRepayR: "Reembolso del Préstamo Revertido",
    sharesBoughtR: "Acciones Compradas Revertidas",
    sharesSoldR: "Acciones Vendidas Revertidas",
    writeoffReversed: "Cancelación Revertida",
    otherExpenses: "Otros Gastos",
    MEMBERSHIP_CASH_OUT: "Membresía - : retiro de efectivo",
    SHARESOLD_CASH_OUT: "Acciones Vendidas - retiro de efectivo",
    SAVINGS_CASH_OUT: "Ahorros - retiro de efectivo",
    FINE_CASH_OUT: "Multa - retiro de efectivo",
    WELFARE_CASH_OUT: "Fondo Social - retiro de efectivo",
    chargeaccrued: "Cargo por servicio acumulado",
    loanBalance: "Saldo de Préstamo",
    sharesBalance: "Saldo de acciones",
    membership: "Membresía",
    BUY_SHARES_REVERSAL: "Buy Shares reversed",
    WELFARE_CONTRIBUTION_REVERSAL: "Welfare Contribution reversed",
    FINE_PAYMENT_REVERSAL: "Pay fine reversed",
    LOAN_REPAYMENT_REVERSAL: "Repay Loan reversed",
    LOAN_INTEREST_REPAYMENT_REVERSAL: "Pay loan interest reversed",
    SELL_SHARES_REVERSAL: "Sell Shares reversed",
    WELFARE_REQUEST_REVERSAL: "Welfare Request reversed",
    MEMBERSHIP_FEE_REVERSAL: "Membership Fee reversed",
    LOAN_INTEREST_CASH_OUT: "Retiro de intereses del préstamo",
  },
};

const mapStateToProps = (state) => {
  return {
    transactions:
      state.previousMeetingsTransactions.selectedMeeting.transactions.filter(
        ({ status }) => status === "SUCCESSFUL"
      ),
    vsla: state.loadedvslas,
    group: state.vsla,
    selectedPreviousMeeting: state.previousMeetingsTransactions.selectedMeeting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPage: (page) => dispatch(setCurrentPage(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MeetingItem);
